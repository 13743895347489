<template>
  <div class="d-flex my-1">
    <div class="d-flex border-dashed rounded" style="background: #F5F8FA">
      <div class="cursor-pointer border-right border-1 rounded-left"
           :class="{ 'bg-primary fw-bolder' : value === true, 'px-5 py-3': size === 'lg','px-2 py-1': size === 'sm'  }" @click="setValue(true)">
        {{ trueLabel }}
      </div>
      <div class="cursor-pointer rounded-right"
           :class="{ 'bg-primary fw-bolder' : value === false, 'px-5 py-3': size === 'lg','px-2 py-1': size === 'sm' }" @click="setValue(false)">
        {{ falseLabel }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";


@Component({})
export default class BooleanInput extends Vue {

  @Prop({default: false}) value?: boolean
  @Prop({default: 'lg'}) size?: string
  @Prop({default: 'Oui'}) trueLabel?: string
  @Prop({default: 'Non'}) falseLabel?: string

  setValue(val: boolean) {
    this.$emit('input', val)
  }
}
</script>

<style scoped>

</style>
