/* eslint-disabled */
import PremiumDetail from "./PremiumDetail";
import Contract from "./Contract";
import Item from "./Item";
import AvenantExtend from "@/entity/extends/AvenantExtend";
import Insurer from "@/entity/Insurer";
import TypeAvenant from "./TypeAvenant";
import PaymentTransaction from "./PaymentTransaction";
import Commission from "@/entity/Commission";
import Reduction from "@/entity/Reduction";

export default class Avenant extends AvenantExtend {

    public id!: number;
    // @ts-ignore
    public dateEffet: Date;
    public dateEcheance?: Date;
    public numero!: number;
    public status = '';
    public type = '';
    public duree? = '';
    public premiumDetails: Array<PremiumDetail> = [];
    public primeTTC!: number;
    public primeNette!: number;
    public contract!: Contract;
    public commissions: Array<Commission> = [];
    public reductions: Array<Reduction> = [];
    public root?: Item;
    public itemsIn: Array<Item> = [];
    public itemsOut: Array<Item> = [];
    public insurer?: Insurer
    public dateEmission?: Date;
    public dateDeclaration?: Date;
    public dateReversement?: Date;
    public dateCommission?: Date;
    public tauxCommission?: number;
    public commission? = 0;
    public commissionApporteur? = 0;
    public transactions: Array<PaymentTransaction> = [];
    public source? = '';
    public emitBy? = '';
    public police = '';
    public uuid = '';
    public createdAt?: Date;
    public updatedAt?: Date;
    public createBy? = '';
    public updateBy? = '';
    public removeBy? = '';
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public typeAvenant!: TypeAvenant;

    public item?: Item;
    public userData? = '';

    constructor(object?: any) {
        super(object)
        if (object) {
            this.id = object.id;
            if (object.dateEffet) {
                this.dateEffet = new Date(object.dateEffet);
            }
            if (object.dateEcheance) {
                this.dateEcheance = new Date(object.dateEcheance);
            }
            this.numero = object.numero;
            this.status = object.status;
            this.type = object.type;
            this.duree = object.duree;
            if (object.premiumDetails) {
                object.premiumDetails.forEach((occ: any) => {
                    const premiumdetail = occ instanceof PremiumDetail ? occ : new PremiumDetail(occ);
                    this.premiumDetails.push(premiumdetail);
                });
            }
            this.police = object.police
            this.primeTTC = object.primeTTC;
            this.primeNette = object.primeNette;
            if (object.transactions) {
                object.transactions.forEach((occ: any) => {
                    const paymenttransaction = occ instanceof PaymentTransaction ? occ : new PaymentTransaction(occ);
                    this.transactions.push(paymenttransaction);
                });
            }
            this.contract = (object.contract instanceof Contract) ? object.contract : object.contract ? new Contract(object.contract) : object.contract;
            this.insurer = (object.insurer instanceof Insurer) ? object.insurer : object.insurer ? new Insurer(object.insurer) : object.insurer;
            this.root = (object.root instanceof Item) ? object.root : object.root ? new Item(object.root) : object.root;
            if (object.itemsIn) {
                object.itemsIn.forEach((occ: any) => {
                    const item = occ instanceof Item ? occ : new Item(occ);
                    this.itemsIn.push(item);
                });
            }
            if (object.commissions) {
                object.commissions.forEach((occ: any) => {
                    const commission = occ instanceof Commission ? occ : new Commission(occ);
                    this.commissions.push(commission);
                });
            }
            if (object.reductions) {
                object.reductions.forEach((occ: any) => {
                    const reduction = occ instanceof Reduction ? occ : new Reduction(occ);
                    this.reductions.push(reduction);
                });
            }
            if (object.itemsOut) {
                object.itemsOut.forEach((occ: any) => {
                    const item = occ instanceof Item ? occ : new Item(occ);
                    this.itemsOut.push(item);
                });
            }
            if (object.dateEmission) {
                this.dateEmission = new Date(object.dateEmission);
            }
            if (object.dateDeclaration) {
                this.dateDeclaration = new Date(object.dateDeclaration);
            }
            if (object.dateReversement) {
                this.dateReversement = new Date(object.dateReversement);
            }
            if (object.dateCommission) {
                this.dateCommission = new Date(object.dateCommission);
            }
            this.commission = object.commission;
            this.commissionApporteur = object.commissionApporteur;
            this.userData = object.userData;
            this.tauxCommission = object.tauxCommission;
            this.source = object.source;
            this.emitBy = object.emitBy;
            this.uuid = object.uuid;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = object.createBy;
            this.updateBy = object.updateBy;
            this.removeBy = object.removeBy;
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            this.item = (object.item instanceof Item) ? object.item : object.item ? new Item(object.item) : object.item;

            this.typeAvenant = (object.typeAvenant instanceof TypeAvenant) ? object.typeAvenant : object.typeAvenant ? new TypeAvenant(object.typeAvenant) : object.typeAvenant;
        }
        this.postConstruct()
    }

}
