import {RouteConfig} from "vue-router";


const prefix = '/wicare'
const wicareRoutes: Array<RouteConfig> = [
    {
        path: "/",
        name: "wicare-download",
        component: import("@/views/Wicare/wicareDownload.vue"),
    },
    {
        path: '/dashboard',
        name: 'wicare-index',
        component: () => import("@/views/Wicare/wicareDashboard.vue"),
    },
    {
        path: '/customers',
        name: 'wicare-customer-list',
        component: () => import("@/views/Wicare/customer/customerList.vue"),
    },
    {
        path: '/notifications',
        name: 'wicare-notifications',
        component: () => import("@/views/Wicare/wicareNotifications.vue"),
    },
    {
        path: '/parameters',
        name: 'wicare-parameters',
        component: () => import("@/views/Wicare/wicareParameters.vue"),
    },


]
wicareRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default wicareRoutes;
