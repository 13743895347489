import {api} from "@/services/Api";
import Popup from "@/entity/Popup";
import {auth} from "@/services/Auth";

class LiveManager {


    engineSource: any = null

    managerSource: any = null

    listenManagerUpdates() {
        if (!this.managerSource) {
            const baseUrl = api.mercure;
            const hubUrl = new URL(baseUrl);
            if(auth.hasRole('ROLE_MANAGER')){
            hubUrl.searchParams.append('topic', '/manager');
            }else if(auth.hasRole('ROLE_PARTNER')){
                hubUrl.searchParams.append('topic', '/partner');
            }
            this.managerSource = new EventSource(hubUrl.toString());
            this.managerSource.onerror = (e: any) => {
                this.managerSource.close()
                console.log("An error occurred while attempting to connect.");
            }
            this.managerSource.onmessage = (event: any) => {
                if (event.data) {
                    const data = JSON.parse(event.data)
                    if (data && data.type) {
                        switch (data.type) {
                            case 'ENGINE_GENERATED': {
                                let color = data.color ? data.color : 'secondary';
                                let message = data.message ? data.message : 'Un moteur de calcul a été généré'
                                let title = data.title ? data.title : 'UNKNOW BRANCH'
                                new Popup(title, message, color, 'fad fa-exclamation');
                                break;
                            }
                            case 'CHECKOUT_FAILED': {
                                let color = data.color ? data.color : 'danger';
                                let message = data.message ? data.message : 'Un paiement a échoué'
                                let title = data.title ? data.title : 'Echec paiement'
                                new Popup(title, message, color, 'fad fa-exclamation');
                                break;
                            }
                            case 'PAYMENT_SUCCEED': {
                                let color = data.color ? data.color : 'success';
                                let message = data.message ? data.message : 'Un paiement a été effectué'
                                let title = data.title ? data.title : 'Paiement Effectué'
                                new Popup(title, message, color, 'fad fa-exclamation');
                                break;
                            }
                            case 'CONTRACT_EMITTED':{
                                let color = data.color ? data.color : 'success';
                                let message = data.message ? data.message : 'Un contrat a été émis'
                                let title = data.title ? data.title : 'Paiement Effectué'
                                new Popup(title, message, color, 'fad fa-exclamation');
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    listenEngineUpdate() {
        if (!this.engineSource) {
            const baseUrl = api.mercure;
            const hubUrl = new URL(baseUrl);
            hubUrl.searchParams.append('topic', '/engine');
            this.engineSource = new EventSource(hubUrl.toString());
            this.engineSource.onerror = (e: any) => {
                this.engineSource.close()
                console.log("An error occurred while attempting to connect.");
            }
            this.engineSource.onmessage = (event: any) => {
                if (event.data) {
                    const data = JSON.parse(event.data)
                    if (data) {
                        if (data.branch && data.message) {
                            let color = data.color ? data.color : 'secondary';
                            new Popup(data.branch, data.message, color, 'fad fa-exclamation', true);
                        }
                    }
                }
            }
            // this.engineSource.addEventListener('error', (e:any) => {
            //     console.log("An error occurred while attempting to connect.");
            //     this.engineSource.close()
            // });
        }
    }

}


export const liveManager = new LiveManager()
