/* eslint-disabled */

export default class PaymentMethodExtend {


    color = ''

    constructor(object?: any) {
    }

    postConstruct() {
    }
}
