<template>
  <div id="notifications" class="d-flex flex-column justify-content-end align-items-end">
    <transition-group name="slideRight" tag="div">
      <div :key="'notif-'+notification.uuid" class="notification card bgi-no-repeat card-xl-stretch text-start mw-400px"
           :class="'bg-'+notification.color+' text-inverse-'+notification.color"
           v-for="notification in $store.state.notificationsPopups"  @mouseover="notification.stopTimeout()"
           @mouseleave="notification.configureTimeout()">
        <div class="card-body p-3 d-flex align-items-center">
          <div class="card-body p-3 d-flex align-items-center flex-grow-0">
            <div class="position-relative w-30px h-30px d-flex justify-content-center align-items-center">
              <img :src="notification.photo" alt="" v-if="isUrl(notification.photo)">
              <img :src="uploadRoot + notification.photo" alt="" v-else-if="isFile(notification.photo)"
                   :class="'text-inverse-'+notification.color">
              <i v-else-if="isIcon(notification.photo)"
                 :class="notification.photo+' fs-2x text-inverse-'+notification.color"></i>
              <i v-else class="fad fa-sync fs-2x" :class="'text-inverse-'+notification.color"></i>
            </div>
          </div>

          <div class="flex-grow-1 px-2 fs-4">
            <div class="fw-bolder text-uppercase">
              {{ notification.title }}
            </div>
            <div class=" fw-bolder text-uppercase fs-6" v-if="!helper.empty(notification.subtitle)">
              {{ notification.subtitle }}
            </div>
            <p class="fw-bold m-0 fs-4" style="white-space: pre-wrap;word-wrap: break-word;font-family: inherit;" v-html="notification.content">
            </p>
          </div>
          <div>
            <a class="btn btn-sm btn-icon">
                    <span @click.prevent="notification.hide()"
                          class="svg-icon svg-icon-1">
                        <i class="fad fa-times"></i>
                    </span>
            </a>
          </div>
        </div>
        <div v-if="notification.keepAlive" class="loader-line"></div>
      </div>
    </transition-group>
  </div>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Notification from "@/entity/Notification";
import {auth} from "@/services/Auth";

@Component({})
export default class Notifications extends Vue {

  uploadRoot = api.uploadRoot

  isFile(photo: string) {
    return photo && photo.includes('.') && photo.split('.').length === 2
  }

  isUrl(photo: string) {
    return photo && photo.toLowerCase().startsWith('http')
  }

  isIcon(photo: string) {
    return photo && photo.startsWith('fa')
  }

  mounted() {
    // this.load()
  }

  async load() {
    if (auth.hasRole('ROLE_MANAGER')) {
      await this.$store.state.sleep(2000)
      const res = await api.get(api.auth, 'user/load/notifications')
      if (res && res.data && res.data.notifications) {
        res.data.notifications.forEach((n: any) => {
          const occ = new Notification(n)
        })
      }
    }
  }


}
</script>
<style scoped>
#notifications {
  position: fixed;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: 10px;
  right: 0;
  padding: 20px;
  z-index: 2000;
}

.notification {
  position: relative;
  pointer-events: auto;
  box-shadow: 0 0 5Px gainsboro;
  max-width: 90%;
  min-width: 250px;
  overflow: hidden;
  margin: 5px 0;
  background: rgba(255, 255, 255, .5);
  backdrop-filter: blur(2px);
}

.spinner {
  top: 0;
  left: 0;
  border: 2px transparent solid;
  border-top: 2px white solid;

  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.bg-primary .spinner, .bg-danger .spinner, .bg-warning .spinner {
  border-top: 2px white solid;
}

.icon {
  /*position: absolute;*/
  font-size: 20px;
  /*padding-right: 10px;*/
  /*opacity: .1;*/
  /*left: -25px;*/
  /*top: -50px;*/
  /*z-index: 0;*/
}

.slideRight-enter-active, .slideRight-leave-active {
  transition: all .5s ease;
}

.slideRight-enter, .slideRight-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: var(--secondary);
  //margin: 100px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: var(--primary);
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
</style>
