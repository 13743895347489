import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";
import configurationRoutes from "@/router/configuration";
import crmRoutes from "@/router/crm";
import incomeRoutes from "@/router/income";
import errorRoutes from "@/router/error";
import comparatorRoutes from "@/router/comparator";
import adminRoutes from "./admin";
import partnerRoutes from "./partner";
import PartnerLogin from "@/views/Partner/PartnerLogin.vue";
import ManagerLogin from "@/views/ManagerLogin.vue";
import prospectionRoutes from "./prospection";
import chatRoutes from "@/router/chat";
import accountingRoutes from "@/router/accounting";
import wicare from "@/views/wicare.vue";
import wicareRoutes from "@/router/wicare";


Vue.use(VueRouter);
const defaultRoutes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: Home,
    },

    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/partner/login",
        name: "partner-login",
        component: PartnerLogin,
    },
    {
        path: "/manager/login",
        name: "manager-login",
        component: ManagerLogin,
    },
    // {
    //     path: '/config',
    //     name: 'config',
    //     component: Config,
    //     meta: {requiredRoles: ['ROLE_USER']},
    // },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Home,
    },
];

let base = "/";
let routes = defaultRoutes
    .concat(configurationRoutes)
    .concat(incomeRoutes)
    //   .concat(communicationRoutes)
    .concat(errorRoutes)
    .concat(comparatorRoutes)
    .concat(adminRoutes)
    .concat(partnerRoutes)
    .concat(accountingRoutes)
    //   .concat(prospectionRoutes)
    //   .concat(chatRoutes)
    .concat(wicareRoutes)
    .concat(crmRoutes);


const host = window.location.host;
const parts = host.split(".");
const domainLength = 3;
// console.log(parts)
if (parts[0] === "comparateur") {
    for (let route of comparatorRoutes) {
        route.path = route.path.replace("/comparateur", "");
    }
    routes = comparatorRoutes;

    // base = '/comparateur/'
}
// console.log(routes)
// console.log(base)
// if (parts.length === (domainLength - 1) || parts[0] === 'www') {
//     routes = index;
// } else if (parts[0] === 'route1') {
//     routes = route1;
// } else if (parts[0] === 'route2') {
//     routes = route2;
// } else {
//     // If you want to do something else just comment the line below
//     routes = index;
// }

const router = new VueRouter({
    mode: "history",
    base: base,
    routes,
});

export default router;
