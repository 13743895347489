<template>
  <Base>
    <template v-slot:content>

      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-9 pb-0">
          <Loading v-if="isLoading"></Loading>
          <div class="d-flex flex-wrap flex-sm-nowrap mb-3" v-else-if="customer">
            <div class="me-7 mb-4">
              <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img :src="uploadRoot + customer.photo" alt="image" class="object-cover" v-if="customer.photo">
                <div v-else
                     class="w-100px h-100px h-lg-175px w-lg-160px d-flex align-items-center justify-content-center shadow-xs rounded">
                  <i class="fad fa-user fs-5x"></i>
                  <!--                                    <img  alt="image" class="object-cover" style="opacity: 0">-->
                </div>
                <div
                    class="bg-success position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px">
                </div>
              </div>
            </div>
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-2">
                    <div class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      {{ customer.identifier }}
                    </div>
                    <div class="btn btn-icon btn-primary ms-5 btn-xs" v-b-modal="'customerForm'">
                      <i class="fad fa-edit"></i>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                    <div class="d-flex align-items-center">
                      <i class="fad fa-phone-alt fs-2 me-5"></i>
                      <div class="mx-5 badge badge-primary">
                        - {{ customer.mobile }}
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="fad fa-phone-rotary fs-2 ms-5"></i>
                      <div class="mx-5 badge badge-primary">
                        - {{ customer.fixe }}
                      </div>
                    </div>
                  </div>
                  <div class=" my-4">
                    <div class="fw-bolder d-flex align-items-center">
                      <!--                      <div :title="readableDate(contract.startAt, 'dmY')" v-b-tooltip.hover v-if="contract.startAt">-->
                      <!--                        {{ readableDate(contract.startAt, 'dmY') }}-->
                      <!--                      </div>-->
                      <!--                      <div class="mx-1 d-flex align-items-center">-->
                      <!--                        ↔-->
                      <!--                      </div>-->
                      <!--                      <div :title="readableDate(contract.expireAt, 'dmY')" v-b-tooltip.hover v-if="contract.expireAt">-->
                      <!--                        {{ readableDate(contract.expireAt, 'dmY') }}-->
                      <!--                      </div>-->
                    </div>
                  </div>
                  <!--                                    <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center" href="#">-->
                  <!--                                            <i class="fad fa-user me-2"></i>-->
                  <!--                                            Developer</a>-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center" href="#">-->
                  <!--                                           <i class="fad fa-map-marker me-2"></i>-->
                  <!--                                            SF, Bay Area-->
                  <!--                                        </a>-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 d-flex align-items-center" href="#">-->
                  <!--                                            <i class="fad fa-at me-2"></i>-->
                  <!--                                            max@kt.com-->
                  <!--                                        </a>-->
                  <!--                                    </div>-->
                </div>
                <div class="d-flex my-4 align-items-center">
                  <div class="mx-5 fw-bolder">
                    <!--                    <user-view v-model="contract.manager"></user-view>-->
                  </div>
                  <!--                  <div :class="{-->
                  <!--                                    'bg-success': contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,-->
                  <!--                                    'bg-warning': contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,-->
                  <!--                                    'bg-danger': contract.status === CONSTANTS.CONTRACT_STATUS.TERMINATED,-->
                  <!--                                    'bg-primary': contract.status === CONSTANTS.CONTRACT_STATUS.SUSPENDED-->
                  <!--                                }" class="btn btn-sm btn-primary me-3">{{ contract.status }}-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="d-flex flex-wrap flex-stack">
                <div class="d-flex flex-column flex-grow-1 pe-8">
                  <div class="d-flex flex-wrap">
                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                          <i class="fad fa-coins"></i>
                        </span>
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true">
                          {{ currency(customer.account.balance) }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Solde</div>
                    </div>

                    <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                          <i class="fad fa-file-contract"></i>
                        </span>
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true">
                          {{ currency(customer.contractValue) }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Valeur Contrat</div>
                    </div>


                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Info-->
          </div>

          <b-tabs active-nav-item-class="" active-tab-class="" nav-wrapper-class="nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap
                            flex-grow-1" v-model="tabIndex">
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-layer-group fs-2 me-2"></i>
                  Contrats
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-object-group fs-2 me-2"></i>
                  Avenants
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center" @click="loadAccountData">
                  <i class="fad fa-file-contract fs-2 me-2"></i>
                  Compte
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center" @click="loadAccountData">
                  <i class="fad fa-file-contract fs-2 me-2"></i>
                  Reçus
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-coins fs-2 me-2"></i>
                  Echeances
                </div>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>


      <div class="card" key="avenants">
        <div class="card-body">
          <Loading v-if="isLoading"></Loading>
          <template v-else-if="customer">
            <div v-if="tabIndex === 0">
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Type</th>
                  <th>Période</th>
                  <th>Montant/Reste</th>
                  <th>Apporteur</th>
                  <th>Agent</th>
                  <th>Statut</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loadingContracts">
                  <td colspan="7">
                    <Loading/>
                  </td>
                </tr>
                <tr v-for="contract in contracts">
                  <td>
                    <div>

                      <div class="text-gray-400 fw-bolder d-flex align-items-center">
                        <i :class="'fad fs-2 ' + contract.branch.icon" class="me-2 text-gray-400"></i>
                        {{ contract.branch.label }} ▪ {{ contract.branch.category.label }}
                      </div>
                      <router-link :to="{ name : 'contract-show', params: { contractUuid : contract.uuid } } "
                                   class="d-flex align-items-center fw-bolder">
                        {{ contract.insurer.label }} ▪ {{ contract.number }}
                      </router-link>

                      <!--                      <div class="me-2">-->
                      <!--                        Créer le {{ readableDate(contract.createdAt, 'dMY H:i') }}-->
                      <!--                      </div>-->
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="fw-bolder align-items-center">
                      <div :title="readableDate(contract.startAt, 'dmY')" v-b-tooltip.hover
                           v-if="contract.startAt">
                        {{ readableDate(contract.startAt, 'dMY') }}
                      </div>
                      <div :title="readableDate(contract.expireAt, 'dmY')" v-b-tooltip.hover
                           v-if="contract.expireAt">
                        {{ readableDate(contract.expireAt, 'dMY') }}
                      </div>
                    </div>
                  </td>
                  <td class="text-end">
                    <span class="fw-bolder">
                      {{ currency(contract.primeTTC) }}
                    </span>
                    <br>
                    <span class="text-danger fw-bolder">
                      {{ currency(contract.balance, true) }}
                    </span>
                  </td>
                  <td>
                    <template v-if="contract.partner">
                      <div class="fw-bolder">
                        {{ contract.partner.name }}
                      </div>
                      <div class="text-gray-400 fw-bolder fs-8">
                        {{ contract.partner.code }}
                      </div>
                    </template>
                  </td>
                  <td>
                    <user-view v-model="contract.manager"></user-view>
                  </td>
                  <td>
                    <div>
                                            <span :class="{
                                                'badge-primary': contract.status === CONSTANTS.CONTRACT_STATUS.SUSPENDED,
                                                'badge-warning': contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,
                                                'badge-success': contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,
                                                'badge-danger': contract.status === CONSTANTS.CONTRACT_STATUS.TERMINATED,
                                            }" class="badge">
                                                {{ contract.status }}
                                            </span>
                    </div>
                  </td>
                  <td>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>


            <div v-if="tabIndex === 1">

              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Actif</th>
                  <th>Assuré</th>
                  <th>Pack</th>
                  <th>Ajoute le</th>
                  <th>Prime</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                </tbody>
              </table>

            </div>
            <div v-if="tabIndex === 2">
              <!--                            <h2>Contrat</h2>-->

              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center p-5">
                    <div class="d-flex justify-content-end flex-grow-1">
                      <b-dropdown no-caret no-flip right size="sm" tag="div"
                                  toggle-class="topbar-item text-decoration-none p-0"
                                  variant="link">
                        <template v-slot:button-content>
                          <div class="btn btn-warning me-2 btn-sm">
                            <!--                            <i class="fad fa-money-bill-wave"></i>-->
                            Recharger le comtpe client
                          </div>
                        </template>
                        <b-dropdown-text class="min-w-md-350px p-5" tag="div">
                          <div>
                            <label class="fw-bolder mb-2" for="">{{ trans('Recharger le compte client') }}</label>
                            <div>
                              <b-input-group>
                                <b-form-input class="text-end" type="number"
                                              v-model="amount"></b-form-input>
                                <b-input-group-append>
                                  <div @click="loadCustomerAccount"
                                       class="btn btn-sm border-radius-left-0 d-flex align-items-center btn-primary h-100">
                                    <i class="fad fa-money-bill"></i>
                                    Recharger
                                  </div>
                                </b-input-group-append>
                              </b-input-group>
                            </div>
                          </div>
                        </b-dropdown-text>
                      </b-dropdown>
                      <div class="btn btn-primary btn-sm ms-5" v-b-modal="'avenantViewer'" @click="loadAvenants">
                        Effectuer un paiement
                      </div>
                    </div>
                  </div>

                  <Loading v-if="loadingAccountData"></Loading>
                  <div>
                    <thead>
                    <tr>
                      <th>
                        Débit
                      </th>
                      <th>
                        Crédit
                      </th>
                    </tr>
                    </thead>
                    <table class="table table-striped border gx-4" v-if="account">
                      <tbody>
                      <tr v-for="operation in account.operations">
                        <td v-if="operation.type === 'Crédit'"></td>
                        <td class="w-50 border">
                          <div>
                            <div class="d-flex align-items-center justify-content-between">
                              <div class="d-flex align-items-center">
                                <i class="fad fa-inbox-in text-success fs-2" v-if="operation.type === 'Débit'"></i>
                                <i class="fad fa-inbox-out text-danger fs-2" v-if="operation.type === 'Crédit'"></i>
                                <div class="fw-bolder fs-2 ms-3"
                                     :class="{'text-success' : operation.type === 'Débit','text-danger' : operation.type === 'Crédit'}">
                                  {{ currency(operation.amount) }}
                                </div>
                              </div>
                              <div class="d-flex align-items-center">
                                <div class="fw-bolder fs-2">
                                  {{ datify(operation.createdAt) }}
                                </div>
                                <div v-if="operation.transaction.receipt">
                                  <router-link target="_blank" :to="{ name : 'receipt-show', params: {uuid : operation.transaction.receipt.uuid } }"
                                               class="btn btn-sm ms-5 btn-primary" >
                                    Reçu
                                  </router-link>
                                </div>
                              </div>
                            </div>
                            <div class="fw-bolder fs-5">
                              {{ operation.transaction.label }}
                            </div>
                          </div>
                        </td>
                        <td v-if="operation.type === 'Débit'"></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>


                </div>
              </div>


            </div>
            <div v-if="tabIndex === 3">

              <table class="table table-stripped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Libellé</th>
                  <th>Initiateur</th>
                  <th>Montant</th>
                </tr>
                </thead>
                <tbody>

                </tbody>
              </table>

            </div>
            <div v-if="tabIndex === 4">4</div>


          </template>
        </div>
      </div>

      <b-modal :ref="'avenantViewer'" :title="trans('Avenants')" centered class="modal-fullscreen" id="avenantViewer"
               scrollable size="xl">
        <div>
          <table class="table table-striped gx-5">
            <tbody>
            <tr v-if="loadingAvenants">
              <td colspan="5">
                <Loading/>
              </td>
            </tr>
            <template v-for="avenant of avenants">
              <tr>
                <!--                <td>-->
                <!--                  <label class="d-flex align-items-center cursor-pointer text-primary">-->
                <!--                    <div class="form-check form-check-custom  form-check-sm">-->
                <!--                      <input class="form-check-input" type="checkbox">-->
                <!--                    </div>-->
                <!--                  </label>-->
                <!--                </td>-->
                <td>
                  <div>
                    <div>
                      {{ avenant.contract.branch.label }}
                    </div>
                    <div>
                      {{ avenant.contract.insurer.label }}
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      AV{{ avenant.numero }} ︎■ {{ avenant.type }}
                    </div>
                    <div class="fw-bolder">
                      <template v-if="avenant.assetLabels.length === 1">
                        {{ avenant.assetLabels[0] }}
                      </template>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div class="fw-bolder fs-5 text-primary">
                      {{ currency(avenant.primeTTC) }}
                    </div>
                    <div class="fw-bolder fs-5 text-danger cursor-pointer"
                         @click="avenant.paymentAmount = Math.abs(avenant.balance) ">
                      {{ currency(avenant.balance, true) }}
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="avenant.balance < 0">
                    <b-form-input v-model="avenant.paymentAmount"/>
                  </div>
                  <div v-else class="badge badge-success">
                    Payé
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="makePaiement(); hide()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Payer') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :ref="'customerForm'" :title="trans('formulaire Client')" centered class="modal-fullscreen"
               id="customerForm" scrollable size="xl">
        <div class="d-flex flex-wrap" v-if="customer">
          <div class="w-100">
            <FileUpload :allowMultiple="false" :allowRemove="false"
                        :allowedType="'image/jpeg,image/png,image/gif,image/jfif'" :fitMode="'contain'"
                        :icon="'fad fa-edit'" :sub-folder="'customers'"
                        :previewClass="'d-flex border-dashed w-150px object-contain cursor-pointer h-150px align-items-center justify-content-center m-auto'"
                        :text="'Selectionnez une photo'" :type="'full'" accept=".jpg,.jpeg,.png,.gif,.jfif"
                        v-model="customer.photo"></FileUpload>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Civilité') }}</label>
            <select class="form-control form-select" v-model="customer.civilite">
              <option value="Mr">Monsieur</option>
              <option value="Mlle">Mademoiselle</option>
              <option value="Mme">Madame</option>
            </select>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Noms') }}</label>
            <b-form-input :id="'label'" :state="!helper.empty(customer.name)" type="text"
                          v-model="customer.name"></b-form-input>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Prénoms') }}</label>
            <b-form-input :id="'label'" :state="!helper.empty(customer.prename)" type="text"
                          v-model="customer.prename"></b-form-input>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Email') }}</label>
            <b-form-input :id="'label'" :state="!helper.empty(customer.email)" type="text"
                          v-model="customer.email"></b-form-input>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Mobile') }}</label>
            <b-form-input :id="'label'" :state="!helper.empty(customer.mobile)" type="text"
                          v-model="customer.mobile"></b-form-input>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Fixe') }}</label>
            <b-form-input :id="'label'" :state="!helper.empty(customer.fixe)" type="text"
                          v-model="customer.fixe"></b-form-input>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Fonction') }}</label>
            <b-form-input :id="'label'" :state="!helper.empty(customer.fonction)" type="text"
                          v-model="customer.fonction"></b-form-input>
          </div>

          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Numero de piece') }}</label>
            <b-form-input :id="'label'" :state="!helper.empty(customer.idNumber)" type="text"
                          v-model="customer.idNumber"></b-form-input>
          </div>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveCustomer(); hide()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Enregistrer') }}
          </b-button>
        </template>
      </b-modal>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Customer from "@/entity/Customer"
import Contract from "@/entity/Contract"
import {api} from '@/services/Api';
import UserView from "@/components/UserView.vue";
import Loading from "@/components/Loading.vue";
import Account from "@/entity/Account";
import Avenant from "@/entity/Avenant";
import Popup from '@/entity/Popup';
import FileUpload from "@/components/FileUpload.vue";

@Component({
  components: {FileUpload, Loading, Base, UserView}
})
export default class CustomerShow extends Vue {

  tabIndex = 0
  isLoading = false
  customer: any = null
  account: any = null
  contracts: Contract[] = []

  uploadRoot = api.uploadRoot
  subscriber = new Customer()
  amount = 0
  loadingContracts = false

  mounted() {
    this.load()
    this.loadContracts()
  }

  async showReceipt(uuid: string) {
    const res = await api.get(api.core, 'payment/receipt/' + uuid)
  }

  async makePaiement() {
    const paiements: any[] = []
    for (let avenant of this.avenants) {
      if (avenant.paymentAmount > 0) {
        if (avenant.paymentAmount > Math.abs(avenant.balance)) {
          new Popup('Montant incorrect', 'Le montant attribué a l\'avenant N°' + avenant.numero + ' prime TTC : ' + avenant.primeTTC + ' est supérieur au montant dû', 'danger');
          return
        }
        paiements.push({avenant: avenant.uuid, amount: avenant.paymentAmount})

      }
    }
    const res = await api.post(api.core, 'checkout/pay/avenants', {data: paiements})
    if (res && res.data) {
      this.loadAccountData()
    }
  }

  async saveCustomer() {
    this.$store.commit('loading')
    const res = await api.post(api.core, 'customer/edit', {customer: this.customer})
    this.$store.commit('stopLoading')
    if (res && res.data) {
      this.load()
    }
  }

  async loadCustomerAccount() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'customer/load/account/' + this.customer.uuid + '/' + this.amount)
    if (res && res.data) {
      this.load()
      this.loadAccountData()
    }
    this.$store.commit('stopLoading')
  }

  async load() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'customer/get/data/' + this.$route.params.uuid)
    if (res && res.data && res.data.customer) {
      this.customer = new Customer(res.data.customer)
    }
    this.$store.commit('stopLoading')
  }

  async loadContracts() {
    this.loadingContracts = true
    const res = await api.get(api.core, 'customer/get/contracts/' + this.$route.params.uuid)
    if (res && res.data && res.data.contracts) {
      this.contracts = []
      res.data.contracts.forEach((c: any) => {
        const occ = new Contract(c)
        this.contracts.push(occ)
      })
    }
    this.loadingContracts = false
  }

  avenants: Avenant[] = []
  loadingAvenants = false

  async loadAvenants() {
    this.loadingAvenants = true
    const res = await api.get(api.core, 'customer/get/avenants/' + this.$route.params.uuid)
    if (res && res.data && res.data.avenants) {
      this.avenants = []
      res.data.avenants.forEach((c: any) => {
        const occ = new Avenant(c)
        this.avenants.push(occ)
      })
    }
    this.loadingAvenants = false
  }


  loadingAccountData = false

  async loadAccountData() {
    this.loadingAccountData = true
    const res = await api.get(api.core, 'customer/get/account/data/' + this.$route.params.uuid)
    if (res && res.data && res.data.account) {
      this.account = new Account(res.data.account)
    }
    this.loadingAccountData = false
  }

  async loadDeadlines() {

  }
}
</script>
<style scoped>
</style>
