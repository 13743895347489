<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                  <i class="fad fa-home"></i>
                  {{ trans("Dashboard") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>
      </div>
    </div>
  </template>

  <template #content>
    <div class="fs-2 my-5 fw-bolder">{{ campaign.name }} | {{ campaign.code }}</div>

    <p>
      {{ campaign.description }}
    </p>



    <b-card class=" h-100">


      <div class="d-flex flex-column align-items-center">

        <button type="button" v-b-modal.modal-event class="btn btn-success my-5">
          {{ event ? event.name : 'Choisissez un evenement' }}
        </button>
        <template v-if="event || campaign.actions.length > 0">
          <template v-for="(item, index) in campaign.actions">
            <div class="my-5 position-relative w-100 d-flex align-items-center flex-column"
              @mouseenter="ViewOptions(true, index, 'add')" @mouseleave="ViewOptions(false, index, 'add')">
              <div style="border: 1px gray dashed; height: 2rem;" class="" />

              <i class="fad fa-plus position-absolute cursor-pointer text-primary bg-white fs-4 p-3 "
                @click="AddAction(item.ranking)"
                v-if="showOptions.show && showOptions.index == index && showOptions.type == 'add'"
                style=" color:red ; cursor: pointer; top: 15%; border-radius: 100%;"></i>
              <div style="border: 1px gray dashed; height: 2rem;" class="" />

            </div>

            <div class="position-relative w-25 justify-content-center d-flex"
              @mouseenter="ViewOptions(true, index, 'delete')" @mouseleave="ViewOptions(false, index, 'delete')">
              <button :class="colorType(item)" type="button" @click="EditAction(item)" :key="index" class="btn ">
                {{ reType(item) }}
              </button>
              <i class="fad fa-trash  cursor-pointer  ms-3 fs-1" @click="DeleteAction(item.id)"
                v-if="showOptions.show && showOptions.index == index && showOptions.type == 'delete'"
                style="right: 0; top: 10px; bottom: 0; color:red ; cursor: pointer;"></i>
            </div>
          </template>


          <div style="border: 1px gray dashed; height: 5rem;" class="mt-3" />
          <button type="button" v-b-modal.modal-action class="btn btn-secondary my-5">
            Ajouter une action
          </button>

        </template>

      </div>


    </b-card>



    <b-modal @ok="handleOkEvent" id="modal-event" centered title="Assigner un evenement">
      <div>
        <div>
          <label for="event">Services</label>
          <select class="form-control form-select mt-3" id="evenement" @change="selectService">
            <option value=" null">Choisissez un service</option>
            <option value="core">CORE</option>
            <!--   <option value="form">FORM</option> -->
          </select>

        </div>


        <div v-if="events.length > 0" class="mt-4">
          <label for="evenement">Évenements</label>
          <select class="form-control form-select mt-3" id="evenement" v-model="event">
            <option value="null">Selectionnez un evenement</option>
            <option :value="event" v-for="event in events">
              {{ event.name }}
            </option>
          </select>
        </div>

      </div>
    </b-modal>

    <b-modal @ok="handleOkAction" id="modal-action" @close="cancel" @cancel="cancel" centered
      title="Ajouter une action">
      <div v-if="!formAction.type" class="row gx-5 text-center text-uppercase fw-bolder">
        <div class="p-5 me-3 bg-primary col d-flex flex-column rounded text-white"
          @click="formAction.type = 'SEND_EMAIL'">
          <i class="fad fa-envelope text-white" style="font-size: 5rem"></i>
          <span class="mt-2"> Envoyer un mail </span>
        </div>

        <div class="p-5 me-3 bg-warning col d-flex flex-column rounded" @click="formAction.type = 'SEND_SMS'">
          <i class="fad fa-sms" style="font-size: 5rem; color: black"></i>
          <span class="mt-2"> Envoyer un SMS </span>
        </div>

        <div class="p-5 bg-info col d-flex flex-column rounded text-white" @click="formAction.type = 'WAIT'">
          <i class="fad fa-pause text-white" style="font-size: 5rem"></i>
          <span class="mt-2"> Attendre </span>
        </div>
      </div>

      <div v-if="formAction.type == 'SEND_EMAIL'">
        <form>
          <div class="mb-3">
            <label for="emailTemplate" class="form-label">Utiliser un modele d'email</label>
            <select class="form-select" id="emailTemplate" v-model="formAction.emailTemplate">
              <option value="hours" selected>Selectionnez un modele</option>
              <option v-for="(item, index) in modelEmailList" :key="index" :value="item">
                {{ item.name }}
              </option>
            </select>
          </div>

          <!--
            <div class="border my-3" />

            <h4>Rediger le mail</h4>

            <div class="mb-3">
              <label for="object" class="form-label">Objet</label>
              <input type="text" class="form-control" id="object" />
            </div>

            <div>
              <label class="my-2">Ecrivez votre message</label>
              <div class="form-floating">
                <textarea
                  v-model="message"
                  @input="onInput"
                  @keydown="onKeydown"
                  ref="suggestionArea"
                  class="form-control"
                  placeholder="Ecrivez votre message"
                  id="floatingTextarea2"
                  style="height: 100px"
                ></textarea>
                <label for="floatingTextarea2">Message</label>
              </div>

              <div v-if="showSuggestions" class="suggestions">
                <ul>
                  <li
                    v-for="suggestion in filteredSuggestions"
                    :key="suggestion"
                    @click="addMention(suggestion)"
                  >
                    {{ suggestion }}
                  </li>
                </ul>
              </div>
            </div>

-->


        </form>
      </div>

      <div v-if="formAction.type == 'SEND_SMS'">
        <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Utiliser un modele d'SMS
            </label>
            <select class="form-select" id="inputGroupSelect01" v-model="formAction.smsTemplate">
              <option value="hours" selected>Selectionnez un modele</option>
              <option v-for="(item, index) in modelSmsList" :key="index" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>

          <!--
            <div class="border my-3" />

            <h4>Rediger le message</h4>
            <div>
              <label class="my-2">Ecrivez votre message</label>
              <div class="form-floating">
                <textarea
                  v-model="message"
                  @input="onInput"
                  @keydown="onKeydown"
                  ref="suggestionArea"
                  class="form-control"
                  placeholder="Ecrivez votre message"
                  id="floatingTextarea2"
                  style="height: 100px"
                ></textarea>
                <label for="floatingTextarea2">Message</label>
              </div>

              <div v-if="showSuggestions" class="suggestions">
                <ul>
                  <li
                    v-for="suggestion in filteredSuggestions"
                    :key="suggestion"
                    @click="addMention(suggestion)"
                  >
                    {{ suggestion }}
                  </li>
                </ul>
              </div>
            </div>

-->

        </form>
      </div>

      <div v-if="formAction.type == 'WAIT'" class="d-flex flex-column">
        <label for="time" class="mb-3"> Temps d'attente : </label>

        <div class="input-group mb-3">
          <input type="number" class="form-control" aria-describedby="basic-addon1" v-model="timeData.time" />
          <select class="form-select" id="inputGroupSelect01" v-model="timeData.type">
            <option value="minutes" selected>Minutes</option>
            <option value="heures">Heures</option>
            <option value="jours">jours</option>
            <option value="semaines">Semaines</option>
            <option value="mois">Mois</option>
            <option value="années">Années</option>
          </select>
        </div>
      </div>
    </b-modal>


  </template>
  </Base>
</template>

<script lang="ts">
import Base from "@/layouts/Base.vue";
import { Vue, Component, Ref } from "vue-property-decorator";
import { api } from "@/services/Api";
import EmailTemplate from "@/entity/EmailTemplate";
import SmsTemplate from "@/entity/SmsTemplate";
import VFlowChart from "vue2-flow";
import Campaign from "@/entity/Campaign";
import Action from "@/entity/Action";
import { helper } from "@/services/Helper";

@Component({
  components: { Base, VFlowChart },
})
export default class CampaignCreate extends Vue {
  @Ref("suggestionArea") readonly suggestionArea!: any;
  @Ref("button-delete") readonly buttonDelete!: any;

  formAction = new Action();
  nodeEvent: any = null;
  nodeAction: any = null;
  event: any = null;
  events: any = [];
  message = "";
  suggestions: any = null;
  showSuggestions = false;
  filteredSuggestions: any[] = [];
  mentionStartIndex = -1;
  timeData = {
    time: 0,
    type: "heures",
  };
  modelEmailList: EmailTemplate[] = [];
  modelSmsList: SmsTemplate[] = [];
  campaign: Campaign = new Campaign();
  showOptions = {
    index: null,
    show: false,
    type: ''

  };

  ViewOptions(show: boolean, index: any, type: string) {

    this.showOptions.index = index;
    this.showOptions.show = show;
    this.showOptions.type = type;

  }
  adjustRanking = (tasks: any[]) => {
    tasks.sort((a, b) => a.id + b.id);  // Ensure tasks are sorted by id
    tasks.forEach((task, index) => {
      task.ranking = index + 1;  // Update ranking to be continuous
    });


    return tasks;

    // console.log(tasks);

  };
  async DeleteAction(id: any) {

    this.$store.commit("loading");

    const index = this.campaign.actions.findIndex(task => task.id === id);

    if (index !== -1) {
      this.campaign.actions.splice(index, 1);
      this.adjustRanking(this.campaign.actions);
    }

    const resDelete = await api.delete(api.com, "action/delete/" + id);


    if (resDelete) {
      const res = await api.post(api.com, "campaign/edit", {
        campaign: this.campaign,
      });

      if (res) {
        this.$store.commit("stopLoading");
        this.campaign = res.data.campaign
        this.$forceUpdate()
      }

    }





  }
  async AddAction(index: number) {
    this.$bvModal.show("modal-action");
    this.formAction = new Action()
    this.formAction.ranking = index
  }
  reType(item: Action) {
    if (item.type == "SEND_EMAIL") {
      return `Envoyer l'email ${item.emailTemplate.name}`
    } else if (item.type == "SEND_SMS") {
      return `Envoyer l'SMS ${item.smsTemplate.name}`
    }
    else {
      return `Attendre ${helper.ConvertTime(item.waitingTime)}`
    }

  }
  colorType(item: Action) {
    if (item.type == "SEND_EMAIL") {
      return `btn-primary`
    } else if (item.type == "SEND_SMS") {
      return `btn-warning`
    }
    else {
      return `btn-info`
    }

  }
  serviceSelected = null
  async selectService(event: any) {
    const service = event.target.value;
    const apis: any = api
    const resEvents = await api.get(apis[service], "custom/event/list");
    this.events = resEvents.data.events;
    this.serviceSelected = service

  }
  async handleOkEvent() {
    this.$bvModal.hide("modal-event");
    if (this.event) {
      const serviceSelected: any = this.serviceSelected
      const apis: any = api
      const res = await api.post(apis[serviceSelected], "custom/event/subscription/add", {
        event: this.event.name,
        code: this.campaign.code
      });
    }



    this.$forceUpdate();
  }
  async handleOkAction() {

    this.$bvModal.hide("modal-action");

    if (this.formAction.type == "WAIT") {
      const time = this.convertToMinutes(
        this.timeData.time,
        this.timeData.type
      );

      this.formAction.waitingTime = time;

    }

    // if (this.formAction.type == "SEND_EMAIL") {
    //   this.nodeAction.name =
    //     "Envoyer le mail" + " " + this.formAction.emailTemplate.name;
    // }

    // if (this.formAction.type == "SEND_SMS") {
    //   this.nodeAction.name =
    //     "Envoyer l'SMS" + " " + this.formAction.smsTemplate.name;
    // }

    // if (this.formAction) {
    //   this.nodes[
    //     this.nodes.findIndex((item: any) => item.id == this.nodeAction.id)
    //   ] = this.nodeAction;
    //   // this.suggestions = this.events && Object.keys(this.event.schema);
    // }

    // this.formAction.ranking = this.campaign.actions.length

    const index = this.campaign.actions.findIndex(obj => obj.id === this.formAction.id);
    if (index !== -1) {
      this.campaign.actions[index] = this.formAction;
    } else {


      console.log(this.formAction.ranking);

      if (this.formAction.ranking !== 0) {
        console.log(this.formAction.ranking);
        this.campaign.actions.splice(this.formAction.ranking - 1, 0, this.formAction);
      } else {
        this.campaign.actions.push(this.formAction);
      }





    }
    const adjustRankingActions: Action[] = this.adjustRanking(this.campaign.actions)


    const campaign = {
      ...this.campaign,
      actions: adjustRankingActions
    }


    this.$store.commit("loading");

    const res =
      await api.post(api.com, "campaign/edit", {
        campaign: campaign,
      });
    if (res) {
      this.load()
    }

    const formAction = new Action();
    this.formAction = formAction;

    this.timeData = {
      time: 0,
      type: "heures",
    };

    // this.$forceUpdate();
  }

  EditAction(item: Action) {

    // let action = new Action()

    this.formAction = item
    // this.formAction.id = item.id
    // this.formAction.ranking = item.ranking
    this.$bvModal.show("modal-action");
  }

  cancel() {
    const formAction = new Action();
    this.formAction = formAction;
    this.timeData = {
      time: 0,
      type: "heures",
    };
    this.$forceUpdate();
  }
  mounted() {
    this.load();
  }
  async load() {
    this.$store.commit("loading");
    const res = await api.get(
      api.com,
      "campaign/show/" + this.$route.params.id
    );

    const resEmailList = await api.get(api.com, "email/template/list");
    const resSmsList = await api.get(api.com, "sms/template/list");

    this.modelEmailList = resEmailList.data.templates;
    this.modelSmsList =resSmsList && resSmsList.data && resSmsList.data.templates ? resSmsList.data.templates : [] ;
    if (res && res.data) {
      this.campaign = res.data.campaign;
    }

    // if (this.campaign.mapping) {
    //   const mapping: any = this.campaign.mapping;
    //   this.nodes = mapping.nodes;
    //   this.conns = mapping.conns;
    //   this.addNewNode(this.nodes[this.nodes.length - 1], "Ajouter une etape");
    // }
    this.$store.commit("stopLoading");
  }
  convertToMinutes(number: number, type: string) {
    // console.log(type);
    const minutesPerHour = 60;
    const minutesPerDay = 24 * minutesPerHour;
    const minutesPerWeek = 7 * minutesPerDay;
    const minutesPerMonth = 30 * minutesPerDay; // approximation
    const minutesPerYear = 365 * minutesPerDay; // approximation

    switch (type.toLowerCase()) {
      case "minutes":
      case "minutes":
        return number;
      case "heures":
      case "hours":
        return number * minutesPerHour;
      case "jours":
      case "days":
        return number * minutesPerDay;
      case "semaines":
      case "weeks":
        return number * minutesPerWeek;
      case "mois":
      case "months":
        return number * minutesPerMonth;
      case "années":
      case "years":
        return number * minutesPerYear;
      default:
        throw new Error("Type de temps non valide");
    }
  }
  onInput(event: any) {
    const cursorPosition = event.target.selectionStart;
    const textBeforeCursor = this.message.slice(0, cursorPosition);

    const mentionMatch: any = textBeforeCursor.match(/@(\w*)$/);
    if (mentionMatch) {
      this.showSuggestions = true;
      this.filteredSuggestions = this.suggestions.filter((suggestion: any) =>
        suggestion.toLowerCase().includes(mentionMatch[1].toLowerCase())
      );
      this.mentionStartIndex = mentionMatch.index + 1;
    } else {
      this.showSuggestions = false;
    }
  }
  onKeydown(event: { key: string; preventDefault: () => void }) {
    if (this.showSuggestions && event.key === "Enter") {
      event.preventDefault();
      this.addMention(this.filteredSuggestions[0]);
    }
  }
  addMention(suggestion: any) {
    const cursorPosition = this.suggestionArea.selectionStart;
    const textBeforeCursor = this.message.slice(0, this.mentionStartIndex - 1);
    const textAfterCursor = this.message.slice(cursorPosition);
    const messageCode = `{{${suggestion}}}`;
    this.message = `${textBeforeCursor} ${messageCode} ${textAfterCursor}`;
    // this.message = this.message + suggestion;

    this.showSuggestions = false;
  }
}
</script>

<style>
.chart__container {
  height: 100%;
}

.chart__container>.chart__self {
  height: 100% !important;

  font-weight: bold;
  text-align: center;
}

.chart__container>.chart__self .node {
  cursor: pointer;
  border-radius: 2rem;
  overflow: hidden;
}
</style>
