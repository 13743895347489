/* eslint-disabled */

export default class CustomerSelfcareExtend {


    constructor (object?: any) {
    }
  
    postConstruct () {
    }
  }
  