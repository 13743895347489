<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div
          class="container-fluid d-flex flex-stack"
          id="kt_toolbar_container"
        >
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link
                    :to="{ name: 'dashboard' }"
                    class="text-muted text-hover-primary"
                  >
                    <i class="fad fa-home"></i>
                    {{ trans("Creation d'une facture") }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>

          <div class="btn btn-sm btn-primary" @click="preview">Apercu</div>
          <b-modal size="xl" id="my-modal">
            <iframe width="100%" height="600px" :src="previewData" frameborder="0"> </iframe>
          </b-modal>
        </div>
      </div>
    </template>

    <template #content>
      <div class="card">
        <div class="card-body">
          <h1>Creation de la facture</h1>

          <div class="my-5">
            <label class="my-2" for="">{{ trans("Description") }}</label>
            <div>

            </div>
          </div>
        </div>
      </div>
    </template>
  </Base>
</template>

<script lang="ts">
import Invoice from "@/entity/Invoice";
import Popup from "@/entity/Popup";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: { Base },
})
export default class CreateInvoice extends Vue {
}
</script>

<style scoped></style>
