<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Cotations') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-auto flex-grow-1">
              <label class="mb-2 fw-bolder" for="">{{ trans('Branche') }}</label>
              <b-form-select class="form-control  form-select" v-model="searched.branch">
                <b-form-select-option :value="null">{{ trans('Choisir une branche') }}
                </b-form-select-option>
                <template v-for="category in branchCategories">
                  <b-form-select-option :key="branch.uuid" :value="branch.uuid"
                                        v-for="(branch, key) in category.branches">
                    {{ category.label }} - {{ trans(branch.label) }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>

            <div class="col-auto flex-grow-1">
              <label class="mb-2 fw-bolder" for="">{{ trans('Status') }}</label>
              <b-form-select class="form-control  form-select" v-model="searched.status">
                <b-form-select-option :value="null">{{ trans('Choisir un status') }}
                </b-form-select-option>
                <template v-for="(status, index) in CONSTANTS.orderStatus">
                  <b-form-select-option :key="'status_' + index" :value="status">
                    {{ status }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>

            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Assureur
              </label>
              <div>
                <b-form-select class="form-control  form-select" v-model="searched.insurer">
                  <b-form-select-option :value="null">{{ trans('Choisir un assureur') }}
                  </b-form-select-option>
                  <b-form-select-option :key="insurer.uuid" :value="insurer.slug" v-for="insurer in insurers">
                    {{ insurer.label }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </div>


          <div class="row my-10">
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Uuid / Id
              </label>
              <div>
                <input class="form-control" placeholder="Identifiant" type="text" @keyup.enter="search"
                       v-model="searched.uuid">
              </div>
            </div>
            <div class="col-auto flex-grow-1" v-if="hasRole('ROLE_MANAGER')">
              <label class="fw-bolder mb-2" for="">
                Apporteur
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" placeholder="Apporteur" type="text" v-model="searched.partner"
                         @keyup.enter="search">
                </div>
              </div>
            </div>
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Créer entre :
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.createMin">
                </div>
                <div class="mx-3 fw-bolder">
                  et
                </div>
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.createMax">
                </div>
              </div>
            </div>
          </div>

          <!--          <div class="d-flex justify-content-center">-->
          <!--            <div @click="resetSearch" class="btn btn-danger me-5 d-flex align-items-center">-->
          <!--              <i class="fad fa-trash"></i>-->
          <!--              Réinitialiser-->
          <!--            </div>-->
          <!--            <button :disabled="searching" @click="search" class="btn btn-primary d-flex align-items-center">-->
          <!--              <i class="fad fa-search"></i>-->
          <!--              Rechercher-->
          <!--            </button>-->
          <!--          </div>-->


          <!--          <div class="fs-2 fw-bolder mb-2 cursor-pointer text-hover-primary" @click="advancedSearch = !advancedSearch">-->
          <!--            <i class="fad fa-caret-right me-2 fs-2" v-if="!advancedSearch"></i>-->
          <!--            <i class="fad fa-caret-down me-2 fs-2" v-else></i>-->
          <!--            Recherche avancée-->
          <!--          </div>-->
          <div>
            <div class="d-flex justify-content-center">
              <div @click="resetSearch" class="btn btn-danger me-5 d-flex align-items-center">
                <i class="fad fa-trash"></i>
                Réinitialiser
              </div>
              <button :disabled="searching" @click="search" class="btn btn-primary d-flex align-items-center">
                <i class="fad fa-search"></i>
                Rechercher
              </button>
            </div>
          </div>
        </div>
      </div>


      <div class="fs-2x fw-bolder mt-10 d-flex justify-content-between align-items-center">
        <div>
          {{ matchCount }}
          <template v-if="matchCount > 1">Correspondances</template>
          <template v-else>Correspondance</template>
        </div>

        <div v-if="selected.length > 0" class="d-flex align-items-center">
          {{ selected.length }}
          <template v-if="selected.length <= 1">selectionné</template>
          <template v-else>selectionnés</template>
          <div class="btn btn-danger btn-icon ms-5">
            <i class="fad fa-trash"></i>
          </div>
        </div>
      </div>
      <div class="card mt-10">
        <div class="card-body">


          <div v-if="orders.length > 0" class="d-flex justify-content-end">
            <button class="btn  btn-primary d-flex align-items-center mb-3">
              <span class="me-3">Exporter</span> <i class="fad fa-print"></i>

            </button>
          </div>


          <table class="table table-striped gy-3 gs-3">
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
              <th>
                <label class="form-check form-check-sm form-check-custom form-check-solid">
                  <input class="form-check-input" data-kt-check="true" data-kt-check-target=".widget-9-check"
                         type="checkbox" :checked="selected.length > 0" value="1"/>
                </label>
              </th>
              <th>{{ trans('Infos') }}</th>
              <th>Adresse</th>
              <th>Status</th>
              <th>Création</th>
              <th>Assureur</th>
              <th class="text-center">{{ trans('Status/Gestionnaire') }}</th>
              <th>{{ trans('Apporteur') }}</th>
              <th>{{ trans('Prime TTC') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="searching">
              <td colspan="10">
                <Loading></Loading>
              </td>
            </tr>
            <tr v-else-if="orders.length === 0">
              <td colspan="10">
                <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center p-20">
                  <i class="fad fa-empty-set fs-5x"></i>
                  <span class="fs-2 text-gray-400">{{ trans('Aucun resultat') }}</span>
                </div>
              </td>
            </tr>
            <template v-else v-for="order in orders">
              <tr>
                <td style="vertical-align: middle">
                  <RowSelector v-model="selected" :data="order.uuid"/>
                </td>
                <td class="position-relative">
                  <div>
                    <div class="d-flex align-items-center fw-bolder">
                      {{ order.alias }} ❖
                    </div>

                    <div class="me-2">
                      {{ order.contact }}
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div class="d-flex align-items-center fw-bolder">
                      {{ order.address }} ❖
                    </div>

                    <div class="me-2">
                      {{ order.town }}
                    </div>
                  </div>
                </td>
                <td>
                                    <span :class="{
                  'badge-warning': order.status === CONSTANTS.orderStatusCode.CREATED,
                  'badge-primary': order.status === CONSTANTS.orderStatusCode.WAITING,
                  'badge-danger': order.status === CONSTANTS.orderStatusCode.EXPIRED || order.status === CONSTANTS.orderStatusCode.FAILED,
                  'badge-success': order.status === CONSTANTS.orderStatusCode.PAYED,
                }" class="badge">
                                      {{ order.status }}
                                    </span>
                </td>
                <td>
                  {{ readableDate(order.createdAt, 'dMY H:i') }}
                </td>
                <td>
                  {{ order.insurer }}
                </td>
                <td>
                  <template v-if="order.responseGroup.manager">
                    {{ order.responseGroup.manager }}
                  </template>
                </td>
                <td>
                  <template v-if="order.partner">
                    <div class="fw-bolder">
                      {{ order.partner }}
                    </div>
                  </template>
                </td>
                <td>
                  {{ currency(order.premium) }}
                </td>
                <td class="text-end">
                  <router-link :to="{ name: 'order-show', params: { uuid: order.uuid } }"
                               class="btn btn-xs btn-icon btn-primary">
                    <i class="fad fa-eye"></i>
                  </router-link>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>

      <div class="my-20">
        <Pagination :count="total" :itemLength="orders.length" :matchCount="matchCount" :perPage="searched.perPage"
                    :showEmpty="true" @input="search" v-model="searched.page"></Pagination>
      </div>
    </template>
  </Base>
</template>


<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Pagination from "@/components/Pagination.vue";
import RowSelector from "@/components/RowSelector.vue";
import Loading from "@/components/Loading.vue";
import UserView from "@/components/UserView.vue";
import {api} from "@/services/Api";
import Contract from "@/entity/Contract";
import Partner from "@/entity/Partner";
import Insurer from "@/entity/Insurer";
import BranchCategory from "@/entity/BranchCategory";
import User from "@/entity/User";
import Order from "@/entity/Order";
import Base from "@/layouts/Base.vue";

@Component({
  components: {UserView, Loading, RowSelector, Pagination, Base}
})
export default class OrderList extends Vue {

  total = 0
  selected: any[] = []
  matchCount = 0
  uploadRoot = api.uploadRoot
  branchCategories: BranchCategory[] = []
  itemTypes: any[] = []
  searching = false
  searched: any = {
    branch: null,
    customer: null,
    insurer: null,
    partner: null,
    manager: null,
    uuid: null,
    police: null,
    status: null,
    createMin: null,
    createMax: null,
    page: 1,
    perPage: 50
  }

  orders: Order[] = []
  partners: Partner[] = []
  managers: User[] = []
  insurers: Insurer[] = []
  isLoading = false

  async mounted() {
    this.$store.commit('loading')
    await this.loadData()
    this.$store.commit('stopLoading')
  }

  async loadData() {
    const res = await api.get(api.core, 'contract/list/requirements')
    if (res && res.data) {
      this.partners = []
      res.data.partners.forEach((p: Partner) => {
        this.partners.push(new Partner(p))
      })
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        this.insurers.push(new Insurer(i))
      })
      this.branchCategories = []
      res.data.categories.forEach((i: Insurer) => {
        this.branchCategories.push(new BranchCategory(i))
      })
      this.itemTypes = res.data.itemTypes
    }
  }

  resetSearch() {
    const keys = Object.keys(this.searched)
    keys.forEach((k: any) => {
      this.searched[k] = null
    })
    this.searched.page = 1
    this.searched.perPage = 50
  }

  async search() {
    if (this.searching) {
      return
    }
    this.searching = true
    const res = await api.post(api.form, 'order/search', {searched: this.searched})
    if (res && res.data && res.data.orders) {
      this.orders = [];
      res.data.orders.forEach((o: any) => {
        const occ = new Order(o)
        this.orders.push(occ);
      })
      this.total = res.data.total
      this.matchCount = res.data.matchCount
    }
    this.searching = false
  }
}
</script>


<style scoped>

</style>
