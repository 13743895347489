import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import device from "vue-device-detector-js";

// import firebaseMessaging from './services/Firebase'

Vue.use(BootstrapVue);
Vue.use(device);
// import './assets/css/style.bundle.css'
import {auth} from "@/services/Auth";
import {trans} from "@/services/Translator";
import {helper} from "@/services/Helper";
// import FormProcessor from "@/views/Income/Comparator/FormProcessor.vue";
Vue.config.productionTip = false;

import JsonViewer from "vue-json-viewer";
import "vue-json-viewer/style.css";

import VueApexCharts from "vue-apexcharts";
import ResponseGroupManager from "@/views/Comparator/Components/ResponseGroupManager.vue";
import Partner from "@/entity/Partner";

Vue.use(JsonViewer);
// import FormProcessor from "@/views/Income/Comparator/FormProcessor.vue";
// import {TitleMixin} from './Mixin/TitleMixin'
// @ts-ignore
import VueTree from "@ssthouse/vue-tree-chart";
import "sweetalert2/dist/sweetalert2.min.css";

import JsonExcel from "vue-json-excel";

import VueCryptojs from "vue-cryptojs";

Vue.use(VueCryptojs);

import MultipleSurvey from "@/views/Income/Comparator/FormComponents/MultipleSurvey.vue";

Vue.component("MultipleSurvey", MultipleSurvey);
Vue.component("ResponseGroupManager", ResponseGroupManager);
Vue.component("downloadExcel", JsonExcel);
Vue.component("vue-tree", VueTree);
Vue.component(
    "FormProcessor",
    require("./views/Income/Comparator/FormProcessor.vue").default
);
Vue.component(
    "ItemManager",
    require("./views/Income/Avenant/ItemManager").default
);
Vue.component(
    "ComparisonOfferDetailLine",
    require("./views/Comparator/Components/ComparisonOfferDetailLine").default
);

Vue.component(
    "PropertyManager",
    require("./views/Income/Avenant/PropertyManager").default
);
Vue.component(
    "AvenantDetail",
    require("./views/Contract/AvenantDetail").default
);
Vue.component(
    "IncorporationDetail",
    require("./views/Contract/IncorporationDetail").default
);
Vue.component(
    "EngineLineEditor",
    require("./views/Engine/EngineLineEditor").default
);
Vue.component(
    "ItemTypeInspector",
    require("./views/Engine/ItemTypeInspector").default
);
Vue.component(
    "ItemTypeClickInspector",
    require("./views/Engine/ItemTypeClickInspector").default
);
Vue.component(
    "ItemLine",
    require("./views/Configuration/Branch/Mapper/ItemLine").default
);
Vue.component(
    "AddRemoveItemLine",
    require("./views/Income/Avenant/AvenantManager/AddRemoveItemLine.vue").default
);
Vue.component(
    "AddRemovePropertyLine",
    require("./views/Income/Avenant/AvenantManager/AddRemovePropertyLine.vue")
        .default
);
// @ts-ignore
import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

import RoleLine from "@/views/Role/RoleLine.vue";

Vue.component("RoleLine", RoleLine);

Vue.component("ItemTree", ItemTree);

// Vue.mixin(TitleMixin)
import Draggable from "vuedraggable";
import Popup from "@/entity/Popup";
import CONSTANTS, {WORKFLOWS} from "@/Utils/constant";

Vue.component("draggable", Draggable);
// import draggable from 'vuedraggable';
import {api} from "@/services/Api";
import User from "./entity/User";
import ItemTree from "@/components/ItemTree.vue";
import menus, {getMenu} from "@/router/Menu";

Vue.directive("uppercase", {
    update: function (el: any) {
        el.value = el.value.toUpperCase();
    },
});

Vue.use(VueApexCharts);

// Vue.use(ClassicEditor)

Vue.component("apexchart", VueApexCharts);
router.beforeEach((to, from, next) => {
    const route = getMenu(to.name);

    if (auth.hasRole("ROLE_ADMIN")) {
        next();
        return;
    }
    if (!route) {
        if (auth.isAdmin()) {
            new Popup(
                "Route non configurée",
                "L'accès au menu " + to.name + " doit être configuré",
                "danger",
                "fad user-slash"
            );
        }
        // auth.logout()
        // return
        next();
        return;
    }
    if (!route.roles) {
        if (auth.isAdmin()) {
            new Popup(
                "Route non configurée",
                "L'accès au menu " + to.name + " doit être configuré",
                "danger",
                "fad user-slash"
            );
        }
        next({path: from.fullPath, query: from.query});
        return;
    }

    let roles: any[] = [];
    for (let role of route.roles) {
        roles = roles.concat(role);
    }

    let roleCode = [];
    for (let role of roles) {
        roleCode.push(role.code);
    }
    if (roleCode.includes("ROLE_PUBLIC")) {
        next();
        return;
    }

    if (!auth.checkRoleTabs(route.roles)) {
        let text =
            "Vous devez disposez de l'un des accès suivant pour accéder à la page " +
            to.name +
            " :";
        route.roles.forEach((r: any) => {
            r.forEach((c: any) => {
                text +=
                    '<span class="badge badge-success badge-sm">' + c.code + "</span>";
            });
        });

        new Popup("Accès non autorisé", text, "danger", "fad user-slash");
        // auth.logout()
        if (!helper.empty(from.fullPath) && from.fullPath !== to.fullPath) {
            next({path: from.fullPath, query: from.query});
        } else {

            next({path: "login"});
        }

        return;
    }

    // console.log('allowed')
    next();
});

// Vue.prototype.$messaging = firebaseMessaging

Vue.prototype.trans = (code: string) => {
    return trans.translate(code);
};

Vue.prototype.hasRole = (role: string) => {
    return auth.hasRole(role);
};
Vue.prototype.hasRoles = (roles: []) => {
    return auth.hasRoles(roles);
};
// Vue.prototype.getManagerIdentifier = async () => {
//     await api.getManagerIdentifier
// }
Vue.prototype.loading = (
    title = "",
    message: string,
    type = "secondary",
    icon = "fad fa-exclamation",
    keepAlive = false,
    link?: string,
    buttons?: []
) => {
    return new Popup(title, message, type, icon, keepAlive, link, buttons);
};

const getCircularReplacer = () => {
    const seen = new WeakSet();
    const disallowed = [
        "createdAt",
        "updatedAt",
        "createBy",
        "updateBy",
        "removeBy",
        "createdFromIp",
        "updatedFromIp",
    ];
    return (key: any, value: any) => {
        if (disallowed.includes(key)) {
            return;
        }
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};
Vue.prototype.stringify = (obj: any) => {
    return JSON.stringify(obj, getCircularReplacer());
};

Vue.prototype.readableDate = helper.readable;
Vue.prototype.readable = helper.readable;
Vue.prototype.dateToHtml = helper.dateToHtml;
Vue.prototype.reversedDateToHtml = helper.reversedDateToHtml;
Vue.prototype.datify = helper.readable;
Vue.prototype.currency = helper.currencyFormat;
Vue.prototype.miniCurrency = helper.minifyCurrency;
Vue.prototype.roundMoney = helper.roundMoney;
Vue.prototype.numberFormat = helper.numberFormat;
Vue.prototype.phoneFormat = helper.phoneFormat;
Vue.prototype.phonify = helper.phoneFormat;

Vue.prototype.abs = Math.abs;
Vue.prototype.timelapse = helper.getTimeLapse;
Vue.prototype.smartlapse = (date: Date) => {
    return helper.getSmartLapse(date);
};
Vue.prototype.CONSTANTS = CONSTANTS;
Vue.prototype.WORKFLOWS = WORKFLOWS;
Vue.prototype.genId = helper.generateId;
Vue.prototype.helper = helper;
Vue.prototype.hasRole = (role: string) => {
    return auth.hasRole(role);
};
Vue.prototype.userHasRole = (user: User, role: string) =>
    auth.userHasRole(user, role);
Vue.prototype.partnerHasRole = (partner: Partner, role: string) =>
    auth.partnerHasRole(partner, role);
Vue.prototype.checkRoleTabs = (roles: any[]) => auth.checkRoleTabs(roles);
Vue.prototype.getManagerIdentifier = (uuid: string) => {
    return api.getManagerIdentifier(uuid);
};

Vue.filter("round", Math.round);
Vue.prototype.empty = helper.empty;
Vue.prototype.isMobile = () => {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check;
};

new Vue({
    router,
    store,
    render: (h) => h(App as any),
}).$mount("#app");
