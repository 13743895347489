<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div class="d-flex align-items-center">
              <div class="btn btn-icon btn-light-danger btn-xs me-2" @click="beforeMount()">
                <i class="fad fa-trash"></i>
              </div>


              <date-range-picker ref="picker" :opens="'left'" :locale-data="localData" :singleDatePicker="false"
                                 :autoApply="false" :ranges="ranges" @update="load" :timePicker="false"
                                 :showWeekNumbers="true"
                                 :showDropdowns="true" v-model="interval">
                <template v-slot:input="picker" style="min-width: 350px;">
                  <template>
                    {{ readableDate(picker.startDate, 'dmY') }} ❖ {{ readableDate(picker.endDate, 'dmY') }}
                  </template>
                </template>
              </date-range-picker>

              <!--              <div class="mx-2">-->
              <!--                du-->
              <!--              </div>-->
              <!--              <div class="mx-2">-->
              <!--                <b-form-datepicker size="sm" type="date" class="form-control" v-model="interval.start">-->
              <!--                </b-form-datepicker>-->
              <!--              </div>-->
              <!--              <div class="mx-2">-->
              <!--                au-->
              <!--              </div>-->
              <!--              <div class="mx-2">-->
              <!--                <b-form-datepicker size="sm" type="date" class="form-control" v-model="interval.end">-->
              <!--                </b-form-datepicker>-->
              <!--              </div>-->
              <!--              <div>-->
              <!--                <button class="btn btn-icon btn-sm btn-primary" @click="load">-->
              <!--                  <i class="fad fa-search"></i>-->
              <!--                </button>-->
              <!--              </div>-->
            </div>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <!--        <ManagerCotations></ManagerCotations>-->
      <!--      <CotationSearch></CotationSearch>-->
      <ManagerStatistic :key="uKey" :interval="interval"></ManagerStatistic>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import ManagerStatistics from "@/views/Dashboard/ManagerComponent/ManagerStatistics.vue";
import InputNumber from "@/components/InputNumber.vue";
import ManagerCotations from "@/views/Dashboard/ManagerComponent/ManagerCotations.vue";
import CotationSearch from "@/views/Income/CotationSearch.vue";
import PartnerStatistic from "@/views/Partner/PartnerStatistic.vue";
import CONSTANTS from "@/Utils/constant";
import {api} from "@/services/Api";
import Avenant from "@/entity/Avenant";
import {helper} from "@/services/Helper";
import ManagerStatistic from "@/views/ManagerStatistic.vue";
import DateRangePicker from "vue2-daterange-picker";
import store from "@/store";

@Component({
  components: {
    ManagerStatistic,
    PartnerStatistic, ManagerCotations, InputNumber, ManagerStatistics, Base, CotationSearch, DateRangePicker
  }
})
export default class ManagerHome extends Vue {
  categories = []
  search = {
    branch: null,
    after: null,
    before: null,
    contains: null,
    minimalState: 0,
    hasClientInfos: false,
    page: 1,
    statut: [] as any
  } as any
  uKey = helper.generateId()

  interval = {
    startDate: CONSTANTS.dateConstants.first_day_current_month(),
    endDate: CONSTANTS.dateConstants.last_day_current_month()
  };
  // interval = {
  //   start: CONSTANTS.dateConstants.first_day_current_month(),
  //   end: CONSTANTS.dateConstants.last_day_current_month()
  // } as any

  localData = {
    direction: 'ltr',
    format: 'mm/dd/yyyy',
    separator: ' - ',
    applyLabel: 'Valider',
    cancelLabel: 'Annuler',
    weekLabel: ' ',
    customRangeLabel: 'Custom Range',
    daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    firstDay: 0
  }
  ranges: any = false;

  resetSearch() {
    this.interval = {
      startDate: CONSTANTS.dateConstants.first_day_current_month(),
      endDate: CONSTANTS.dateConstants.last_day_current_month()
    }
    let def: any = localStorage.getItem('partnerDashboardFilter')
    if (def) {
      def = JSON.parse(def)
      if (def.startDate && def.endDate) {
        this.interval = def as any
      }
    }
    this.ranges = store.getters.loadRanges()
  }

  beforeMount() {
    this.resetSearch()
  }

  mounted() {

  }

  data = {
    emissions: [] as any,
    emissionTTCGlobale: 0,
    emissionNetteGlobale: 0
  }


  async load() {
    const data = {
      start: new Date(this.interval.startDate),
      end: new Date(this.interval.endDate)
    }
console.log(data)
    localStorage.setItem('partnerDashboardFilter', JSON.stringify(this.interval))
    const res = await api.post(api.core, 'data/dashboard', {
      search: data
    });
    if (res && res.data) {
      res.data.emissions.forEach((e: any) => {
        const avenant = new Avenant(e)
        this.data.emissions.push(avenant)
      })
      this.data.emissionTTCGlobale = 0
      this.data.emissionNetteGlobale = 0
      this.data.emissions.forEach((avenant: Avenant) => {
        this.data.emissionTTCGlobale += avenant.primeTTC
        this.data.emissionNetteGlobale += avenant.primeNette
      })

    }
    this.uKey = helper.generateId()
    // localStorage.setItem('incomeStateInterval', JSON.stringify(this.interval))
    // this.loadEmissions()
  }
}
</script>
<style scoped>
</style>
