<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <router-link :to="{ name: 'form-list', params: { slug: $route.params.slug } }"
                         class="btn-sm btn-primary btn">
              <i class="fad fa-ballot-check text-white"></i>
              {{ trans('Formulaires') }}
            </router-link>

            <button @click="generate" class="btn btn-warning btn-sm ms-3">
              <i class="fad fa-cogs"></i>
              {{ trans('Generate') }}
            </button>

            <button @click="cleanOrphanProperties" class="btn btn-danger btn-sm ms-3" v-if="hasRole('SUPER_ADMIN')">
              <i class="fad fa-trash"></i>
              {{ trans('Clean Oprhan Properties') }}
            </button>

          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="">
        <div class="title">
          <template v-if="branch">{{ trans(branch.TitleMixin) }}</template>
          {{ trans('Configuration') }}
        </div>
        <div class="mw-500px m-auto" v-if="loading">
          <Loading></Loading>
        </div>
        <!--                                <div class="d-flex flex-wrap">-->

        <draggable :animation="200" :list="itemTypes" @end="updateItemRanks" class="d-flex flex-wrap"
                   filter=".action-button" ghost-class="bg-light-primary" group="required" tag="div">
          <template v-for="item in itemTypes">
            <div class="card m-3 card-xxl-stretch mb-xl-3 flex-grow-1 mw-400px" v-if="item.required">
              <div class="card-header border-0">
                <div>
                  <h3 class="card-title fw-bolder text-dark">
                    <i :class="item.icon + ' me-2'"></i>
                    {{ trans(item.name) }}
                  </h3>
                  {{ item.code }} ▪ {{ item.className }}
                </div>
                <div class="card-toolbar">


                  <b-dropdown no-caret no-flip ref="filter" right size="sm" style="padding:0" tag="div"
                              toggle-class="topbar-item text-decoration-none" variant="link">
                    <template v-slot:button-content>
                      <div class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <i class="fad fa-th-large "></i>
                      </div>
                    </template>
                    <b-dropdown-text class="p-0 b-dropdown-text" tag="div">
                      <div class="p-0">
                        <div class="d-flex row dropmenu bg-white">
                          <a @click="setItem(item)" class="dropdown-item cursor-pointer"
                             v-b-modal.modal-1 v-if="hasRole('ROLE_ADMIN')">
                            {{ trans('Edit') }}
                          </a>
                          <a @click="addProperty(item)" class="dropdown-item cursor-pointer"
                             v-b-modal.modal-2>
                            {{ trans('Add_property') }}
                          </a>
                          <a @click="deleteItem(item.uuid)" class="dropdown-item cursor-pointer">
                            {{ trans('Delete') }}
                          </a>
                        </div>
                      </div>
                    </b-dropdown-text>
                  </b-dropdown>


                </div>
              </div>
              <div class="card-body pt-2">
                <draggable :animation="200" :group="item.uuid" :list="item.properties"
                           @end="updatePropertyRanks(item)" filter=".action-button" ghost-class="bg-light-primary"
                           tag="div">
                  <div class="d-flex align-items-center" v-for="property in item.properties">
                    <div class="flex-grow-1">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                                                <span class="text-gray-800 text-hover-primary fw-bolder fs-6" href="#">
                                                    {{ trans(property.name) }}
                                                  <span v-if="property.link" class="text-muted ms-2">
                                                    <i class="fad fa-link"></i>
                                                    {{ property.link }}
                                                  </span>
                                                </span>
                          <span class="text-muted fw-bold d-block"
                                v-if="property.type && property.type.code === 'OBJECT'">
                                                    {{ trans(property.type.label) }} ◾ {{ trans(property.value) }}
                                                </span>
                          <span class="text-muted fw-bold d-block" v-else>
                                                    {{ trans(property.type.label) }} ◾
                                                    <template v-if="property.type && property.type.code === 'BOOLEAN'">
                                                        {{
                                                        Boolean(Number(property.value)) ? trans('true') :
                                                            trans('false')
                                                      }}
                                                    </template>
                                                    <template v-else>
                                                        {{ trans(property.value) }}
                                                    </template>
                                                </span>
                        </div>
                        <div class="ms-5">
                          <i :class="property.isAlias ? 'text-primary' : 'text-gray-400'"
                             :title="'intégré dans l\'alias'"
                             @click.prevent.stop="toggleAlias(property)"
                             class="fad fa-eye cursor-pointer" v-b-tooltip.hover
                             v-if="property.editable || hasRole('ROLE_ADMIN')"></i>
                          <i @click="editProperty(property, item)"
                             class="fad fa-edit cursor-pointer ms-2" v-b-modal.modal-2
                             v-if="property.editable || hasRole('ROLE_ADMIN')"></i>
                          <i :class="property.identifier ? 'text-primary' : 'text-gray-400'"
                             :title="trans('is_an_identifier')"
                             @click.prevent.stop="toggleIdentifier(property)"
                             class="fad fa-crosshairs cursor-pointer ms-2" v-b-tooltip.hover></i>
                          <i @click="removeProperty(property)"
                             class="fad fa-trash text-danger ms-2 cursor-pointer"
                             v-if="property.editable || hasRole('ROLE_ADMIN')"></i>
                          <!--                                            <i :class=" property.required ? 'text-danger'  : 'text-gray-400'"-->
                          <!--                                               :title="trans('is_required')" @click.prevent.stop="toggleRequired(property)"-->
                          <!--                                               class="fad fa-exclamation-triangle ms-2  cursor-pointer"-->
                          <!--                                               v-b-tooltip.hover></i>-->
                        </div>
                      </div>
                    </div>
                    <!--                                <span class="badge badge-light-success fs-8 fw-bolder">New</span>-->
                  </div>
                </draggable>
              </div>
              <div class="card-footer justify-content-center py-2 d-flex" v-if="item.branch">

                <div class="signature">
                  <div class="user text-primary cursor-pointer" v-if="item.updateBy">
                    {{ item.updateBy }}
                  </div>
                  <div class="date">
                    {{ readableDate(item.updatedAt) }}
                  </div>

                </div>
                <!--                            <div class="btn btn-primary btn-sm">-->
                <!--                                <i class="fad fa-plus"></i>-->
                <!--                                {{trans('Add_property')}}-->
                <!--                            </div>-->
              </div>
            </div>
          </template>
        </draggable>


        <div class="title">
          <template v-if="branch">{{ trans(branch.TitleMixin) }}</template>
          {{ trans('Structure de Données') }}
        </div>

        <div class="d-flex justify-content-center" v-if="!root && itemTypes.length > 0">

          <b-button @click="createRoot" class="btn-sm" variant="primary">
            <i class="fad fa-plus"></i>
            {{ trans('Creer la racine') }}
          </b-button>
        </div>


        <draggable :animation="200" :list="itemTypes" @end="updateItemRanks" class="d-flex flex-wrap"
                   filter=".action-button" ghost-class="bg-light-primary" group="structure" tag="div">
          <template v-for="item in itemTypes">
            <div class="card m-3 card-xxl-stretch mb-xl-3 flex-grow-1 min-w-300px mw-400px"
                 v-if="!item.required">
              <div class="card-header border-0">
                <div>
                  <h3 class="card-title fw-bolder text-dark">
                    <i :class="item.icon + ' me-2'"></i>
                    {{ trans(item.name) }}
                  </h3>
                  {{ item.code }} ▪ {{ item.className }}
                </div>
                <div class="card-toolbar">
                  <b-dropdown no-caret no-flip ref="filter" right size="sm" style="padding:0" tag="div"
                              toggle-class="topbar-item text-decoration-none" variant="link">
                    <template v-slot:button-content>
                      <div class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <i class="fad fa-th-large "></i>
                      </div>
                    </template>
                    <b-dropdown-text class="p-0 b-dropdown-text" tag="div">
                      <div class="p-0">
                        <div class="d-flex row dropmenu bg-white">
                          <a @click="setItem(item)" class="dropdown-item cursor-pointer"
                             v-b-modal.modal-1 v-if="!item.isRoot || hasRole('ROLE_ADMIN')">
                            {{ trans('Edit') }}
                          </a>
                          <a @click="addProperty(item)" class="dropdown-item cursor-pointer"
                             v-b-modal.modal-2>
                            {{ trans('Add_property') }}
                          </a>
                          <a @click="deleteItem(item.uuid)" class="dropdown-item cursor-pointer"
                             v-if="!item.isRoot || hasRole('ROLE_ADMIN')">
                            {{ trans('Delete') }}
                          </a>
                        </div>
                      </div>
                    </b-dropdown-text>
                  </b-dropdown>


                </div>
              </div>
              <div class="card-body pt-2 d-flex flex-column">
                <draggable :animation="200" :group="item.uuid" :list="item.properties"
                           @end="updatePropertyRanks(item)" filter=".action-button" ghost-class="bg-light-primary"
                           tag="div">
                  <div class="d-flex align-items-center" v-for="property in item.properties">
                    <div class="flex-grow-1">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                                                <span class="text-gray-800 text-hover-primary fw-bolder fs-6" href="#">
                                                   {{ property.ranking }} - {{ trans(property.name) }}
                                                </span>
                          <span class="text-muted fw-bold d-block">
                                                    {{ trans(property.type.label) }} ◾
                                                    <template v-if="property.link">
                                                    <i class="fad fa-link"></i>
                                                    {{ property.link }} ◾
                                                    </template>
                                                    <template v-if="property.type && property.type.code === 'BOOLEAN'">
                                                        {{
                                                        Boolean(Number(property.value)) ? trans('true') :
                                                            trans('false')
                                                      }}
                                                    </template>
                                                    <template
                                                        v-if="property.type && property.type.code.match(/OBJECT/)">
                                                        {{ itemNameFromUuid(property.value) }}
                                                    </template>
                                                    <template v-else>
                                                        {{ trans(property.value) }}
                                                    </template>
                                                </span>
                        </div>
                        <div class="ms-5">
                          <i :class="property.showOnDevis ? 'text-primary' : 'text-gray-400'"
                             :title="'Afficher dans le devis'"
                             @click.prevent.stop="toggleDevis(property)"
                             class="fad fa-ballot-check cursor-pointer mx-1"
                             v-b-tooltip.hover></i>
                          <i :class="property.isAlias ? 'text-primary' : 'text-gray-400'"
                             :title="'intégré dans l\'alias'"
                             @click.prevent.stop="toggleAlias(property)"
                             class="fad fa-eye cursor-pointer mx-1" v-b-tooltip.hover></i>
                          <i @click="editProperty(property, item)"
                             class="fad fa-edit cursor-pointer mx-1" v-b-modal.modal-2></i>
                          <i :class="property.identifier ? 'text-primary' : 'text-gray-400'"
                             :title="trans('is_an_identifier')"
                             @click.prevent.stop="toggleIdentifier(property)"
                             class="fad fa-crosshairs cursor-pointer mx-1" v-b-tooltip.hover></i>
                          <i @click="removeProperty(property)"
                             class="fad fa-trash text-danger ms-2 cursor-pointer"></i>
                          <!--                                            <i :class=" property.required ? 'text-danger'  : 'text-gray-400'"-->
                          <!--                                               :title="trans('is_required')" @click.prevent.stop="toggleRequired(property)"-->
                          <!--                                               class="fad fa-exclamation-triangle ms-2  cursor-pointer"-->
                          <!--                                               v-b-tooltip.hover></i>-->
                        </div>
                      </div>
                    </div>
                    <!--                                <span class="badge badge-light-success fs-8 fw-bolder">New</span>-->
                  </div>
                </draggable>
              </div>
              <div class="card-footer justify-content-center py-2 d-flex" v-if="item.branch">

                <div class="signature">
                  <div class="user text-primary cursor-pointer" v-if="item.updateBy">
                    {{ item.updateBy }}
                  </div>
                  <div class="date">
                    {{ readableDate(item.updatedAt) }}
                  </div>

                </div>
                <!--                            <div class="btn btn-primary btn-sm">-->
                <!--                                <i class="fad fa-plus"></i>-->
                <!--                                {{trans('Add_property')}}-->
                <!--                            </div>-->
              </div>
            </div>
          </template>
        </draggable>


        <div class="title">
          <template v-if="branch">{{ trans(branch.TitleMixin) }}</template>
          {{ trans('Schéma de Données') }}
        </div>

        <div v-if="root">

          <div class="d-flex justify-content-end">
            <div @click="$refs.tree.zoomOut()" class="btn btn-sm btn-icon btn-primary"><i
                class="fad fa-minus"></i></div>
            <div @click="$refs.tree.zoomIn()" class="btn btn-sm btn-icon btn-primary mx-5"><i
                class="fad fa-plus"></i></div>
            <div @click="$refs.tree.restoreScale()" class="btn btn-sm btn-icon btn-primary"><i
                class="fad fa-crosshairs"></i></div>
          </div>
          <vue-tree :collapse-enabled="true" :config="treeConfig" :dataset="tree" class="bg-white rounded my-5"
                    ref="tree" style="height: 650px">
            <template v-slot:node="{ node, collapsed }">
              <div :style="{ border: collapsed ? '2px solid grey' : '' }"
                   class="bg-primary shadow-sm rounded py-2 px-5 d-flex align-items-center">
                <i :class="node.icon + ' fs-2 text-white'" v-if="node.icon"></i>
                <i class="fad fa-cogs fs-2" v-else></i>
                <span class="ms-2 text-white" style="padding: 4px 0; font-weight: bold;">
                                {{ node.name }}
                            </span>
              </div>
            </template>
          </vue-tree>
          <!--                    {{root}}-->
        </div>
      </div>
      <b-modal :title="trans('Editer un Objet')" centered id="modal-1" ref="item-modal" size="lg">
        <form>
          <div class="row" v-if="item">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Icon') }}</label>
              <div>
                <IconSelector :selected="item.icon" v-on:select="item.icon = $event"></IconSelector>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Name') }}</label>
              <b-form-input :id="'name'" :state="item.name.trim().length > 0" v-model="item.name">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'type'" class="required form-label">{{ trans('Type') }}</label>
              <b-form-select :state="!empty(item.className)" class="form-control  form-select"
                             v-model="item.className">
                <b-form-select-option value="">{{ trans('Select_a_type') }}
                </b-form-select-option>
                <b-form-select-option :key="'object-' + key" :value="object.class"
                                      v-for="object, key in objects">
                  {{ trans(object.name) }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'type_multiple'" class="required form-label">{{ trans('Défini le pack') }}</label>
              <div>
                <label class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" v-model="item.isPackApplier"/>
                </label>
              </div>
            </div>

            <div class="col-auto my-2 flex-grow-1">
              <label :for="'type'" class="required form-label">{{ trans('Methode de Calcul Prime Nette') }}</label>
              <div class="d-flex">
                <b-input-group>
                  <b-form-select :state="!helper.empty(item.method)" class="form-control  form-select"
                                 v-model="item.method">
                    <b-form-select-option :value="null">{{ trans('Select_a_type') }}
                    </b-form-select-option>
                    <b-form-select-option :key="'method_' + method.id" :value="method"
                                          v-for="(method, key) in methods">
                      {{ trans(method.label) }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-input-group-append>
                    <div class="btn btn-sm btn-icon btn-primary h-100"
                         style="border-top-left-radius: 0;border-bottom-left-radius: 0"
                         v-b-modal="'methodModal'">
                      <i class="fad fa-plus"></i>
                    </div>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>

            <div class="col-auto my-2 flex-grow-1">
              <label :for="'type'" class="required form-label">{{ trans('Workflows') }}</label>
              <div class="d-flex">
                <b-input-group>
                  <b-form-select :state="!helper.empty(item.asaciWorkflow)" class="form-control  form-select"
                                 v-model="item.asaciWorkflow">
                    <b-form-select-option :value="null">{{ trans('Selectionnez un workflow') }}
                    </b-form-select-option>
                    <b-form-select-option :key="'workflow_' + workflow.code" :value="workflow.code"
                                          v-for="workflow in workflows">
                      {{ trans(workflow.name) }}
                    </b-form-select-option>
                  </b-form-select>
                </b-input-group>
              </div>
            </div>

            <div class="title w-100 d-flex justify-content-between">
              <div>
                Imprimables
              </div>
              <div>
                <div @click="addPrintable" class="btn btn-sm btn-icon btn-primary">
                  <i class="fad fa-plus"></i>
                </div>
              </div>
            </div>
            <div class=" border-dashed p-5 mt-2 w-100">
              <table class="table table-striped gy-2 gs-2 w-100">
                <thead>
                <tr>
                  <th>
                    Privée
                  </th>
                  <th>
                    Noms
                  </th>
                  <th>
                    Template
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="printable, index in item.printables">
                  <td>
                    <div class="form-check form-switch form-check-custom form-check-solid ">
                      <input class="form-check-input h-30px w-40px" type="checkbox" v-model="printable.private"
                             value=""/>
                    </div>
                  </td>
                  <td>
                    <b-form-input v-model="printable.label"></b-form-input>
                  </td>
                  <td>
                    <b-form-input v-model="printable.template"></b-form-input>
                  </td>
                  <td>
                    <div @click="removePrintable(printable)" class="btn btn-xs btn-icon btn-danger">
                      <i class="fad fa-trash"></i>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editItem" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
      <b-modal :title="trans('Editer une propriété')" centered id="modal-2" ref="property-modal" size="xl">
        <form v-if="property">
          <h2 class="text-gray-400">
            Code : {{ property.code }}
          </h2>
          <div class="row">
            <div class="fs-3 fw-bolder" v-if="property.item">{{ trans(property.item.name) }}</div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Nom') }}</label>
              <b-form-input :id="'name'" :state="!helper.empty(property.name)" v-model="property.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Workflow') }}</label>

              <b-input-group>
                <b-form-select :state="!helper.empty(property.workflow)" class="form-control  form-select"
                               v-model="property.workflow">
                  <b-form-select-option :value="null">{{ trans('Selectionnez un workflow') }}
                  </b-form-select-option>
                  <b-form-select-option :key="'workflow_' + workflow.code" :value="workflow.code"
                                        v-for="workflow in workflows">
                    {{ trans(workflow.name) }}
                  </b-form-select-option>
                </b-form-select>
              </b-input-group>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{
                  trans('Reinitialiser à chaque nouvel avenant ?')
                }}</label>
              <BooleanInput v-model="property.resetForEachAvenant"/>
            </div>

            <div class="col-auto m-2 flex-grow-1"
                 v-if="property.item && property.item.className && !property.item.className.match(/Asset|OBJECT/)">
              <label :for="'name'" class="required form-label">{{ trans('Lien') }} {{ property.link }}</label>
              <b-form-select :state="!helper.empty(property.code)"
                             class="form-control form-select-solid form-select" v-model="property.link">
                <b-form-select-option :value="null">Selectionnez un lien</b-form-select-option>
                <b-form-select-option :key="key" :value="prop.name" v-for="prop, key in classProperties">
                  {{ trans(prop.name) }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Type') }}</label>
              <b-form-select :state="!helper.empty(property.type)" @change="typeChanged"
                             class="form-control form-select-solid form-select" v-model="property.type">
                <b-form-select-option :value="null">Selectionnez un type</b-form-select-option>
                <b-form-select-option :key="key" :value="type" v-for="type, key in dataTypes">
                  {{ trans(type.label) }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <div class="col-auto m-2 flex-grow-1"
                 v-if="property.type && property.type.code.match(/RADIO|CHECKBOX|PACK_CATEGORY/)">
              <label :for="'value'" class="form-label">{{ trans('Valeur par defaut') }}</label>
              <select class="form-control form-select" v-model="property.value">
                <option>
                  Selectionnez une valeur par default
                </option>
                <option :value="choice.code" v-for="choice in property.choices">
                  {{ choice.label }}
                </option>
              </select>
            </div>

            <div class="col-auto m-2 flex-grow-1 w-100"
                 v-if="property.type && property.type.code.match(/RADIO|CHECKBOX|PACK_CATEGORY/)">
              <div class="d-flex justify-content-between mb-2">
                <label :for="'code'" class="required fw-bolder form-label">{{ trans('Choix') }}</label>
                <div>
                  <div @click="addChoice" class="btn btn-icon btn-primary btn-xs">
                    <i class="fad fa-plus"></i>
                  </div>
                  <div @click="importFromQuestion" class="btn btn-icon btn-warning ms-2 btn-xs">
                    <i class="fad fa-download"></i>
                  </div>
                </div>
              </div>
              <div class="p-5 border-dashed d-flex flex-wrap">
                <div class="d-flex flex-column m-1 flex-wrap flex-grow-1 bg-light-primary p-5"
                     v-for="choice in property.choices">
                  <div>
                    Code
                  </div>
                  <div>
                                    <textarea class="form-control w-100" v-model="choice.code"
                                              style="border:1px gray dashed;outline: none;font-size:.8em"></textarea>
                  </div>
                  <div>
                    Label
                  </div>
                  <div>
                                    <textarea class="form-control" v-model="choice.label"
                                              style="border:1px gray dashed;outline: none;"/>
                  </div>
                  <!-- <div class="d-flex flex-grow-1"> -->

                  <!-- <b-input-group-append> -->
                  <div @click="removeChoice(choice)"
                       class="btn btn-sm mt-5 btn-danger rounded">
                    <i class="fad fa-trash"></i>
                    Supprimer
                  </div>
                  <!-- </b-input-group-append> -->
                  <!-- </div> -->
                </div>
              </div>
            </div>


            <div class="col-auto m-2 flex-grow-1 w-100">
              <div class="d-flex justify-content-between mb-2">
                <label :for="'code'" class=" fw-bolder form-label">{{
                    trans('Conditions d\'affichage')
                  }}</label>
                <div>
                  <div @click="addconditionGroup(property)" class="btn btn-icon btn-primary btn-xs">
                    <i class="fad fa-plus"></i>
                  </div>

                </div>
              </div>
              <div class="p-5 border-dashed flex-wrap">

                <div :key="'g_' + helper.generateId()" v-for="group, index in property.conditionGroups"
                     class="border-dashed bg-light-success p-5">
                  <div>
                    <i class="fad fa-times"></i>
                  </div>
                  <div class="d-flex flex-wrap">
                    <div :key="'c_' + cindex" v-for="condition, cindex in group.conditions"
                         class="d-flex align-items-center">
                      <div class="btn btn-success btn-sm mx-2" v-if="cindex > 0">
                        ET
                      </div>
                      <div class="bg-secondary rounded p-1">

                        <div class="d-flex align-items-center"
                             :set="items = getConditionAuthorizedItems(property)">
                          <b-dropdown no-caret no-flip left size="sm" tag="div"
                                      toggle-class="topbar-item text-decoration-none p-0" variant="link">
                            <template v-slot:button-content>
                              <div class="btn btn-sm btn-clean btn-dropdown btn-primary">
                                <template v-if="!condition.testingProperty">
                                  {{ trans('Propriété') }}
                                </template>
                                <template v-else>
                                  {{ condition.testingProperty.name }}
                                </template>
                              </div>
                            </template>
                            <template #default="{ hide }">
                              <b-dropdown-text class="p-0 min-w-300px" tag="div">
                                <div class="row g-0 overflow-auto mh-325px px-5">
                                  <template v-for="type in items">
                                    <div>
                                      <ItemTypeClickInspector :type="type"
                                                              :allowSub="true"
                                                              @setProperty="setConditionProperty(condition, $event); hide()">
                                      </ItemTypeClickInspector>
                                    </div>
                                  </template>
                                </div>
                              </b-dropdown-text>
                            </template>
                          </b-dropdown>
                          <b-dropdown :disabled="!condition.testingProperty" no-caret no-flip
                                      right size="sm" tag="div"
                                      toggle-class="topbar-item text-decoration-none p-0" variant="link">
                            <template v-slot:button-content>
                              <div
                                  class="btn btn-sm btn-clean btn-dropdown btn-primary  mx-1">
                                <template v-if="!helper.empty(condition.operator)">
                                  {{ condition.operator }}
                                </template>
                                <template v-else>
                                  Opérateur
                                </template>
                              </div>
                            </template>
                            <template v-for="operator in operators">
                              <b-dropdown-item
                                  @click="setOperator(condition, operator.symbol)"
                                  class="whitespace-no-wrap" tag="div"
                                  v-if="!operator.numeric || (operator.numeric && condition.testingProperty && condition.testingProperty.type.format === 'NUMBER')">
                                {{ operator.name }}
                              </b-dropdown-item>
                            </template>
                          </b-dropdown>
                          <template v-if="condition.testingProperty">
                            <b-dropdown no-caret no-flip right size="sm" tag="div"
                                        toggle-class="topbar-item text-decoration-none p-0"
                                        v-if="condition.testingProperty.type.code.match(/RADIO|CHECKBOX/)"
                                        variant="link">
                              <template v-slot:button-content>
                                <div
                                    class="btn btn-sm btn-clean btn-dropdown btn-primary  mx-1">
                                  <template v-if="!helper.empty(condition.value)">
                                    {{ condition.value }}
                                  </template>
                                  <template v-else>
                                    Opérande
                                  </template>
                                </div>
                              </template>
                              <b-dropdown-item @click="setValue(condition, choice.code)"
                                               class="whitespace-no-wrap" tag="div" :key="choice.id"
                                               v-for="choice in condition.testingProperty.choices">
                                {{ choice.label }}
                              </b-dropdown-item>
                            </b-dropdown>

                            <b-dropdown no-caret no-flip right size="sm" tag="div"
                                        toggle-class="topbar-item text-decoration-none p-0"
                                        v-else-if="condition.testingProperty.type.code.match(/BOOLEAN/)"
                                        variant="link">
                              <template v-slot:button-content>
                                <div
                                    class="btn btn-sm btn-clean btn-dropdown btn-primary  mx-1">
                                  <template v-if="!helper.empty(condition.value)">
                                    {{ condition.value ? 'Oui' : 'Non' }}
                                  </template>
                                  <template v-else>
                                    Opérande
                                  </template>
                                </div>
                              </template>
                              <b-dropdown-item @click="setValue(condition, true)"
                                               class="whitespace-no-wrap" tag="div">
                                Oui/Vrai
                              </b-dropdown-item>
                              <b-dropdown-item @click="setValue(condition, false)"
                                               class="whitespace-no-wrap" tag="div">
                                Non/Faux
                              </b-dropdown-item>
                            </b-dropdown>

                            <b-form-input class="w-100px" type="number"
                                          v-else-if="condition.testingProperty.type.format.match(/NUMBER/)"
                                          v-model="condition.value"></b-form-input>

                            <b-form-input class="w-100px" type="text"
                                          v-else-if="condition.testingProperty.type.format.match(/TEXT/)"
                                          v-model="condition.value"></b-form-input>

                          </template>
                          <template v-else>
                            <button class="btn btn-sm btn-primary" disabled>
                              Valeur
                            </button>
                          </template>

                          <div>
                            <div class="btn btn-sm btn-danger btn-icon ms-2"
                                 @click="removeCondition(group, condition.genId)">
                              <i class="fad fa-trash"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mt-5 justify-content-center">
                                    <span class="btn btn-icon btn-xs btn-success fs-8" @click="addCondition(group)">
                                        <!-- <i class="fad fa-plus"></i> -->
                                        ET
                                    </span>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-auto m-2 flex-grow-1" v-if="property.type && property.type.format === 'NUMBER'">
              <label :for="'value'" class="form-label">{{ trans('Valeur par defaut') }}</label>
              <b-form-input v-model="property.value"></b-form-input>
            </div>


            <div class="col-auto m-2 flex-grow-1" v-if="property.type && property.type.code === 'DATE'">
              <label :for="'value'" class="form-label">{{ trans('Default_Value') }}</label>
              <b-form-select :state="property.value !== null && property.value !== undefined &&
                        property.value.trim().length > 0" class="form-control form-select-solid form-select"
                             v-model="property.value">
                <b-form-select-option :value="null">{{ trans('Select_an_object') }}</b-form-select-option>
                <b-form-select-option :key="key" :value="key"
                                      v-for="key, index in Object.keys(dateConstants)">
                  <!--                                    {{key}}-->
                  {{ trans(key) }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <template v-if="property.type && property.type.code.match(/DATE/)">
              <div class="col-auto m-2 flex-grow-1">
                <!--                <div class="d-flex align-items-center justify-content-between">-->
                <!--                  <label :for="'value'" class="form-label">{{ trans('Min') }}</label>-->
                <!--                  <div>-->
                <!--                    <div class="form-check form-switch form-check-custom form-check-solid">-->
                <!--                      <input :checked="property.min !== null" @change="toggleMin"-->
                <!--                             class="form-check-input h-20px w-30px" type="checkbox"/>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <label :for="'value'" class="form-label">{{ trans('Min') }}</label>
                <div>
                  <select class="form-control form-select" v-model="property.min">
                    <option :value="null">Selectionnez une option</option>
                    <option :value="date.code_int" v-for="date in dates">
                      {{ date.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <div>
                  <label :for="'value'" class="form-label">{{ trans('Max') }}</label>
                </div>
                <select class="form-control form-select" v-model="property.max">
                  <option :value="null">Selectionnez une option</option>
                  <option :value="date.code_int" v-for="date in dates">
                    {{ date.name }}
                  </option>
                </select>
              </div>
            </template>

            <div class="col-auto m-2 flex-grow-1"
                 v-if="property.type && property.type.code.match(/OBJECT_GROUP|OBJECT/)">
              <label :for="'code'" class="required form-label">{{ trans('Value') }}</label>
              <b-input-group class="mt-3">
                <b-form-select :state="property.value !== null && property.value !== undefined &&
                            property.value.trim().length > 0" class="form-control form-select-solid form-select"
                               v-model="property.value">
                  <b-form-select-option :value="null">{{ trans('Select_an_object') }}
                  </b-form-select-option>
                  <b-form-select-option :key="key" :value="item.uuid" v-for="item, key in itemTypes">
                    {{ trans(item.name) }}
                  </b-form-select-option>
                </b-form-select>
                <b-input-group-append>
                  <div class="btn btn-icon btn-primary"
                       style="border-top-left-radius: 0;border-bottom-left-radius: 0" v-b-modal.modal-1>
                    <i class="fad fa-plus"></i>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </div>

            <template v-if="property.type && property.type.code === 'OBJECT_GROUP'">
              <div class="col-auto m-2 flex-grow-1">
                <div class="d-flex align-items-center justify-content-between">
                  <label :for="'value'" class="form-label">{{ trans('Min') }}</label>
                  <div>
                    <div class="form-check form-switch form-check-custom form-check-solid">
                      <input :checked="property.min !== null" @change="toggleMin"
                             class="form-check-input h-20px w-30px" type="checkbox"/>
                    </div>
                  </div>
                </div>
                <div>
                  <input-number :min="0" v-if="property.min !== null" v-model="property.min">
                  </input-number>
                  <input class="form-control" disabled type="number" v-else>
                </div>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <div class="d-flex align-items-center justify-content-between">
                  <label :for="'value'" class="form-label">{{ trans('Max') }}</label>
                  <div>
                    <div class="form-check form-switch form-check-custom form-check-solid">
                      <input :checked="property.max !== null" @change="toggleMax"
                             class="form-check-input h-20px w-30px" type="checkbox"/>
                    </div>
                  </div>
                </div>
                <input-number :min="0" v-if="property.max !== null" v-model="property.max"></input-number>
                <input class="form-control" disabled type="number" v-else>
              </div>
            </template>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveProperty()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
      <b-modal :title="trans('Calcul')" centered id="methodModal" ref="methodModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label class="required form-label">{{ trans('Nom') }}</label>
              <div>
                <b-form-input :state="!helper.empty(method.label)" class="form-control" type="text"
                              v-model="method.label">
                </b-form-input>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label class="form-label">{{ trans('Description') }}</label>
              <div>
                <textarea class="form-control" v-model="method.description"></textarea>
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editMethod()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

    </template>

  </Base>
</template>
<script lang="ts">
/* eslint-disabled */
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import IconSelector from "@/components/IconSelector.vue";
import ItemType from "@/entity/ItemType";
import ConditionGroup from "@/entity/ConditionGroup";
import Loading from "@/components/Loading.vue";
import ItemProperty from "@/entity/ItemProperty";
import CONSTANTS, {DATES} from "@/Utils/constant";
import Popup from "@/entity/Popup";
import Branch from "@/entity/Branch";
import InputNumber from "@/components/InputNumber.vue";
import DataType from "@/entity/DataType";
import PropertyChoice from "@/entity/PropertyChoice";
import {helper} from "@/services/Helper";
import EngineMethod from "@/entity/EngineMethod";
import Printable from "@/entity/Printable";
import PropertyCondition from '@/entity/PropertyCondition';
import Swal from "sweetalert2";
import QuestionChoice from "@/entity/QuestionChoice";
import BooleanInput from "@/components/BooleanInput.vue";

@Component({
  components: {BooleanInput, InputNumber, Loading, IconSelector, Base}
})
export default class ItemTypeList extends Vue {
  [x: string]:
  /* eslint-disabled */
      any;

  dates = DATES
  operators = [
    {'name': 'Egal', 'symbol': '==', 'numeric': false},
    {'name': 'Inférieur', 'symbol': '<', 'numeric': true},
    {'name': 'Inférieur ou égal', 'symbol': '<=', 'numeric': true},
    {'name': 'Supérieur', 'symbol': '>', 'numeric': true},
    {'name': 'Supérieur ou égal', 'symbol': '>=', 'numeric': true},
    {'name': 'Différent', 'symbol': '!=', 'numeric': false},
  ]

  workflows = [
    {name: 'Attestation Digitale Automobile', code: 'attestation_digitale_automobile'}
  ]
  objects = [
    {name: 'Actif', class: 'Asset', type: 'CUSTOM_OBJECT'},
    {name: 'Bénéficiaire', class: 'Customer', type: 'PRIVATE_OBJECT'},
    {name: 'Groupe d\'objets', class: 'OBJECT_GROUP', type: 'CUSTOM_OBJECT'},
    {name: 'Objet', class: 'OBJECT', type: 'CUSTOM_OBJECT'},
    {name: 'Pack', class: 'Pack', type: 'PRIVATE_OBJECT'},
  ]
  dataTypes: DataType[] = []

  async typeChanged() {
    // console.log(this.property.type)
    if (this.property.type.code === 'CHECKBOX') {
      if (this.property.type.class === 'Insurer') {
        const res = await api.get(api.core, 'insurer/list')
        // console.log(res)
        if (res && res.data && res.data.insurers) {
          this.property.choices = [];
          res.data.insurers.forEach((i: any) => {
            const occ = new PropertyChoice();
            occ.code = i.slug;
            occ.label = i.label;
            occ.icon = i.photo
            this.property.choices.push(occ)
          })
        }
      }
    }
  }

  root: any = null
  itemTypes: ItemType[] = []
  item = new ItemType()
  property = new ItemProperty()
  branch: any = null
  loading = false
  dateConstants = CONSTANTS.dateConstants
  classProperties: any[] = []
  methods: EngineMethod[] = []
  isLoading = false
  method = new EngineMethod()


  addconditionGroup(property: ItemProperty) {
    const cg = new ConditionGroup();
    this.addCondition(cg)
    property.conditionGroups.push(cg)
    // console.log(property)

  }

  removeCondition(group: ConditionGroup, genId: string) {
    console.log(genId)
    const index = group.conditions.findIndex((c: PropertyCondition) => {
      return c.genId === genId
    })

    if (index > -1) {
      group.conditions.splice(index, 1)
    }
  }

  addCondition(group: ConditionGroup) {
    const cp = new PropertyCondition()
    group.conditions.push(cp)
  }

  getConditionAuthorizedItems(property: ItemProperty) {
    let req = this.itemTypes.filter((i: ItemType) => {
      return i.required || i.isRoot || i.id === property.item.id
    })
    req = req.sort((a: ItemType, b: ItemType) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    return req
  }

  getConditionalProperties(property: ItemProperty) {

    console.log(this.itemTypes)
    // console.log('properties of ' + property.item.name)
    let parents = this.findItemTypeParents(property.item)
    parents.push(property.item)
    // console.log(parents)
    return parents
  }

  async cleanOrphanProperties() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'item/property/clean/orphan/properties')
    this.$store.commit('stopLoading')
  }

  getRoot() {
    return this.itemTypes.find((item: ItemType) => item.isRoot)
  }

  setConditionProperty(condition: PropertyCondition, evt: any) {
    // console.log(condition)
    // console.log(evt)
    condition.testingProperty = evt.prop
    this.$forceUpdate()
  }

  setOperator(condition: PropertyCondition, operator: string) {
    condition.operator = operator
  }

  setValue(condition: PropertyCondition, value: any) {
    condition.value = value
  }

  getItemProperties(item: ItemType) {
    return item.properties
  }

  findItemTypeParents(itemType: ItemType, parents: ItemType[] = []) {
    // console.log('----------------------')
    // console.log('checking parent of ' + itemType.name)
    for (let it of this.itemTypes) {
      for (let prop of it.properties) {
        if (prop.type.code === 'OBJECT' || prop.type.code === 'OBJECT_GROUP') {
          if (prop.value === itemType.uuid) {
            // console.log('found ' + it.name)
            parents.push(it)
            this.findItemTypeParents(it, parents)
          }
        }
      }
    }
    return parents;
  }

  async toggleRequired(property: ItemProperty) {
    const popup = new Popup(this.trans('Loading'),
        this.trans('Updating_' + property.name + '_required_status'),
        'secondary', 'fad fa-cloud-upload-alt', true)
    const res = await api.get(api.core, 'toggle/model/property/required/' + property.id)
    if (res.property) {
      property.required = res.property.required
    }
    popup.hide()
  }

  importFromQuestion() {

  }

  async toggleIdentifier(property: ItemProperty) {
    const popup = new Popup(this.trans('Loading'),
        this.trans('Updating_' + property.name + '_identifier_status'),
        'secondary', 'fad fa-cloud-upload-alt', true)
    const res = await api.get(api.core, 'item/property/toggle/identifier/' + property.id)
    if (res && res.data) {
      property.identifier = res.data.property.identifier
    }
    popup.hide()
  }


  async toggleAlias(property: ItemProperty) {
    const popup = new Popup(this.trans('Loading'), this.trans('Actualisation'), 'secondary', 'fad fa-cloud-upload-alt', true)
    const res = await api.get(api.core, 'item/property/toggle/alias/' + property.id)
    if (res && res.data) {
      property.isAlias = res.data.property.isAlias
    }
    popup.hide()
  }

  async toggleDevis(property: ItemProperty) {
    const popup = new Popup(this.trans('Loading'), this.trans('Actualisation'), 'secondary', 'fad fa-cloud-upload-alt', true)
    const res = await api.get(api.core, 'item/property/toggle/devis/' + property.id)
    if (res && res.data) {
      property.showOnDevis = res.data.property.showOnDevis
    }
    popup.hide()
  }

  getPremium() {
    console.log(this.item)
  }

  toggleMin() {
    if (this.property.min !== null) {
      this.property.min = null as any
    } else {
      this.property.min = 0
    }
  }

  toggleMax() {
    if (this.property.max !== null) {
      this.property.max = null as any
    } else {
      this.property.max = 0
    }
  }

  addChoice() {
    let empty = false
    this.property.choices.forEach((c: PropertyChoice) => {
      if (helper.empty(c.label)) {
        empty = true
      }
    })
    if (!empty) {
      const occ = new PropertyChoice()
      this.property.choices.push(occ)
    }
  }

  async removeChoice(choice: PropertyChoice) {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'item/property/delete/choice/' + choice.id)
    this.$store.commit('stopLoading')

    if (res && res.data && res.data.status === 'success') {
      const index = this.property.choices.findIndex((c: PropertyChoice) => {
        return c == choice
      })
      if (index !== -1) {
        this.property.choices.splice(index, 1)
      }
    }
  }

  addPrintable() {
    const occ = new Printable()
    this.item.printables.push(occ)
  }

  removePrintable(printable: any) {
    const index = this.item.printables.findIndex((p: Printable) => {
      return p.uuid === printable.uuid
    })
    if (index !== -1) {
      this.item.printables.splice(index, 1)
    }
  }

  createRoot() {
    this.item.isRoot = true
    this.item.name = 'Racine'
    this.item.icon = 'fad fa-ob'
    this.item.className = 'OBJECT'
    this.editItem()
  }

  itemNameFromUuid(uuid: string) {
    const item = this.itemTypes.find((item: ItemType) => {
      return item.uuid === uuid
    })
    if (item) {
      return item.name
    }
    return '-'
  }

  async saveProperty() {
    // console.log(this.property)
    // return
    const res = await api.post(api.core, 'item/property/edit', {property: this.property});
    if (res && res.data) {
      (this.$refs['property-modal'] as any).hide()
      this.property = new ItemProperty()
      this.load()
    }
  }

  editProperty(property: ItemProperty, item: ItemType) {
    this.property = {...property} as any
    this.property.item = item;
    if (item.className && item.className !== 'Asset') {
      this.loadProperties(item.className)
    }
  }


  async loadProperties(classname: string) {
    const res = await api.get(api.core, 'item/type/property/list/' + classname)
    this.classProperties = []
    if (res && res.data && res.data.properties) {
      res.data.properties.forEach((a: any) => {
        this.classProperties.push(a)
      })
    }
  }

  addProperty(item: ItemType) {
    this.property = new ItemProperty()
    this.property.item = item;
    if (item.className) {
      this.loadProperties(item.className)
    }
  }

  async deleteItem(uuid: string) {
    if (this.$refs.hasOwnProperty('item-modal')) {
      (this.$refs['item-modal'] as any).hide();
    }
    this.$store.commit('loading')
    const res = await api.delete(api.core, 'item/type/delete/' + uuid);
    await this.load()
    this.$store.commit('stopLoading')
  }

  async removeProperty(property: ItemProperty) {

    const response = await Swal.fire({
      title: 'Attention',
      html: 'La suppression de cette propriété entrainera la suppression de toutes les valeurs des objets de ce modèle',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const popup = new Popup('Chargement', this.trans('Suppression de la propriété ' + property.name), 'secondary', 'fad fa-cloud-upload-alt', true)
      const res = await api.delete(api.core, 'item/property/delete/' + property.uuid);
      popup.hide()
      this.$store.commit('stopLoading')
    }
    this.load()
  }

  async updateItemRanks() {
    const popup = new Popup('', this.trans('Reorganisation en cours ...'), 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1;

    this.itemTypes.forEach((b: ItemType) => {
      if (b.required) {
        ranks.push({uuid: b.uuid, ranking: i})
        i++;
      }
    })
    this.itemTypes.forEach((b: ItemType) => {
      if (!b.required) {
        ranks.push({uuid: b.uuid, ranking: i})
        i++;
      }
    })
    const res = await api.post(api.core, 'item/type/update/ranks', {ranks})
    popup.hide()
  }

  async updatePropertyRanks(item: ItemType) {
    const popup = new Popup('', this.trans('Reorganisation en cours ...'), 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1;

    item.properties.forEach((prop: ItemProperty) => {
      ranks.push({uuid: prop.uuid, ranking: i})
      i++;

    })
    const res = await api.post(api.core, 'item/property/update/ranks', {ranks})
    popup.hide()
  }

  async mounted() {
    if (this.$route.params.slug) {
      const popup = new Popup('Chargement ...', '', 'secondary', 'fad fa-cloud-upload-alt', true)
      const res = await api.get(api.core, 'branch/get/data/' + this.$route.params.slug)
      if (res && res.data && res.data.branch) {
        this.branch = new Branch(res.data.branch)
      }
      if (!this.branch) {
        popup.hide()
        new Popup(this.trans('An_error_occurred'), this.trans('This_slug_dont_match_with_any_branch'), 'danger')
        return;
      }
      this.item.branch = this.branch

      await Promise.all([this.loadDataTypes(), this.load(), this.loadMethods()])
      popup.hide()
      // await this.loadDataTypes()
      // await this.load()
    }
    // await this.loadMethods()

  }

  async load() {
    const res = await api.get(api.core, 'item/type/list/' + this.branch.slug)
    if (res && res.data && res.data.itemTypes) {
      this.itemTypes = []
      res.data.itemTypes.forEach((itemType: any) => {
        const item = new ItemType(itemType)
        this.itemTypes.push(item)
        if (item.isRoot) {
          this.root = item
        }
      })
      this.$store.state.itemTypes = this.itemTypes
      if (this.root) {
        this.tree = this.getItemTree(this.root)
        // console.log(this.tree)
      }
    }
  }


  async loadMethods() {
    let url = 'engine/method/list/branch/methods/' + this.branch.slug;
    const res = await api.get(api.core, url)
    if (res && res.data && res.data.methods) {
      this.methods = []
      res.data.methods.forEach((m: any) => {
        const occ = new EngineMethod(m)

        this.itemTypes.forEach((i: ItemType) => {
          if (i.method) {
            if (i.method.id === occ.id) {
              i.method = occ
            }
          }
        })
        this.methods.push(occ)
      })
    }
  }

  async editMethod() {
    this.$store.commit('loading')
    const method = {id: this.method.id, label: this.method.label, description: this.method.description}
    let url = 'engine/method/edit';

    const res = await api.post(api.core, url, {method})
    if (this.$refs.methodModal) {
      (this.$refs.methodModal as any).hide()
    }
    this.$store.commit('stopLoading')
    this.load()
    // this.loadPremiumDetailModels()
  }

  async loadDataTypes() {
    const pop = new Popup('Chargement des types', '', 'primary', 'fad fa-sync', true)
    const res = await api.get(api.master, 'api/get/data/types')
    if (res && res.data && res.data.types) {
      this.dataTypes = []
      res.data.types.forEach((type: DataType) => {
        this.dataTypes.push(new DataType(type))
      })
    }
    pop.hide()
  }

  tree: any = {}
  treeConfig: any = {nodeWidth: 200, nodeHeight: 80, levelHeight: 100,}

  getItemTree(item: ItemType) {
    // return[]
    // console.log(item.name)
    let tree = {
      name: item.name,
      code: item.code,
      icon: item.icon,
      className: item.className,
      props: [] as any,
      children: [] as any,
      parent: ItemType
    };
    // console.log(item)
    item.properties.forEach((prop: ItemProperty) => {
      tree.props.push(prop)
      // console.log(prop)
      if (prop.type.code === 'OBJECT') {
        const link = this.itemTypes.find((i: ItemType) => {
          return i.uuid === prop.value
        })
        if (link) {
          const t = this.getItemTree(link)
          t.name = prop.name
          tree.children.push(t)
        }
      }
      if (prop.type.code === 'OBJECT_GROUP') {
        const link = this.itemTypes.find((i: ItemType) => {
          return i.uuid === prop.value
        })
        if (link) {
          // for (let i = 1; i < 3; i++) {
          // console.log('=>'+prop.name)
          const t = this.getItemTree(link)
          t.name = prop.name + ' ...c'
          tree.children.push(t)
          // }
        }
      }
    })
    return tree
  }


  async generate() {
    const pop = new Popup('Génération en cours', '', 'primary', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'item/type/generate/' + this.branch.slug)
    pop.hide()
    // console.log(res)
  }

  resetItem() {
    this.item = new ItemType()
    this.item.branch = this.branch
  }

  setItem(item: ItemType) {
    if (item.method) {
      const method = this.methods.find((m: EngineMethod) => {
        return m.id === item.method!.id
      })
      if (method) {
        item.method = method
      }
    }
    this.item = new ItemType({...item});
  }

  async editItem() {
    this.$store.commit('loading')
    this.item.branch = {id: this.branch.id, uuid: this.branch!.uuid} as Branch
    const res = await api.post(api.core, 'item/type/edit', {item: this.item})
    if (res.data.status === 'success') {
      this.load()
      this.resetItem();
      (this.$refs['item-modal'] as any).hide()
    }
    this.$store.commit('stopLoading')
  }
}
</script>
<style scoped>

</style>
