<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div @click="generate" class="btn-sm btn btn-warning ms-5">
              <i class="fad fa-cogs"></i>
              {{ trans('Générer') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="engine">
        <div class="d-flex align-items-baseline">
          <div class="fs-2x fw-bolder">Version {{ engine.name }}</div>
          <div class="ms-5 text-gray-400">v.{{ engine.version }}</div>

        </div>
        <div class="fw-bolder mb-5 text-gray-400">
          {{ engine.description }}
        </div>
      </div>
      <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class="" nav-wrapper-class="bg-white rounded">
        <b-tab :title-link-class="''">
          <template #title>
            GLOBAL
          </template>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end">
                <div @click="createGlobalMethod()" class="btn btn-primary btn-sm d-flex align-items-center"
                     v-b-modal="'methodModal'">
                  <i class="fad fa-plus me-2"></i>
                  Créer un calcul
                </div>

              </div>
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Calcul</th>
                  <th>Entrées</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="method in methods">
                  <tr v-if="!method.insurer && !method.engine" class="bg-hover-light-primary">
                    <td>
                      <div>
                        {{ method.id }} - {{ method.label }}
                      </div>
                      <div class="text-gray-400 ">
                        {{ method.description }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-wrap">
                        <div class="badge badge-light-danger m-1"
                             v-for="req in method.requirements">
                          {{ req.label }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-end">
                        <div class="form-check form-switch form-check-custom form-check-solid me-2">
                          <input :disabled="updatingShowInDetails"
                                 @change="toggleShowInDetails(method)"
                                 class="form-check-input w-30px h-20px"
                                 title="Afficher dans les details" type="checkbox"
                                 v-model="method.showInDetails"/>
                        </div>
                        <router-link
                            :to="{ name: 'engine-method-editor', params: { methodId: method.id, methodSlug: method.code } }"
                            class="btn btn-xs btn-success btn-icon me-2">
                          <i class="fad fa-code"></i>
                        </router-link>
                        <div class="btn btn-xs btn-primary btn-icon me-2" @click="updateMethod(method)"
                             v-b-modal="'methodModal'">
                          <i class="fad fa-edit"></i>
                        </div>
                        <div @click="deleteMethod(method)"
                             class="btn btn-xs btn-danger btn-icon me-2">
                          <i class="fad fa-trash"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
                <tfoot>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Calcul</th>
                  <th>Entrées</th>
                  <th>Actions</th>
                </tr>
                </tfoot>
              </table>
              <div class="d-flex justify-content-end">
                <div @click="createGlobalMethod()" class="btn btn-primary btn-sm d-flex align-items-center"
                     v-b-modal="'methodModal'">
                  <i class="fad fa-plus me-2"></i>
                  Créer un calcul
                </div>

              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title-link-class="''">
          <template #title>
                    <span class="text-uppercase" v-if="branch">
                        {{ branch.label }}
                    </span>
          </template>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end">
                <div @click="createMethod()" class="btn btn-primary btn-sm d-flex align-items-center"
                     v-b-modal="'methodModal'">
                  <i class="fad fa-plus me-2"></i>
                  Créer un calcul
                </div>

              </div>
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Calcul</th>
                  <td>Entrée</td>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="method in methods">
                  <tr v-if="!method.insurer && method.engine" class="bg-hover-light-primary">
                    <td>
                      <div>
                        {{ method.id }} - {{ method.label }}
                      </div>
                      <div class="text-gray-400 ">
                        {{ method.description }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-wrap">
                        <div class="badge badge-light-danger m-1"
                             v-for="req in method.requirements">
                          {{ req.label }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-end">
                        <div class="form-check form-switch form-check-custom form-check-solid me-2">
                          <input :disabled="updatingShowInDetails"
                                 @change="toggleShowInDetails(method)"
                                 class="form-check-input w-30px h-20px"
                                 title="Afficher dans les details" type="checkbox"
                                 v-model="method.showInDetails"/>
                        </div>
                        <router-link
                            :to="{ name: 'engine-method-editor', params: { methodId: method.id, methodSlug: method.code } }"
                            class="btn btn-xs btn-success btn-icon me-2">
                          <i class="fad fa-code"></i>
                        </router-link>
                        <div class="btn btn-xs btn-primary btn-icon me-2" @click="updateMethod(method)"
                             v-b-modal="'methodModal'">
                          <i class="fad fa-edit"></i>
                        </div>
                        <div @click="deleteMethod(method)"
                             class="btn btn-xs btn-danger btn-icon me-2">
                          <i class="fad fa-trash"></i>
                        </div>
                        <!--                                                <div class="btn btn-xs btn-warning btn-icon me-2">-->
                        <!--                                                    <i class="fad fa-crosshairs"></i>-->
                        <!--                                                </div>-->
                        <b-dropdown class="" no-caret no-flip right tag="div"
                                    toggle-class="topbar-item text-decoration-none p-0" variant="link">
                          <template v-slot:button-content>
                            <div class="btn btn-xs btn-warning btn-icon rounded">
                              <i class="fad fa-crosshairs"></i>
                            </div>
                          </template>
                          <b-dd-item @click="duplicateMethod(method.id, insurer.id)"
                                     :key="'insurer-' + insurer.id" v-for="insurer in insurers">
                            {{ insurer.label }}
                          </b-dd-item>
                        </b-dropdown>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
                <tfoot>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Calcul</th>
                  <th>Entrées</th>
                  <th>Actions</th>
                </tr>
                </tfoot>
              </table>
              <div class="d-flex justify-content-end">
                <div @click="createMethod()" class="btn btn-primary btn-sm d-flex align-items-center"
                     v-b-modal="'methodModal'">
                  <i class="fad fa-plus me-2"></i>
                  Créer un calcul
                </div>

              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title-link-class="''" v-for="insurer in insurers" :key="'tab-' + insurer.id">
          <template #title>
            <template>
              {{ insurer.label }}
            </template>
          </template>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end">
                <div @click="createMethod(insurer)" class="btn btn-primary btn-sm d-flex align-items-center"
                     v-b-modal="'methodModal'">
                  <i class="fad fa-plus me-2"></i>
                  Créer un calcul
                </div>

              </div>
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <td>Calcul</td>
                  <th>Entrées</th>
                  <td>Actions</td>
                </tr>
                </thead>
                <tbody>
                <template v-for="method in methods">
                  <tr v-if="method.insurer && method.insurer.uuid === insurer.uuid" class="bg-hover-light-primary">
                    <td>
                      <div>
                        {{ method.id }} - {{ method.label }} ▪ <span class="fw-bolder">{{ method.code }}</span>
                      </div>
                      <div class="text-gray-400">
                        {{ method.description }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-wrap">
                        <div class="badge badge-light-danger m-1"
                             v-for="req in method.requirements">
                          {{ req.label }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-end">
                        <div class="form-check form-switch form-check-custom form-check-solid">
                          <input :disabled="updatingShowInDetails"
                                 @change="toggleShowInDetails(method)"
                                 class="form-check-input w-30px h-20px"
                                 title="Afficher dans les details" type="checkbox"
                                 v-model="method.showInDetails"/>
                        </div>
                        <router-link
                            :to="{ name: 'engine-method-editor', params: { engineVersion : $route.params.engineVersion, branchSlug:$route.params.branchSlug, methodId: method.id, methodSlug: method.code } }"
                            class="btn btn-xs btn-success btn-icon mx-2">
                          <i class="fad fa-code"></i>
                        </router-link>
                        <div class="btn btn-xs btn-primary btn-icon me-2" @click="updateMethod(method)"
                             v-b-modal="'methodModal'">
                          <i class="fad fa-edit"></i>
                        </div>
                        <div @click="deleteMethod(method)"
                             class="btn btn-xs btn-danger btn-icon me-2">
                          <i class="fad fa-trash"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
                <tfoot>
                <tr>
                  <td>Calcul</td>
                  <th>Entrées</th>
                  <td>Actions</td>
                </tr>
                </tfoot>
              </table>
              <div class="d-flex justify-content-end">
                <div @click="createMethod(insurer)" class="btn btn-primary btn-sm d-flex align-items-center"
                     v-b-modal="'methodModal'">
                  <i class="fad fa-plus me-2"></i>
                  Créer un calcul
                </div>

              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <b-modal :title="trans('Calcul')" centered id="methodModal" ref="methodModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label class="required form-label">{{ trans('Nom') }}</label>
              <div>
                <b-form-input :state="!helper.empty(method.label)" class="form-control" type="text"
                              v-model="method.label">
                </b-form-input>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label class="required form-label">{{ trans('Nom Public') }}</label>
              <div>
                <b-form-input :state="!helper.empty(method.alias)" class="form-control" type="text"
                              v-model="method.alias">
                </b-form-input>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label class="form-label">{{ trans('Description') }}</label>
              <div>
                <textarea class="form-control" v-model="method.description"></textarea>
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editMethod()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Base from "@/layouts/Base.vue";
import EngineMethod from "@/entity/EngineMethod";
import Branch from "@/entity/Branch";
import Insurer from '@/entity/Insurer';
import Engine from '@/entity/Engine';
import Popup from "@/entity/Popup";
import Swal from "sweetalert2";

@Component({
  components: {Base}
})
export default class MethodList extends Vue {

  method = new EngineMethod()
  methods: EngineMethod[] = []
  branch: Branch | null = null
  engine: Engine | null = null
  uploadRoot = api.uploadRoot
  insurers: Insurer[] = []
  insurer: any = null
  updatingShowInDetails = false


  mounted(): void {
    this.loadMethods()
  }

  async generate() {
    this.$store.commit('loading')

    const res = await api.get(api.core, 'engine/generate/' + this.engine!.id)
    this.$store.commit('stopLoading')
    if (res && res.data && res.data.message && res.data.status !== 'error') {
      new Popup('Génération en cours', res.data.message)
    }
    // await this.generateJs()
    // await this.generatePhp()


  }


  async generateJs() {
    const res = await api.get(api.core, 'engine/generate/js/' + this.engine!.id)
  }

  async generatePhp() {
    const res = await api.get(api.core, 'engine/generate/php/' + this.engine!.id)
  }

  createGlobalMethod() {
    this.global = true
    this.insurer = null
    this.method = new EngineMethod()

  }

  global = false

  createMethod(insurer: any = null) {
    this.global = false
    this.insurer = insurer
    this.method = new EngineMethod()

  }

  async duplicateMethod(methodId: number, insurerId: number) {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'engine/method/duplicate/' + this.engine!.id + '/' + methodId + '/' + insurerId)
    this.$store.commit('stopLoading')
    this.loadMethods()
  }

  async loadMethods() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'engine/method/list/' + this.$route.params.branchSlug + '/version/' + this.$route.params.engineVersion)
    if (res && res.data) {
      this.branch = new Branch(res.data.branch)
      this.engine = new Engine(res.data.engine)
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        const occ = new Insurer(i)
        this.insurers.push(occ)
      })
      this.methods = []
      res.data.methods.forEach((m: EngineMethod) => {
        // if (m.id === 402) {
        //   console.log('Received')
        // }
        const occ = new EngineMethod(m)
        this.methods.push(occ)
      })

      // this.engines = []
      // res.data.engines.forEach((e: Engine) => {
      //     const occ = new Engine(e)
      //     this.engines.push(occ)
      // })
      // console.log(this.branch)
    }
    this.$store.commit('stopLoading')
  }


  async toggleShowInDetails(method: EngineMethod) {
    this.updatingShowInDetails = true
    const pop = new Popup(method.label, 'Actualisation ', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'engine/method/toggle/show/in/details/' + method.id)
    if (res && res.data && res.data.state) {
      method.showInDetails = res.data.state
    }
    pop.hide()
    this.updatingShowInDetails = false
  }

  async deleteMethod(method: EngineMethod) {
    const response = await Swal.fire({
      title: 'Supprimer',
      html: 'ëte vous sûr de vouloir supprimer cette méthode ?',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.get(api.core, 'engine/method/delete/' + method.id)
      this.$store.commit('stopLoading')
      this.loadMethods()

    }
  }

  async updateMethod(method: EngineMethod) {
    if (method.insurer) {
      this.insurer = method.insurer
    } else {
      this.insurer = null
    }
    if (method.branch) {
      this.global = false
    } else {
      this.global = true
    }
    this.method = method

  }

  async editMethod() {
    this.$store.commit('loading')
    const method = {id: this.method.id, label: this.method.label, description: this.method.description, alias:this.method.alias}
    let url = 'engine/method/edit';
    if (!this.global) {
      url = 'engine/method/edit' + (this.engine ? '/' + this.engine.id : '') + (this.engine && this.insurer ? '/' + this.insurer.slug : '');
    }
    const res = await api.post(api.core, url, {method})
    if (this.$refs.methodModal) {
      (this.$refs.methodModal as any).hide()
    }
    this.global = false
    this.$store.commit('stopLoading')
    this.loadMethods()
  }
}
</script>
<style scoped></style>
