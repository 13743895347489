/* eslint-disabled */
import BranchCategory from "./BranchCategory";
import ItemType from "./ItemType";
import Contract from "./Contract";
import PackCategory from "./PackCategory";
import Guarantee from "./Guarantee";
import PremiumDetailModel from "./PremiumDetailModel";
import Engine from "./Engine";
import Voucher from "./Voucher";
import TypeAvenant from "./TypeAvenant";
import CommissionModel from "./CommissionModel";
import ReductionModel from "./ReductionModel";
import BranchExtend from "@/entity/extends/BranchExtend";

export default class Branch extends BranchExtend {

    public id = '';
    public label = '';
    public icon = '';
    public slug = '';
    public description? = '';
    public category!: BranchCategory;
    public items: Array<ItemType> = [];
    public ranking? = '';
    public active? = '';
    public contracts: Array<Contract> = [];
    public packCategories: Array<PackCategory> = [];
    public color? = '';
    public guarantees: Array<Guarantee> = [];
    public premiumDetailModels: Array<PremiumDetailModel> = [];
    public engines: Array<Engine> = [];
    public version? = '';
    public vouchers: Array<Voucher> = [];
    public startDate? = '';
    public orderText? = '';
    public illustration? = '';
    public typeAvenants: Array<TypeAvenant> = [];
    public commissionModels: Array<CommissionModel> = [];
    public reductionModels: Array<ReductionModel> = [];
    public itemType!: ItemType;
    public deletedAt? = '';
    public uuid? = '';
    public createdAt? = '';
    public updatedAt? = '';
    public createBy? = '';
    public updateBy? = '';
    public removeBy? = '';
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public printables? = '';
    public photo? = '';
    public alt? = '';
    public allowOrder = false

    constructor(object?: any) {
        super(object)
        if (object) {
            this.id = object.id;
            this.label = object.label;
            this.icon = object.icon;
            this.slug = object.slug;
            this.allowOrder=object.allowOrder
            this.description = object.description;
            this.category = (object.category instanceof BranchCategory) ? object.category : object.category ? new BranchCategory(object.category) : object.category;
            if (object.items) {
                object.items.forEach((occ: any) => {
                    const itemtype = occ instanceof ItemType ? occ : new ItemType(occ);
                    this.items.push(itemtype);
                });
            }
            this.ranking = object.ranking;
            this.active = object.active;
            if (object.contracts) {
                object.contracts.forEach((occ: any) => {
                    const contract = occ instanceof Contract ? occ : new Contract(occ);
                    this.contracts.push(contract);
                });
            }
            if (object.packCategories) {
                object.packCategories.forEach((occ: any) => {
                    const packcategory = occ instanceof PackCategory ? occ : new PackCategory(occ);
                    this.packCategories.push(packcategory);
                });
            }
            this.color = object.color;
            if (object.guarantees) {
                object.guarantees.forEach((occ: any) => {
                    const guarantee = occ instanceof Guarantee ? occ : new Guarantee(occ);
                    this.guarantees.push(guarantee);
                });
            }
            if (object.premiumDetailModels) {
                object.premiumDetailModels.forEach((occ: any) => {
                    const premiumdetailmodel = occ instanceof PremiumDetailModel ? occ : new PremiumDetailModel(occ);
                    this.premiumDetailModels.push(premiumdetailmodel);
                });
            }
            if (object.engines) {
                object.engines.forEach((occ: any) => {
                    const engine = occ instanceof Engine ? occ : new Engine(occ);
                    this.engines.push(engine);
                });
            }
            this.version = object.version;
            if (object.vouchers) {
                object.vouchers.forEach((occ: any) => {
                    const voucher = occ instanceof Voucher ? occ : new Voucher(occ);
                    this.vouchers.push(voucher);
                });
            }
            this.startDate = object.startDate;
            this.orderText = object.orderText;
            this.illustration = object.illustration;
            if (object.typeAvenants) {
                object.typeAvenants.forEach((occ: any) => {
                    const typeavenant = occ instanceof TypeAvenant ? occ : new TypeAvenant(occ);
                    this.typeAvenants.push(typeavenant);
                });
            }
            if (object.commissionModels) {
                object.commissionModels.forEach((occ: any) => {
                    const commissionmodel = occ instanceof CommissionModel ? occ : new CommissionModel(occ);
                    this.commissionModels.push(commissionmodel);
                });
            }
            if (object.reductionModels) {
                object.reductionModels.forEach((occ: any) => {
                    const reductionmodel = occ instanceof ReductionModel ? occ : new ReductionModel(occ);
                    this.reductionModels.push(reductionmodel);
                });
            }
            this.itemType = (object.itemType instanceof ItemType) ? object.itemType : object.itemType ? new ItemType(object.itemType) : object.itemType;
            this.deletedAt = object.deletedAt;
            this.uuid = object.uuid;
            this.createdAt = object.createdAt;
            this.updatedAt = object.updatedAt;
            this.createBy = object.createBy;
            this.updateBy = object.updateBy;
            this.removeBy = object.removeBy;
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            this.printables = object.printables;
            this.photo = object.photo;
            this.alt = object.alt;
        }
        this.postConstruct()
    }

}
