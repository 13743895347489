/* eslint-disabled */

export default class BranchCategoryExtend {


    commissions = 0

    constructor(object?: any) {
    }

}
