<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>
                </li>
                <li class="breadcrumb-item">
                  <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'form-list', params: { slug: survey.branch } }" v-if="survey">
                    Formulaires
                  </router-link>
                </li>
                <li class="breadcrumb-item">
                  <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li class="breadcrumb-item text-muted">
                  {{ trans('Edit_survey') }}
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <button :disabled="survey === null" @click="generate" class="btn btn-sm btn-success me-2">
              <i class="fad fa-cogs"></i>
              {{ trans('Générer le formulaire') }}
            </button>

            <button :class="[questionSelected.length === 0 ? 'btn-secondary' : 'btn-primary']"
                    :disabled="questionSelected.length === 0" @click="exportQuestions" class="btn btn-sm">
              <i class="fad fa-download"></i>
              {{ trans('Exporter les questions') }}
            </button>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex flex-wrap flex-stack mb-6" v-if="survey && branch">
        <h3 class="fw-bolder my-2"> {{ trans(survey.label) }} ◾ {{ trans('branch') }} {{ trans(survey.branch) }}
          <span class="fs-6 text-gray-400 fw-bold ms-1" v-if="survey.active">Active</span>
        </h3>
        <!--                <div class="d-flex align-items-center">-->
        <!--                    Identifié par : &nbsp;-->
        <!--                    <div class="bg-success d-flex fw-bolder px-2 py-1 rounded text-white">-->
        <!--                        <div v-for="alias,index in survey.aliases">-->
        <!--                            {{alias.data.label}}-->
        <!--                            <template v-if="index+1 < survey.aliases.length">-->
        <!--                                ▪ &nbsp;-->
        <!--                            </template>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="btn btn-icon btn-warning btn-xs ms-2">-->
        <!--                        <i class="fad fa-edit"></i>-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>


      <div class="d-flex flex-wrap my-5 align-items-center justify-content-center bg-white rounded shadow-sm p-5"
           v-if="survey">
        <h4 class="w-100 d-flex justify-content-center fw-bolder">Alias</h4>

        <div class="flex-grow-0 m-2 d-flex align-items-center" v-for="alias in survey.aliases">
          <!--                    <select class="form-control form-select">-->
          <!--                        <option v-for="question in allQuestions" value="">{{question.label}}</option>-->
          <!--                    </select>-->

          <VueMultiselect :deselectLabel="'Retirer'" :max-height="150" :options="allQuestions" :searchable="true"
                          :selectedLabel="'Ok'" :tagPlaceholder="trans('Selectionner une question')" :taggable="true"
                          @input="setAlias" group-label="surveySlug" group-values="questions" label="label"
                          placeholder="Selectionner une question" select-label="Ajouter" tag-placeholder="Ajouter"
                          track-by="uuid" v-model="alias.data"></VueMultiselect>
          <i @click="removeAlias(alias)" class="fad fa-times ms-3 cursor-pointer text-danger"></i>
        </div>
        <div @click="addAlias" class="btn btn-primary btn-sm btn-icon ms-5">
          <i class="fad fa-plus"></i>
        </div>
        <div @click="saveAlias" class="btn btn-success btn-sm btn-icon ms-2">
          <i class="fad fa-save"></i>
        </div>
      </div>
      <!--            <div class="d-flex justify-content-center">-->
      <!--                <div class="btn btn-sm btn-primary">-->
      <!--                    <i class="fad fa-plus"></i>{{trans('Ajouter un alias')}}-->
      <!--                </div>-->
      <!--            </div>-->
      <!--            <div class="card card-custom">-->
      <!--                <div class="card-body p-0">-->
      <div class="wizard wizard-1 mw-100">
        <div class="wizard-nav border-bottom position-relative d-flex align-items-center overflow-auto h-100px">
          <div class="wizard-steps  d-flex justify-content-evenly align-items-center flex-grow-1 position-absolute"
               v-if="survey" style="left:0">
            <draggable :animation="200" :list="survey.steps" @end="updateStepRanks"
                       class="wizard-steps d-flex justify-content-evenly align-items-center flex-grow-1"
                       filter=".action-button" ghost-class="bg-light-primary" group="steps" tag="ul">
              <div :key="'step-' + step.uuid" v-for="(step, index) in survey.steps">
                <div :class="{ 'active': currentStep && currentStep.ranking >= step.ranking }"
                     class="min-w-150px mx-5 wizard-step d-flex align-items-center text-center position-relative"
                     v-if="step">
                  <div @click="selectStep(step)"
                       class="wizard-label mx-5 position-relative cursor-pointer">
                    <i :class="step.icon" @dragenter="mouseOverStep(step)" class="fs-3x"></i>
                    <p class="wizard-title pt-2 fw-bolder">{{ step.label }}</p>
                  </div>
                  <div @click="setStep(step)"
                       class="btn btn-xs btn-icon btn-warning cursor-pointer position-absolute"
                       style="right:10px;top:10px" v-b-modal.stepForm>
                    <i class="fad fa-edit text-inverse-warning"></i>
                  </div>
                </div>
                <span :key="'arrow-' + step.id" class="svg-icon svg-icon-xl wizard-arrow" v-if="step">
                                <i :class="{ 'text-primary': currentStep && currentStep.ranking > step.ranking }"
                                   class="fad fa-arrow-right"></i>
                            </span>
              </div>
            </draggable>
            <div>
              <div @click="createStep" class="btn btn-sm btn-icon btn-primary" v-b-modal.stepForm>
                <i title="trans('Add_a_new_step')" class="fad fa-plus text-white"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="" v-if="survey && currentStep">
            <step-view :questionSelected="questionSelected" :questionTypes="questionTypes" :step="step"
                       :survey="survey" :durations="durations" v-for="step in survey.steps" v-on:loadTypes="loadTypes"
                       :key="'step-view-'+step.uuid" :packCategories="packCategories"
                       v-on:reload="load" v-on:toggleQuestion="toggleQuestion" v-show="step.uuid === currentStep.uuid">
            </step-view>

            <!--                        <step-view :questionTypes="questionTypes" :step="currentStep" :survey="survey" v-if="currentStep"-->
            <!--                                   v-show="survey && currentStep" v-on:loadTypes="loadTypes" v-on:reload="load"></step-view>-->
          </div>
        </div>
        <!--end::Wizard Body-->
      </div>
      <!--end::Wizard-->
      <!--                </div>-->
      <!--end::Wizard-->
      <!--            </div>-->


      <b-modal :title="trans('Edit_Step')" centered id="stepForm" ref="stepForm">
        <div class="d-flex">
          <div>
            Id :{{ step.id }}
          </div>
          <div class="ms-5">
            Rank : {{ step.ranking }}
          </div>
        </div>
        <form v-if="step">
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'icon'" class="required form-label">{{ trans('Icon') }}</label>
              <div>
                <IconSelector :selected="step.icon" v-model="step.icon"></IconSelector>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'name'" :state="!empty(step.label)" v-model="step.label"></b-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Description') }}</label>
              <b-form-textarea :id="'name'" :state="!empty(step.description)" v-model="step.description">
              </b-form-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Template') }}</label>
              <b-form-input :id="'name'" :state="!empty(step.template)" v-model="step.template">
              </b-form-input>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="deleteStep" size="sm" v-if="step.uuid" variant="danger">
            <i class="fad fa-trash"></i>
            {{ trans('Delete') }}
          </b-button>
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editStep" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import IconSelector from "@/components/IconSelector.vue";
import {api} from "@/services/Api";
import ItemType from "@/entity/ItemType";
import Popup from "@/entity/Popup";
import Survey from "@/entity/Survey";
import Step from "@/entity/Step";
import StepView from "@/views/Configuration/Branch/SurveyComponents/StepView.vue";
import Question from "@/entity/Question";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import DataType from "@/entity/DataType";
import Duration from '@/entity/Duration';
import PackCategory from "@/entity/PackCategory";


@Component({
  components: {VueMultiselect, StepView, Base, IconSelector}
})
export default class SurveyForm extends Vue {
  [x: string]: any;

  step: Step = new Step()
  // steps : Step[] = []
  currentStep?: any = null
  itemTypes: ItemType[] = []
  branch = ''
  survey: Survey = null as any
  questionTypes: DataType[] = []
  durations: Duration[] = []
  uKey = this.genId()
  surveys: Survey[] = []
  questionSelected: string[] = [];
  aliases: any[] = [];
  dragging = false
  packCategories: PackCategory[] = []

  async loadPackCategories(slug: string) {
    const res = await api.get(api.core, 'pack/category/list/' + slug)
    this.packCategories = []
    res.data.packCategories.forEach((pc: any) => {
      const occ = new PackCategory(pc)
      this.packCategories.push(occ)
    })
    // console.log(res)
  }

  setAlias(evt: any[]) {
    // const uuids: any = []
    // evt.forEach((data: any) => {
    //     uuids.push(data.uuid)
    // })
    // console.log(this.survey.aliases)
    // this.question.defaultValue = JSON.stringify(uuids)
    // return uuids
  }

  async saveAlias() {
    this.$store.commit('loading')
    const res = await api.post(api.form, 'survey/save/aliases/' + this.survey.uuid, {aliases: this.survey.aliases})
    this.$store.commit('stopLoading')

  }

  removeAlias(alias: any) {
    const index = this.survey.aliases.findIndex((a: any) => {
      return a.data.uuid === alias.data.uuid
    })
    if (index !== -1) {
      this.survey.aliases.splice(index, 1);
    }
    this.saveAlias();
  }

  addAlias() {
    // console.log(this.survey)
    this.survey.aliases.push({data: null})
  }

  get allQuestions() {
    if (this.survey) {
      const qs: any = this.survey.getQuestions()
      const array: any[] = []
      qs.forEach((q: Question) => {
        let index = array.findIndex((t: any) => t.surveySlug === q.surveySlug)
        if (index === -1) {
          array.push({'surveySlug': q.surveySlug, label: this.survey.label, questions: []})
          index = array.length - 1
        }
        array[index].questions.push({uuid: q.uuid, label: q.label})
      })
      return array;
    }
    return []
  }

  async generate() {
    this.$store.commit('loading')
    const res = await api.get(api.form, 'survey/generate/' + this.survey.slug)
    this.$store.commit('stopLoading')
    if (res && res.data && res.data.status === 'success') {
      new Popup('Succès', 'Le formulaire a été généré', 'success', 'fad fa-thumbs-up');
    } else {
      new Popup('Erreur', 'Une erreur a été rencontrée lors de la génération du formulaire', 'danger', 'fad fa-thumbs-down');
    }
  }

  async exportQuestions() {
    const p = new Popup('', 'Exportation en cours ...', 'warning', 'fad fa-exclamation', true);
    const res = await api.post(api.form, 'question/export', {questions: this.questionSelected})
    if (res && res.data && res.data.questions) {
      console.log(res.data.questions)
      var data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(res.data.questions));
      var element = document.createElement('a');
      element.setAttribute('href', data);
      element.setAttribute('download', 'question.waform');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      this.questionSelected = []
    }
    // console.log(res)
    p.hide()
  }

  setStep(step: Step) {
    // console.log(step)
    this.step = step;
  }

  getQuestions() {
    const qs: any = this.survey.getQuestions()
    const array: any = []
    // console.log(qs)
    qs.forEach((q: Question) => {
      if (!array[q.surveySlug]) {
        array[q.surveySlug] = []
      }
      array[q.surveySlug].push({uuid: q.uuid, label: q.label, survey: q.surveySlug, type: q.type.label})
      //     console.log(q.surveySlug + ' => ' + q.label)
    })
    console.log(array)
  }

  createStep() {
    this.step = new Step();
  }

  mouseOverStep(step: Step) {
    this.selectStep(step)
  }

  selectStep(step: Step) {
    this.currentStep = step
  }

  toggleQuestion(uuid: string) {
    const index = this.questionSelected.findIndex((id: string) => id === uuid);
    if (index !== -1) {
      this.questionSelected.splice(index, 1)
    } else {
      this.questionSelected.push(uuid)
    }
  }

  async editStep() {
    this.$store.commit('loading')

    this.step.survey = {uuid: this.survey.uuid} as any;
    // if (this.step.item) {
    //     this.step.item = {uuid: this.step.item.uuid} as any;
    // }
    const res = await api.post(api.form, 'step/edit', {step: this.step})
    if (res.data.status === 'success') {
      if (this.$refs) {
        (this.$refs.stepForm as any).hide();
      }
      this.$store.commit('stopLoading')
      this.load()
    }
  }

  async loadTypes() {
    const p = new Popup('', 'Chargement des type en cours ...', 'warning', 'fad fa-exclamation', true);
    const res = await api.get(api.master, 'api/get/data/types')
    if (res && res.data && res.data.status === 'success') {
      this.questionTypes = []
      res.data.types.forEach((type: DataType) => {
        this.questionTypes.push(new DataType(type))
      })
    }
    p.hide()
  }

  async loadDurations() {
    const p = new Popup('', 'Chargement des durées en cours ...', 'warning', 'fad fa-exclamation', true);
    const res = await api.get(api.master, 'api/get/durations')
    if (res && res.data && res.data.status === 'success') {
      this.durations = []
      res.data.durations.forEach((type: Duration) => {
        this.durations.push(new Duration(type))
      })
    }
    p.hide()
  }

  async updateStepRanks() {
    const popup = new Popup('', this.trans('Reorganisation en cours ...'), 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1;

    this.survey.steps.forEach((s: any) => {
      if (s) {
        ranks.push({uuid: s.uuid, ranking: i})
        i++;
      }
    })
    const res = await api.post(api.form, 'step/update/ranks', {ranks})
    if (res && res.data && res.data.categories) {
    }
    popup.hide()
  }

  async deleteStep() {
    this.$store.commit('loading')
    if (this.$refs) {
      (this.$refs.stepForm as any).hide();
    }
    const res = await api.delete(api.form, 'step/delete/' + this.step.uuid);
    this.$store.commit('stopLoading')
    await this.load()
  }


  async mounted() {
    // await this.load()
    this.loadTypes()
    this.loadDurations()
    this.createStep();

  }


  @Watch('$route.params', {immediate: true, deep: true})
  async load() {
    if (this.$route.params.surveySlug) {
      this.$store.commit('loading')
      const res = await api.get(api.form, 'survey/get/' + this.$route.params.surveySlug)
      if (res && res.data && res.data.survey) {
        this.survey = new Survey(res.data.survey)
        if (this.currentStep) {
          let found = false
          this.survey.steps.forEach((s: Step) => {
            if (s.uuid === this.currentStep.uuid) {
              this.currentStep = {...s}
              found = true
            }
          })
          if (!found) {
            this.currentStep = null
          }
        }
        // this.uKey = this.genId()
        if (this.$route.params.branchSlug) {
          this.branch = this.$route.params.branchSlug
          if (!this.branch) {
            new Popup(this.trans('An_error_occurred'), this.trans('Unable_to_find_survey_branch'), 'danger')
            this.$store.commit('stopLoading')
            return;
          }
          this.loadPackCategories(this.branch)
        }
        if (this.currentStep === null && this.survey.steps.length > 0) {
          this.currentStep = {...this.survey.steps[0]}
        }
      }
      if (!this.survey) {
        new Popup(this.trans('An_error_occurred'), this.trans('This_slug_dont_match_with_any_survey'), 'danger')
        this.$store.commit('stopLoading')
        return;
      }
      this.$forceUpdate()
      this.$store.commit('stopLoading')
      // this.getQuestions()
    }
  }
}
</script>
<style scoped>
.wizard-step.active,
.wizard-step.active i {
  color: var(--bs-primary);
}

#add-step-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
}

.wizard-arrow:last-child {
  display: none;
}
</style>
