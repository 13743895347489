<template>
  <div :style="{ display: !question.isAuthorized() || question.hidden ? 'none' : 'flex' }"
       class="mw-100 flex-grow-1 flex-column">
    <NumberQuestion v-if="question.type.code.match(/NUMBER|INTEGER/)" :question="question"/>
    <Money v-else-if="question.type.code === 'AMOUNT'" :question="question"/>
    <CarBrand v-else-if="question.type.code === 'CAR_BRAND'" :question="question"/>
    <CarModel v-else-if="question.type.code === 'CAR_MODEL'" :question="question"/>
    <TextType v-else-if="question.type.code.match(/TEXT/)" :question="question"></TextType>
    <Checkbox v-else-if="question.type.code === 'BOOLEAN'" :question="question"></Checkbox>
    <MultipleChoice v-else-if="question.type.code === 'CHECKBOX'" :question="question"></MultipleChoice>
    <QuestionList v-else-if="question.type.code === 'QUESTION_LIST'">
    </QuestionList>
    <Contact v-else-if="question.type.code === 'CONTACT'" :question="question"></Contact>
    <Radio v-else-if="question.type.code.match(/RADIO|DURATION/)" :question="question"></Radio>
    <Mail v-else-if="question.type.code === 'EMAIL'" :question="question"></Mail>
    <Date v-else-if="question.type.code === 'DATE'" :question="question"></Date>
    <FileQuestion v-else-if="question.type.code === 'PHOTO'" :question="question"></FileQuestion>
    <FileInput v-else-if="question.type.code === 'FILE' || question.type.code === 'file'"
               :question="question"></FileInput>
    <SurveyType :responseGroup="rg" :melted="true" :question="question" v-else-if="question.type.code === 'OBJECT'"
                v-on:reset="reset">
    </SurveyType>
    <GuaranteeSelector v-else-if="question.type.code === 'GUARANTEE_SELECTOR'" :branch="branch" :question="question"
                       :rg="rg" :survey="form"
                       :currentStep="currentStep"/>
    <template v-else-if="question.type.code === 'COMPARISON'">
      <div class="d-flex py-5 justify-content-end">
        <div class="form-check form-switch form-check-custom form-check-solid ">
          <input class="form-check-input h-20px w-30px" type="checkbox" v-model="online" value=""/>
        </div>
      </div>
      <Comparison :branch="branch" :melted="true" :question="question" :rg="rg" :survey="form"
                  :currentStep="currentStep"
                  v-on:reset="reset" v-if="hasRole('ROLE_MANAGER') && !online"></Comparison>
      <ComparisonOnline v-else :branch="branch" :melted="true" :question="question" :rg="rg" :survey="form"
                        :currentStep="currentStep" v-on:reset="reset"></ComparisonOnline>
    </template>
    <template v-else-if="question.type.code === 'INSURER_OFFER'">
      <InsurerOffer :branch="branch" :melted="true" :question="question" :rg="rg" :survey="form"
                    :currentStep="currentStep" v-on:reset="reset"/>
    </template>
    <!--    <ComparisonOnline v-else-if="question.type.code === 'COMPARISON'" :branch="branch" :melted="true" :question="question" :rg="rg" :survey="form" :currentStep="currentStep" v-on:reset="reset"></ComparisonOnline>-->
    <MultipleSurvey :branch="branch" :name="'object_group_'+rg.uuid" :responseGroup="rg"
                    :creatingResponseGroup="creatingResponseGroup"
                    :survey="form"
                    :melted="question.isInline" :question="question"
                    v-else-if="question.type.code === 'OBJECT_GROUP'"
                    v-on:deleteSubResponseGroup="deleteSubResponseGroup"
                    v-on:reset="reset"></MultipleSurvey>
    <PackCategory :question="question" v-else-if="question.type.code === 'PACK_CATEGORY'">
    </PackCategory>
    <MessageType :branch="branch" :responseGroup="rg"
                 :survey="form" :question="question"
                 v-on:previous="previous"
                 v-else-if="question.type.code === 'MESSAGE'"/>
    <TextType v-else-if="question.type.format.match(/TEXT/)" :question="question"></TextType>

    <div class="d-flex align-items-center m-2" v-else>
            <span class="badge badge-danger">
                {{ question.label }}
                <br>
                {{ question.type.code }} NOT HANDLED
            </span>
    </div>
    <div class="suggestions d-flex flex-wrap justify-content-center">
      <div v-for="suggestion in question.suggestions"
           class="border-dashed flex-grow-1 d-flex justify-content-center align-items-center flex-column p-2 m-2 cursor-pointer bg-hover-light-primary rounded"
           @click="setAnswer(suggestion)">
        <img :src="uploadRoot + suggestion.icon" class="w-80px h-80px">
        <div>
          {{ suggestion.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Question from '@/entity/Question';
import {Component, Prop, Vue} from 'vue-property-decorator';

import NumberQuestion from "@/views/Income/Comparator/FormComponents/NumberQuestion.vue";
import Money from "@/views/Income/Comparator/FormComponents/Money.vue";
import CarBrand from "@/views/Income/Comparator/FormComponents/CarBrand.vue";
import CarModel from "@/views/Income/Comparator/FormComponents/CarModel.vue";
import TextType from "@/views/Income/Comparator/FormComponents/TextType.vue";
import Checkbox from "@/views/Income/Comparator/FormComponents/Checkbox.vue";
import QuestionList from "@/views/Income/Comparator/FormComponents/QuestionList.vue";
import Contact from "@/views/Income/Comparator/FormComponents/Contact.vue";
import Radio from "@/views/Income/Comparator/FormComponents/Radio.vue";
import Mail from "@/views/Income/Comparator/FormComponents/Mail.vue";
import Date from "@/views/Income/Comparator/FormComponents/Date.vue";
import FileQuestion from "@/views/Income/Comparator/FormComponents/FileQuestion.vue";
import FileInput from "@/views/Income/Comparator/FormComponents/FileInput.vue";
import SurveyType from "@/views/Income/Comparator/FormComponents/SurveyType.vue";
import PackCategory from "@/views/Income/Comparator/FormComponents/PackCategory.vue";
import Comparison from "@/views/Income/Comparator/FormComponents/Comparison.vue";
import ResponseGroup from '@/entity/ResponseGroup';
import Branch from '@/entity/Branch';
import Survey from '@/entity/Survey';
import {api} from '@/services/Api';
import Suggestion from '@/entity/Suggestion';
import Step from '@/entity/Step';
import ComparisonOnline from "@/views/Income/Comparator/FormComponents/ComparisonOnline.vue";
import MultipleChoice from "@/views/Income/Comparator/FormComponents/MultipleChoice.vue";
import GuaranteeSelector from "@/views/Income/Comparator/FormComponents/GuaranteeSelector.vue";
import InsurerOffer from "@/views/Income/Comparator/FormComponents/InsurerOffer.vue";
import MessageType from "@/views/Income/Comparator/FormComponents/MessageType.vue";


@Component({
  components: {
    MessageType,
    InsurerOffer,
    GuaranteeSelector,
    MultipleChoice,
    ComparisonOnline,
    Comparison,
    PackCategory, SurveyType,
    FileQuestion,
    Date, Mail, Radio, Contact, QuestionList, Checkbox, TextType, CarModel, CarBrand, Money, NumberQuestion, FileInput
  }
})
export default class QuestionManager extends Vue {
  @Prop() question!: Question
  @Prop() branch!: Branch
  @Prop() rg!: ResponseGroup
  @Prop() form!: Survey
  @Prop() currentStep!: Step
  uploadRoot = api.uploadRoot
  online = false


  async setAnswer(suggestion: Suggestion) {
    this.question.response.value = suggestion.code
    await this.question.updateAnswer()
    this.currentStep.validate()
    // console.log(this.currentStep.isValid)
    if (this.currentStep.isValid) {
      this.$emit('nextStep');
    }
  }

  mounted() {
    // console.log(this.question.label + ' mounted')
    this.question.setDefaultValue(this.rg)
  }

  reset(payload: any) {
    this.$emit('reset', payload);
  }

  creatingResponseGroup(payload: any) {
    this.$emit('creatingResponseGroup', payload);
  }

  previous(payload: any) {
    console.log('question previous')
    this.$emit('previous', payload);
  }

  deleteSubResponseGroup(payload: any) {
    this.$emit('deleteSubResponseGroup', payload);
  }
}


</script>
<style scoped>

</style>
