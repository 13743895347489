<template>
  <div id="filter" class="bg-danger d-flex flex-column flex-wrap justify-content-center align-items-center mt-5"
       v-if="question && question.fullscreen && !isConnected">
    <div class="fs-2x text-white fw-bolder">
      {{ question.label }}
    </div>
    <div class="d-flex justify-content-center">
      <img src="./../../../../assets/booty.png" alt="" style="width:500px;height:500px;max-width: 80%;object-fit: contain">
    </div>
    <div class="fs-2x text-white fw-bolder">
      {{ question.description }}
    </div>
    <div class="py-10">

      <div class="btn btn-dark btn-lg m-5" @click="previous">
        <i class="fad fa-chevron-left"></i>
        Précédent
      </div>
      <router-link :to="{'name': 'comparateur-index'}" class="btn btn-primary btn-lg m-5">
        Continuer
        <i class="fad fa-chevron-right"></i>
      </router-link>
    </div>
  </div>
  <div v-else class="border-dashed rounded text-center py-10 mt-5">
    <div class="fs-2x  fw-bolder">
      {{ question.label }}
    </div>
    <div class="d-flex justify-content-center">
      <img src="./../../../../assets/booty.png" alt="" style="width:300px;height:300px;max-width: 80%;object-fit: contain">
    </div>
    <div class="fs-2x fw-bolder">
      {{ question.description }}
    </div>
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import Question from "@/entity/Question";
import Branch from "@/entity/Branch";
import ResponseGroup from "@/entity/ResponseGroup";
import Survey from "@/entity/Survey";
import Step from "@/entity/Step";
import {auth} from "@/services/Auth";

@Component({})
export default class MessageType extends Vue {
  @Prop() question!: Question
  @Prop() branch!: Branch
  @Prop() rg!: ResponseGroup
  @Prop() survey!: Survey

  isConnected() {
    return auth.isConnected()
  }

  // @Prop() currentStep!: Step

  previous() {
    console.log('message previous')
    this.$emit('previous');
  }

}

</script>


<style scoped>

#filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, .75); */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  //background-size: 4px 4px;
  //background-image: radial-gradient(transparent 1px, rgba(255, 255, 255, .5) 1px);
  //backdrop-filter: saturate(50%) blur(4px);
}

</style>
