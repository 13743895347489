<template>
  <Base>
    <template v-slot:content>
      <div class="d-flex align-items-start justify-content-between">
        <div class="flex-2 flex-grow-1">
          <div class="d-flex justify-content-between mb-5">
            <h1 class="fw-bolder text-uppercase d-flex align-items-center justify-content-between flex-grow-1">
              <div class="d-flex align-items-center">
                <router-link :to="{ name: 'income-index' }">
                  <i class="fad fa-arrow-left fs-3 me-5 text-dark"></i>
                </router-link>
                <div>
                  Devis
                </div>
              </div>
            </h1>

            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div
                    class="ms-2 bg-secondary rounded w-30px h-30px d-flex align-items-center justify-content-center">
                  <i class="fad fa-user fs-2"></i>
                </div>
                <a class="fw-bolder ms-2 d-flex align-items-center" href="#">
                  <!--                  <user-view v-model="rg.manager"/>-->
                  <i class="fad fa-caret-down ms-2 text-primary"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="card mb-5 mb-xl-10">
            <div class="card-body ">
              <Loading v-if="isLoading"></Loading>
              <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <div class="d-flex justify-content-between align-items-start flex-wrap my-2">
                    <!--begin::User-->
                    <div>
                      <div class="d-flex flex-grow-1 align-items-center">
                        <div
                            class="w-150px h-150px  rounded me-5 shadow-sm d-flex justify-content-center align-items-center">
                          <img class="rounded w-100 h-100 object-cover">
                          <i class="fad fa-user fs-5x"></i>
                        </div>
                        <div class="flex-column d-flex">
                          <!--                                        <div class="fs-2 fw-bolder text-uppercase">Prospect</div>-->
                          <div class="d-flex align-items-center">
                            <a class="text-gray-800  text-hover-primary fs-2 me-1" href="#">

                            </a>
                          </div>
                          <div class="fw-bold fs-6 mb-4 pe-2">
                            <div class="d-flex align-items-center">
                              <i class="fad fa-phone me-3"></i>
                              <div class="d-flex flex-wrap align-items-center me-3">
                                <router-link :to="{name: 'show-cotation', params:{uuid:order.responseGroup.uuid}}"
                                             v-if="order.responseGroup"
                                             class="btn btn-primary">
                                  <i class="fad fa-external-link"></i>
                                  Cotation
                                </router-link>
                              </div>
<!--                              <div class="d-flex flex-wrap align-items-center me-3">-->
<!--                                <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2">-->

<!--                                  <div-->
<!--                                      class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">-->
<!--                                    <i class="fad fa-phone"></i>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->


                            </div>
                            <div class="d-flex align-items-center my-2">
                              <i class="fad fa-handshake me-3"></i>
                            </div>

                            <div class="d-flex align-items-center">
                              <i class="fad fa-at me-3"></i>
                              <a class="d-flex align-items-center text-primary my-2" href="#">
                                <!--                                {{ rg.prospect.email }}-->
                              </a>
                              <span class="d-flex align-items-center text-primary">
                                                            -
                                                        </span>
                            </div>
                            <div class="fw-bolder fs-7">
                                                        <span
                                                            class="d-flex align-items-center cursor-pointer"
                                                            v-b-tooltip.hover>
                                                            Créé
                                                        </span>
                              <span title="" class="d-flex align-items-center cursor-pointer" v-b-tooltip.hover>
                                                            Consulté
                                                        </span>
                            </div>
                            <div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center  flex-column mt-5">
                        <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                          <!--                                                <span :class="{-->
                          <!--                                                    'badge-secondary': rg.status === 'INCOMPLETE',-->
                          <!--                                                    'badge-danger': rg.status.match(/IDENTIFIED|COMPARED|FINISHED|PACK_SELECTED/),-->
                          <!--                                                    'badge-success': rg.status === 'VALIDATED',-->
                          <!--                                                }" class="badge fw-bolder fs-6">-->
                          <!--                                                    {{ rg.status }}-->
                          <!--                                                </span>-->
                          <span class="fw-bolder fs-6 ms-20">

                                                </span>
                        </div>
                        <div class="h-5px mx-3 w-100 bg-light mb-3">
                          <div class="bg-success rounded h-5px" role="progressbar"></div>
                        </div>
                      </div>
                      <!--                      <div class="d-flex justify-content-between">-->
                      <!--                        <div>-->
                      <!--                          <i class="fad fa-map-marker-alt"></i>-->
                      <!--                          <a href="#" v-if="rg.location">-->
                      <!--                            {{ rg.location.city }}, {{ rg.location.country }}-->
                      <!--                          </a>-->
                      <!--                        </div>-->
                      <!--                        <div class="ms-3">-->
                      <!--                          <i class="fad fa-mobile-alt"></i>-->
                      <!--                          <a :title="rg.device.os + ' ' + rg.device.browser" href="#"-->
                      <!--                             v-if="rg.device">-->
                      <!--                            <template v-if="!empty(rg.device.brand) || !empty(rg.device.model)">-->
                      <!--                              {{ rg.device.brand }}, {{ rg.device.model }}-->
                      <!--                            </template>-->
                      <!--                            <template v-else>-->
                      <!--                              {{ rg.device.os }}, {{ rg.device.browser }}-->
                      <!--                            </template>-->

                      <!--                          </a>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                    </div>

                    <div class="d-flex flex-column flex-grow-1 ms-10">
                      <!--                                            <div class="fs-2 fw-bolder mb-2">{{trans('Tâches')}}</div>-->


                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>
          <template>
            <h2 class="mb-5">
              Paiements ({{ payments.length }})
            </h2>
            <div class="card mb-5 mb-xl-10">
              <div class="card-body">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>
                      Opérateur
                    </th>
                    <th>
                      ID
                    </th>
                    <th>
                      Date
                    </th>
                    <th>
                      Montant
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Action
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="payment in payments">
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="me-2">
                          <img :src="uploadRoot+payment.method.photo" alt="" class="w-40px h-40px rounded">
                        </div>
                        <div>
                          {{ payment.method.label }}
                        </div>
                      </div>
                    </td>
                    <td style="vertical-align: middle">
                      {{ payment.token }}
                    </td>
                    <td style="vertical-align: middle">
                      {{ datify(payment.createdAt) }}
                    </td>
                    <td style="vertical-align: middle">
                      {{ currency(payment.amount) }}
                    </td>
                    <td style="vertical-align: middle">
                      {{ payment.status }}
                    </td>
                    <td>
                      <div class="btn btn-primary btn-icon" v-if="hasRole('ROLE_ADMIN') && (order && order.status !== 'PAYED')"
                           @click="processPayment(payment)">
                        <i class="fad fa-check-double"></i>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>

          <template>
            <h2 class="mb-5">
              Details
            </h2>
            <div class="card mb-5 mb-xl-10">
              <div class="card-body">
                <table class="table table-striped w-100 g-2">
                  <tbody>
                  <tr v-for="detail of order.details" class="bg-hover-light-primary">
                    <td>
                      {{ detail.name }}
                    </td>
                    <td class="text-end">
                      <span class="fs-2 fw-bolder">
                      {{ currency(detail.value, false, false) }}
                        </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>

          <template>
            <h2 class="mb-5">
              Produits compatible
            </h2>
            <div class="card mb-5 mb-xl-10">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center p-10 flex-column">
                  <i class="fad fa-empty-set fs-3x"></i>
                  <p class="mt-5 fs-2">
                    Impossible de fournir une proposition en raison des réponses manquantes
                  </p>
                </div>
              </div>
            </div>
          </template>
          <h2 class="mb-5">
            Activité
          </h2>

          <div class="card mb-5 mb-xl-10">
            <div class="card-body">
            </div>
          </div>
        </div>


        <div class="flex-1 flex-grow-1 ms-5">
          <div class="d-flex justify-content-center my-5">
            <!--            <router-link :to="" class="btn btn-primary" target="_blank ">-->
            <!--              <i class="fad fa-ballot-check fs-2"></i>-->
            <!--              Formulaire-->
            <!--            </router-link>-->
          </div>
          <div class="card flex-1 ">
            <div class="card-body">

            </div>
          </div>

          <div class="card flex-1  my-5">
            <div class="card-body">
              <div class="fs-2 fw-bolder mb-2 ">{{ trans('Notes') }}</div>

              <div class="d-flex justify-content-center align-items-center my-10 flex-column">
                <i class="fad fa-empty-set fs-3x"></i>
                <div class="fw-bolder text-gray-400 mt-5">
                  Aucune note pour l'instant
                </div>
              </div>

              <div class="">
                <textarea class="form-control"></textarea>
              </div>
              <div class="text-center">
                <div class="btn btn-primary btn-sm mt-5">
                  {{ trans('Ajouter une note') }}
                </div>
              </div>
            </div>
          </div>


          <div class="card flex-1  my-5">
            <div class="card-body">
              <div class="fs-2 fw-bolder mb-2 ">{{ trans('Pertinence') }}</div>
              <div class="d-flex justify-content-center p-5">
                <div class="d-flex justify-content-center align-items-center fs-2
                            fw-bolder"
                     style="border:10Px gainsboro solid;width:100px;height:100px;border-radius:100%;">
                  0%
                </div>
              </div>
              <h5 class="text-center">
                {{ trans('Ajouter une appréciation') }}
              </h5>
              <div class="d-flex">
                <div
                    class="flex-grow-1 d-flex justify-content-center cursor-pointer bg-hover-light-primary">
                  <img alt="" class="w-50px" src="/images/is.gif">
                </div>
                <div
                    class="flex-grow-1 d-flex justify-content-center cursor-pointer bg-hover-light-primary">
                  <img alt="" class="w-50px" src="/images/ps.gif">
                </div>
                <div
                    class="flex-grow-1 d-flex justify-content-center cursor-pointer bg-hover-light-primary">
                  <img alt="" class="w-50px" src="/images/s.gif">
                </div>
                <div
                    class="flex-grow-1 d-flex justify-content-center cursor-pointer bg-hover-light-primary">
                  <img alt="" class="w-50px" src="/images/ts.gif">
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </template>
  </Base>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import UserView from "@/components/UserView.vue";
import Loading from "@/components/Loading.vue";
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import PaymentTransaction from "@/entity/PaymentTransaction";
import Swal from "sweetalert2";
import Order from "@/entity/Order";
import Popup from "@/entity/Popup";
import {or} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";
import CONSTANTS from "@/Utils/constant";

@Component({
  methods: {CONSTANTS, or},
  components: {Base, Loading, UserView}
})
export default class OrderShow extends Vue {

  uploadRoot = api.uploadRoot
  payments: PaymentTransaction[] = []
  order = new Order()

  isLoading = false

  mounted() {
    this.loadOrder()
    this.loadPayments()
  }


  async processPayment(payment: PaymentTransaction) {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'payment/process/' + payment.uuid)
    this.$store.commit('stopLoading')
    if(res && res.data && res.data.status === 'success'){
      this.loadPayments()
      new Popup('Succès',"Ce paiement a été confirmé par l'agrégatteur, le traitement sera effectué dans quelques minutes")
    }else {
      const response = await Swal.fire({
        title: 'Transaction Invalide',
        html: "Voulez vous quand même créer un projet de contrat ?",
        timer: 10000,
        icon: "error",
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: 'Créer un projet',
      })
      if (response.isConfirmed) {
        this.generateProject(payment)
      }
    }
  }

  async generateProject(payment: PaymentTransaction) {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'payment/force/process/' + this.$route.params.uuid)
    this.$store.commit('stopLoading')
    if (res && res.data && res.data.contract) {
      // new Popup('Succès',res.data.message,'success')
      this.$router.push({
        name: 'contract-show',
        params: {
          contractUuid: res.data.contract
        }
      })
    }
  }

  async loadOrder() {
    const res = await api.get(api.form, 'order/get/data/' + this.$route.params.uuid)
    if (res && res.data && res.data.order) {
      this.order = new Order(res.data.order)
    }
  }

  async loadPayments() {
    const res = await api.get(api.core, 'payment/order/list/' + this.$route.params.uuid)
    if (res && res.data) {
      this.payments = []
      res.data.payments.forEach((p: any) => {
        const occ = new PaymentTransaction(p)
        this.payments.push(occ)
      })
    }
  }
}
</script>

<style scoped>

</style>
