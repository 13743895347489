<template>
  <div>
    <b-modal :id="'questionForm-' + uKey" :ref="'questionForm-' + uKey" :title="trans('Edit_Question')"
             @hidden="hideQuestionForm" centered scrollable size="xl">
      <div>
        <FileUpload :allowMultiple="false" :allowRemove="false"
                    :allowedType="'image/jpeg,image/png'" :fitMode="'contain'"
                    :icon="'fad fa-edit'"
                    :previewClass="'d-flex border-dashed w-150px object-contain cursor-pointer h-150px align-items-center justify-content-center m-auto'"
                    :text="'Selectionnez une photo'" :type="'full'" accept=".jpg,.jpeg,.png,.gif,.jfif"
                    v-model="question.image"></FileUpload>
      </div>
      <div class="question-form d-flex flex-wrap">

        <div class="col-auto my-2 flex-grow-1">
          <label :for="'filter'" class="required form-label">{{ trans('Visibilité') }}</label>
          <b-form-select :state="!helper.empty(question.visibility)" class="form-control form-select"
                         v-model="question.visibility">
            <b-form-select-option :value="null">{{ trans('Select') }}
            </b-form-select-option>
            <b-form-select-option :key="key" :value="visibility" v-for="visibility, key in visibilities">
              {{ trans(visibility) }}
            </b-form-select-option>
          </b-form-select>
        </div>

        <div class="col-auto my-2 flex-grow-1">
          <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
          <b-form-input :id="'label'" :state="!helper.empty(question.label)" type="text"
                        v-model="question.label"></b-form-input>
        </div>

        <div class="col-auto my-2 flex-grow-1">
          <label :for="'type'" class="required form-label">{{ trans('Type') }}</label>
          <div class="d-flex">
            <b-input-group>
              <b-form-select :state="!helper.empty(question.type)" @change="typeChanged"
                             class="form-control  form-select"
                             v-model="question.type">
                <b-form-select-option :value="null">{{ trans('Select_a_type') }}
                </b-form-select-option>
                <b-form-select-option :key="type.id" :value="type" v-for="type, key in questionTypes">
                  {{ trans(type.label) }}
                </b-form-select-option>
              </b-form-select>
            </b-input-group>
          </div>
        </div>

        <div class="w-100">
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Description') }}</label>
            <b-form-textarea :id="'label'" :state="!helper.empty(question.description)" type="text"
                             v-model="question.description"></b-form-textarea>
          </div>
        </div>

        <template v-if="question.type && question.type.code === 'NUMBER'">
          <div class="col-auto m-2 flex-grow-1">
            <div class="d-flex align-items-center justify-content-between">
              <label :for="'value'" class="form-label">{{ trans('Min') }}</label>
              <div>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input :checked="question.min !== null" @change="toggleMin" class="form-check-input h-20px w-30px"
                         type="checkbox"/>
                </div>
              </div>
            </div>
            <div>
              <input-number :min="-1" v-if="question.min !== null" v-model="question.min"></input-number>
              <input class="form-control" disabled type="number" v-else>
            </div>
          </div>

          <div class="col-auto m-2 flex-grow-1">
            <div class="d-flex align-items-center justify-content-between">
              <label :for="'value'" class="form-label">{{ trans('Max') }}</label>
              <div>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input :checked="question.max !== null" @change="toggleMax" class="form-check-input h-20px w-30px"
                         type="checkbox"/>
                </div>
              </div>
            </div>
            <input-number :min="0" v-if="question.max !== null" v-model="question.max"></input-number>
            <input class="form-control" disabled type="number" v-else>
          </div>
        </template>


        <template v-if="question.type && question.type.code === 'MESSAGE'">
          <div class="col-auto m-2 flex-grow-1">
            <div class="d-flex align-items-center justify-content-between">
              <label :for="'value'" class="form-label">{{ trans('Lien du bouton') }}</label>

            </div>
            <div>
              <select v-model="question.link" name="" id="" class="form form-control form-control-lg form-control-lg form-select">
                <option :value="null">Aucun</option>
                <option value="home">Accueil</option>
              </select>
            </div>
          </div>

          <div class="col-auto my-2 flex-grow-1">
            <label :for="'regex'" class="form-label">{{ trans('Plein ecran') }}</label>
            <div class="form-check form-switch form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" v-model="question.fullscreen" value=""/>
            </div>
          </div>
        </template>


        <template v-if="question.type && question.type.code === 'COMPARISON'">
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'regex'" class="form-label">{{ trans('Autoriser la génération de devis') }}</label>
            <div class="form-check form-switch form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" v-model="question.fullscreen" value=""/>
            </div>
          </div>
        </template>

        <!--                <div class="col-auto my-2 flex-grow-1" v-if="question.type && question.type.code === 'OBJECT'">-->
        <!--                    <label :for="'type'" class="required form-label">{{trans('Form')}}</label>-->
        <!--                    <div class="d-flex">-->
        <!--                        <b-input-group>-->
        <!--                            <b-form-select :state="!helper.empty(question.defaultValue)"-->
        <!--                                           class="form-control  form-select"-->
        <!--                                           v-model="question.defaultValue">-->
        <!--                                <b-form-select-option :value="null">{{trans('Select_a_type')}}-->
        <!--                                </b-form-select-option>-->
        <!--                                <b-form-select-option :key="survey.uuid" :value="survey.uuid"-->
        <!--                                                      v-for="survey,key in surveys">-->
        <!--                                    {{trans(survey.label)}}-->
        <!--                                </b-form-select-option>-->
        <!--                            </b-form-select>-->
        <!--                        </b-input-group>-->
        <!--                    </div>-->
        <!--                </div>-->


        <div class="col-auto my-2 flex-grow-1"
             v-if="question.type && question.type.code && question.type.code.match(/QUESTION_LIST/)">
          <label :for="'type'" class="required form-label">{{ trans('Question') }}</label>
          <div class="d-flex">

            <VueMultiselect :close-on-select="false" :deselectLabel="'Retirer'" :max-height="150" :multiple="true"
                            :options="allQuestions" :searchable="true" :selectedLabel="'Ok'"
                            :tagPlaceholder="trans('Selectionner une question')" :taggable="true"
                            @input="setQuestionList"
                            group-label="surveySlug" group-values="questions" label="label"
                            placeholder="Selectionner une question"
                            select-label="Ajouter" tag-placeholder="Ajouter" track-by="uuid"
                            v-model="parsedDefaultValue">
            </VueMultiselect>
          </div>
        </div>

        <template v-if="question.type && question.type.code && !question.type.code.match('OBJECT')">
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'placeholder'" class="form-label">{{ trans('Placeholder') }}</label>
            <b-form-input :id="'placeholder'" :state="helper.empty(question.placeholder)" type="text"
                          v-model="question.placeholder"></b-form-input>
          </div>


          <div class="col-auto my-2 flex-grow-1" v-if="question.type.code === 'FILE_UPLOAD'">
            <label :for="'defaultValue'" class="required form-label">{{
                trans('Type de fichier acceptés')
              }}</label>
            <div>
              <b-form-input :state="helper.empty(question.extensionAccepted)" class="form-control"
                            placeholder=".jpg,.png,.gif,.word,.xsl" required type="text"
                            v-model="question.extensionAccepted"></b-form-input>
            </div>
          </div>

          <div class="col-auto my-2 flex-grow-1" v-if="question.type && question.type.code.match(/PACK_CATEGORY/)">
            <label :for="'defaultValue'" class="required form-label">{{ trans('Default_value') }}</label>
            <b-input-group>
              <b-form-select :state="!helper.empty(question.defaultValue)" class="form-control  form-select"
                             v-model="question.defaultValue">
                <b-form-select-option :value="null">{{ trans('Selectionnez une catégorie') }}
                </b-form-select-option>
                <b-form-select-option :key="cat.uuid" :value="cat.slug" v-for="cat in packCategories">
                  {{ cat.label }}
                </b-form-select-option>
              </b-form-select>
            </b-input-group>
          </div>
          <div class="col-auto my-2 flex-grow-1"
               v-if="question.type && question.type.code && question.type.code.match(/RADIO/)">
            <label :for="'type'" class="form-label">{{ trans('Valeur par defaut') }}</label>
            <div class="d-flex">
              <b-input-group>
                <b-form-select :state="!helper.empty(question.defaultValue)" class="form-control  form-select"
                               v-model="question.defaultValue">
                  <b-form-select-option :value="null">{{ trans('Selectionnez un choix') }}
                  </b-form-select-option>
                  <b-form-select-option :key="key" :value="choice.code ? choice.code : choice.label"
                                        v-for="choice, key in question.choices">
                    {{ trans(choice.label) }}
                  </b-form-select-option>
                </b-form-select>
              </b-input-group>
            </div>
          </div>

          <div>
            <div class="d-flex flex-wrap" v-if="question.type && question.type.code === 'BOOLEAN'">
              <div class="col-auto my-2 flex-grow-1">
                <label :for="'filter'" class="required form-label">{{ trans('Valeur par defaut') }}</label>
                <b-input-group>
                  <b-form-select :state="!helper.empty(question.defaultValue)" class="form-control form-select"
                                 v-model="question.defaultValue">
                    <b-form-select-option :key="false" :value="'false'">
                      {{ trans('Non') }}
                    </b-form-select-option>
                    <b-form-select-option :key="true" :value="'true'">
                      {{ trans('Oui') }}
                    </b-form-select-option>
                  </b-form-select>
                </b-input-group>
              </div>
            </div>
          </div>
          <div class="col-auto my-2 flex-grow-1"
               v-if="question.type && !question.type.code.match(/QUESTION_LIST|RADIO|BOOLEAN|PACK_CATEGORY/)">
            <label :for="'defaultValue'" class="required form-label">{{ trans('Default_value') }}</label>
            <b-form-input :id="'defaultValue'" :state="!helper.empty(question.defaultValue)" type="text"
                          v-model="question.defaultValue"></b-form-input>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'regex'" class="form-label">{{ trans('Regex') }}</label>
            <b-form-input :id="'regex'" :state="!helper.empty(question.regex)" type="text"
                          v-model="question.regex"></b-form-input>
          </div>

          <div class="col-auto my-2 flex-grow-1">
            <label :for="'filter'" class="required form-label">{{ trans('Filter') }}</label>

            <b-form-select :state="question.filter !== null && question.filter !== undefined"
                           class="form-control form-select" v-model="question.filter">
              <b-form-select-option :value="null">{{ trans('Select_a_question') }}
              </b-form-select-option>
              <b-form-select-option :key="type.uuid" :value="type" v-for="type, key in questionTypes">
                {{ trans(type.label) }}
              </b-form-select-option>
            </b-form-select>
            <!--                                <b-form-input :id="'category'" :state="branch.label.length > 0"-->
            <!--                                              v-model="branch.category"></b-form-input>-->
          </div>

          <div class="col-auto my-2 flex-grow-1">
            <div class="d-flex justify-content-between align-items-center">
              <label :for="'prefix'" class="form-label">{{ trans('Prefix') }}</label>
              <div class="d-flex fw-bolder">
                <div :class="{ 'text-primary': prefixType === 'text' }" @click="prefixType = 'text'"
                     class="px-2 cursor-pointer">
                  Texte
                </div>
                <div :class="{ 'text-primary': prefixType === 'icon' }" @click="prefixType = 'icon'"
                     class="px-2 cursor-pointer">
                  Icône
                </div>
              </div>
            </div>
            <IconSelector v-if="prefixType === 'icon'" v-model="question.prefix"></IconSelector>
            <b-form-input :id="'prefix'" :state="!helper.empty(question.prefix)" type="text"
                          v-if="prefixType === 'text'"
                          v-model="question.prefix"></b-form-input>
          </div>

          <div class="col-auto my-2 flex-grow-1">
            <label :for="'suffix'" class="form-label">{{ trans('Suffix') }}</label>
            <b-form-input :id="'suffix'" :state="!helper.empty(question.suffix)" type="text"
                          v-model="question.suffix"></b-form-input>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'regex'" class="form-label">{{ trans('Required') }}</label>
            <div class="form-check form-switch form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" v-model="question.required" value=""/>
              <!--                <label class="form-check-label" for="flexSwitchDefault">-->
              <!--                </label>-->
            </div>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'regex'" class="form-label">{{ trans('Hidden') }}</label>
            <div class="form-check form-switch form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" v-model="question.hidden" value=""/>
              <!--                <label class="form-check-label" for="flexSwitchDefault">-->
              <!--                </label>-->
            </div>
          </div>
          <div class="col-auto my-2 flex-grow-1">
            <label :for="'regex'" class="form-label">{{ trans('Share with child surveys') }}</label>
            <div class="form-check form-switch form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" v-model="question.shared" value=""/>
              <!--                <label class="form-check-label" for="flexSwitchDefault">-->
              <!--                </label>-->
            </div>
          </div>
        </template>

        <template v-if="question.type && question.type.code.match(/DATE/)">
          <div class="col-auto m-2 flex-grow-1">
            <label :for="'value'" class="form-label">{{ trans('Min') }}</label>
            <div>
              <select class="form-control form-select" v-model="question.min">
                <option value="null">Selectionnez une option</option>
                <option :value="date.code_int" v-for="date in dates">
                  {{ date.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-auto m-2 flex-grow-1">
            <div>
              <label :for="'value'" class="form-label">{{ trans('Max') }}</label>
            </div>
            <select class="form-control form-select" v-model="question.max">
              <option value="null">Selectionnez une option</option>
              <option :value="date.code_int" v-for="date in dates">
                {{ date.name }}
              </option>
            </select>
          </div>
        </template>

        <template v-if="question.type && question.type.code && question.type.code.match(/OBJECT_GROUP|OBJECT/)">
          <div class="title w-100">{{ trans('Formulaires') }} :</div>

          <div class="d-flex flex-wrap">
            <div class="col-auto my-2 flex-grow-1">
              <label :for="'filter'" class="required form-label">{{ trans('Formulaires') }}</label>
              <b-input-group>
                <b-form-select :state="!helper.empty(question.defaultValue)" class="form-control form-select"
                               v-model="question.defaultValue">
                  <b-form-select-option :value="null">{{ trans('Selectionnez_un_formulaire') }}
                  </b-form-select-option>
                  <b-form-select-option :key="survey.uuid" :value="survey.uuid" v-for="survey, key in surveys">
                    {{ trans(survey.label) }}
                  </b-form-select-option>
                </b-form-select>
                <b-input-group-append>
                  <div class="btn btn-sm btn-icon btn-danger h-100"
                       style="border-top-left-radius: 0;border-bottom-left-radius: 0"
                       v-b-modal="'questionTypeForm-' + uKey">
                    <i class="fad fa-plus"></i>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </div>

            <template v-if="question.type && question.type.code === 'OBJECT'">

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'value'" class="form-label">{{ trans('Obligatoire') }}</label>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input :checked="question.min === 1"
                         @change="$event.target.checked ? question.min = 1 : question.min = 0" class="form-check-input"
                         type="checkbox" value=""/>
                  <!--                                    <input class="form-control" type="text" v-model="question.min"/>-->
                  <!--                <label class="form-check-label" for="flexSwitchDefault">-->
                  <!--                </label>-->
                </div>
              </div>
              <div class="col-auto m-2 flex-grow-1">
                <label :for="'regex'" class="form-label">{{ trans('Fusionner avec le formulaire parent') }}</label>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" v-model="question.isInline" value=""/>
                  <!--                <label class="form-check-label" for="flexSwitchDefault">-->
                  <!--                </label>-->
                </div>
              </div>
            </template>


            <template v-if="question.type && question.type.code && question.type.code.match(/OBJECT_GROUP/)">

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'value'" class="form-label">{{ trans('Min') }}</label>
                <div>
                  <input-number :min="-1" v-model="question.min"></input-number>
                </div>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <div>
                  <label :for="'value'" class="form-label">{{ trans('Max') }}</label>
                </div>
                <input-number :min="0" v-model="question.max"></input-number>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'regex'" class="form-label">{{ trans('Autoriser les multiplicateurs') }}</label>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" v-model="question.allowMultiplicator" value=""/>
                  <!--                <label class="form-check-label" for="flexSwitchDefault">-->
                  <!--                </label>-->
                </div>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'regex'" class="form-label">{{ trans('Fusionner avec le formulaire parent') }}</label>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" v-model="question.isInline" value=""/>
                  <!--                <label class="form-check-label" for="flexSwitchDefault">-->
                  <!--                </label>-->
                </div>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'regex'" class="form-label">{{ trans('Autoriser la diffusion par mail') }}</label>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" v-model="question.allowBroadcast" value=""/>
                  <!--                <label class="form-check-label" for="flexSwitchDefault">-->
                  <!--                </label>-->
                </div>
              </div>
            </template>
          </div>
        </template>

        <template v-if="question.type && question.type.code && question.type.code.match(/RADIO|CHECKBOX/)">
          <div class="title w-100">{{ trans('Choix') }} :</div>

          <div class="flex-grow-1">
            <draggable :animation="200" :id="'section_' + section.uuid" :list="question.choices" @end="updateChoiceRanks"
                       filter=".action-button" ghost-class="bg-light-primary" group="sections" tag="div">
              <div class="col-auto my-2 flex-grow-1" :id="+question.id+'-'+choice.id"
                   v-for="choice in question.choices" :key="choice.uuid">
                <div>
                  <div class="form-check form-switch form-check-custom form-check-solid ">
                    <label class="mb-2">
                      Lié à un formulaire
                      <input class="form-check-input h-20px w-30px"
                             type="checkbox" v-model="choice.isForm" value=""/>
                    </label>
                  </div>
                </div>
                <b-input-group>
                  <b-input-group-prepend>
                    <FileUpload :allowMultiple="false" :allowRemove="false"
                                :allowedType="'image/jpeg,image/png,image/gif,image/jfif,image/svg+xml'"
                                :icon="'fad fa-edit'"
                                :previewClass="'d-flex border-dashed w-40px cursor-pointer h-40px align-items-center justify-content-center m-auto'"
                                :text="'Photo'" fitMode="contain" :type="'full'"
                                accept=".jpg,.jpeg,.png,.gif,.jfif,.svg"
                                v-model="choice.icon"></FileUpload>
                    <!--                                    <div class=" h-100">-->
                    <!--                                    <IconSelector class="h-100" v-model="choice.icon"></IconSelector>-->
                    <!--                                    </div>-->
                  </b-input-group-prepend>
                  <select class="form-select form-select-solid" v-model="choice.code" v-if="choice.isForm">
                    <option :value="null">Selectionnez un formulaire</option>
                    <template v-for="survey in surveys">
                      <option :value="'SURVEY_'+survey.uuid" :key="survey.uuid" v-if="survey.isRoot">
                        <template v-if="survey.branch">
                          {{ survey.branch }} -
                        </template>
                        <template v-else>
                          Independant -
                        </template>
                        {{ survey.label }}
                      </option>
                    </template>
                  </select>
                  <b-form-input v-else :id="'regex'" :state="!helper.empty(choice.code)" type="text"
                                style="font-size:.8em"
                                class="form-control border-none bg-secondary px-1" v-model="choice.code"/>

                  <b-form-input :id="'regex'" :state="!helper.empty(choice.label)" type="text"
                                v-model="choice.label"></b-form-input>
                  <b-input-group-prepend>
                    <div class=" h-100">
                      <div @click="removeChoice(choice)" class="btn btn-icon btn-danger border-radius-left-0">
                        <i class="fad fa-trash"></i>
                      </div>
                      <!--                    <div class="btn btn-icon btn-secondary ">-->
                      <!--                      <i class="fad fa-list"></i>-->
                      <!--                    </div>-->
                    </div>
                  </b-input-group-prepend>
                </b-input-group>
                <b-form-textarea v-model="choice.description"></b-form-textarea>
              </div>
            </draggable>
            <div class="d-flex my-2 w-100">
              <div @click="addOption" class="btn btn-primary btn-sm">
                <i class="fad fa-plus"></i>
                {{ trans('Add_new_choice') }}
              </div>
            </div>
          </div>
        </template>

        <template v-if="question.type && question.type.code && question.type.code.match(/SURVEY_SELECTOR/)">
          <div class="title w-100">{{ trans('Choix') }} :</div>

          <div class="flex-grow-1">
            <div class="col-auto my-2 flex-grow-1" v-for="choice in question.choices">

              <b-input-group>
                <b-input-group-prepend>
                </b-input-group-prepend>
                <select class="form-select form-select-solid" v-model="choice.code">
                  <option :value="null">Selectionnez un formulaire</option>
                  <template v-for="survey in surveys">
                    <option :value="survey.uuid" :key="survey.uuid" v-if="survey.isRoot">
                      <template v-if="survey.branch">
                        {{ survey.branch }} -
                      </template>
                      <template v-else>
                        Independant -
                      </template>
                      {{ survey.label }}
                    </option>
                  </template>
                </select>
                <!--                <b-form-input :id="'regex'" :state="!helper.empty(choice.code)" type="text" style="font-size:.8em"-->
                <!--                              class="form-control border-none bg-secondary px-1" v-model="choice.code" />-->
                <b-form-input :id="'regex'" :state="!helper.empty(choice.label)" type="text"
                              v-model="choice.label"></b-form-input>
                <b-input-group-prepend>
                  <div class=" h-100">
                    <div @click="removeChoice(choice)" class="btn btn-icon btn-danger border-radius-left-0">
                      <i class="fad fa-trash"></i>
                    </div>
                  </div>
                </b-input-group-prepend>
              </b-input-group>
            </div>

            <div class="d-flex my-2 w-100">
              <div @click="addOption" class="btn btn-primary btn-sm">
                <i class="fad fa-plus"></i>
                {{ trans('Add_new_choice') }}
              </div>
            </div>
          </div>
        </template>

        <template v-if="question.type && question.type.code && question.type.code.match(/DURATION/)">
          <div class="title w-100">{{ trans('Choix') }} :</div>

          <div class="d-flex flex-wrap">
            <div class="col-auto my-2 flex-grow-1" v-for="choice in question.choices">
              <!-- {{choice.code}}.{{choice.label}} -->
              <select class="form-control form-select" v-model="choice.code" @change="updateLabel(choice)">
                <option :value="duration.code" v-for="duration in durations">{{ duration.label }}
                </option>
              </select>
            </div>

            <div class="d-flex my-2 w-100">
              <div @click="addOption" class="btn btn-primary btn-sm">
                <i class="fad fa-plus"></i>
                {{ trans('Add_new_choice') }}
              </div>
            </div>
          </div>
        </template>


        <!--        <div class="col-12">-->

        <!--          <label :for="'description'" class="required form-label">{{ trans('Description') }}</label>-->
        <!--          <b-form-textarea :id="'description'" :state="!helper.empty(question.description)" type="text"-->
        <!--            v-model="question.description"></b-form-textarea>-->
        <!--        -->
        <!--        </div>-->
        <div class="title w-100 d-flex align-items-center justify-content-between">
          <div>
            {{ trans('Conditions d\'affichage') }}
          </div>
          <div>
            <div @click="addConditionGroup" class="btn btn-icon btn-primary btn-xs">
              <i class="fad fa-plus"></i>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap border-dashed p-5 mt-2 w-100">
          <template v-for="(group, index) in question.conditionGroups">
            <div class="d-flex justify-content-center w-100">
              <div class="badge badge-primary" v-if="index > 0">
                Ou
              </div>
            </div>
            <div class="border-dashed w-100 p-2 m-2 position-relative">
              <div @click="removeGroup(group)" class="bg-danger position-absolute px-3 cursor-pointer rounded-top"
                   style="right:0;bottom:100%">
                <i class="fad fa-times text-white"></i>
              </div>
              <div class="col-auto m-2 flex-grow-1 d-flex align-items-center" v-for="condition in group.conditions">
                <select class="form-select h-100 rounded-right-0" v-model="condition.testingQuestion">
                  <template v-for="q in survey.getQuestions()">
                    <template v-if="survey.getPosition(q) < survey.getPosition(question)">
                      <option :value="q.uuid">{{ q.label }}</option>
                    </template>
                  </template>
                </select>
                <select class="form-select h-100 rounded-0" v-model="condition.operator">
                  <option value="===">Egal</option>
                  <option value="!==">Différent</option>
                  <option value="<">Inférieur</option>
                  <option value="<=">Inférieur ou égal</option>
                  <option value=">">Supérieur</option>
                  <option value=">=">Supérieur ou égal</option>
                </select>
                <!--                        {{condition.testingQuestion}}-->
                <!--                        <template v-if="condition.testingQuestion">-->
                <!--                            {{condition.testingQuestion.type.code}}-->
                <!--                        </template>-->
                <select class="form-select h-100 rounded-left-0"
                        v-if="getQuestion(condition.testingQuestion) && getQuestion(condition.testingQuestion).type.code === 'RADIO'"
                        v-model="condition.value">
                  <option :value="choice.code" v-for="choice in getQuestion(condition.testingQuestion).choices">
                    {{ choice.label }}
                  </option>
                </select>
                <b-form-input :state="!helper.empty(condition.value)" class="h-100 rounded-left-0" type="text"
                              v-else-if="getQuestion(condition.testingQuestion) && getQuestion(condition.testingQuestion).type.format === 'TEXT'"
                              v-model="condition.value"></b-form-input>
                <select class="form-select h-100 rounded-left-0"
                        v-else-if="getQuestion(condition.testingQuestion) && getQuestion(condition.testingQuestion).type.code === 'BOOLEAN'"
                        v-model="condition.value">
                  <option :value="'true'">
                    Oui/Vrai
                  </option>
                  <option :value="'false'">
                    Non/Faux
                  </option>
                </select>
                <input class="form-control h-100 rounded-left-0" disabled type="text" v-else>
                <div>
                  <i @click="removeCondition(condition, group)"
                     class="fad fa-times text-danger ms-3 cursor-pointer"></i>
                </div>
              </div>
              <div class="w-100 d-flex justify-content-center">
                <div @click="addCondition(group)" class="badge badge-success cursor-pointer">
                  +
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="title w-100 d-flex align-items-center justify-content-between">
          <div>
            {{ trans('Suggestions') }}
          </div>
          <div>
            <div @click="addSuggestion" class="btn btn-icon btn-primary btn-xs">
              <i class="fad fa-plus"></i>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap border-dashed p-5 mt-2 w-100">
          <div :key="'tag-' + index" class="col-auto m-2" v-for="suggestion, index in question.suggestions">
            <FileUpload :allowMultiple="false" :allowRemove="false"
                        :allowedType="'image/jpeg,image/png,image/gif,image/jfif,image/svg+xml'" :fitMode="'contain'"
                        :icon="'fad fa-edit'" subFolder="suggestions"
                        :previewClass="'d-flex border-dashed w-150px object-contain cursor-pointer h-150px align-items-center mb-5 justify-content-center m-auto'"
                        :text="'Selectionnez un logo'" :type="'full'" accept=".jpg,.jpeg,.png,.gif,.jfif,.svg"
                        v-model="suggestion.icon"></FileUpload>
            <input class="form-control mb-5" type="text" v-model="suggestion.code" placeholder="Code"/>
            <b-input-group>
              <input class="form-control" type="text" v-model="suggestion.label" placeholder="Intitulé">
              <b-input-group-append>
                <div @click="removeSuggestion(suggestion)" class="btn btn-sm btn-icon btn-danger h-100"
                     style="border-top-left-radius: 0;border-bottom-left-radius: 0">
                  <i class="fad fa-trash"></i>
                </div>
              </b-input-group-append>
            </b-input-group>

          </div>
        </div>

        <div class="title w-100 d-flex justify-content-between">
          <div>
            Tags
          </div>
          <div>
            <div @click="addQuestionTag" class="btn btn-xs btn-icon btn-primary">
              <i class="fad fa-plus"></i>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap border-dashed p-5 mt-2 w-100">
          <div :key="'tag-' + index" class="col-auto m-2" v-for="tag, index in question.tags">

            <b-input-group>
              <input class="form-control" type="text" v-model="tag.label">
              <b-input-group-append>
                <div @click="removeQuestionTag(tag)" class="btn btn-sm btn-icon btn-danger h-100"
                     style="border-top-left-radius: 0;border-bottom-left-radius: 0">
                  <i class="fad fa-trash"></i>
                </div>
              </b-input-group-append>
            </b-input-group>

          </div>
        </div>

      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
        <b-button @click="save" size="sm" variant="primary">
          <i class="fad fa-save"></i>
          {{ trans('Save') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :id="'questionTypeForm-' + uKey" :ref="'questionTypeForm-' + uKey" :title="trans('Create_Question_Type')"
             centered>
      <form>
        <div class="row" v-if="type">
          <div class="col-auto m-2 flex-grow-1">
            <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
            <b-form-input :id="'label'" :state="!helper.empty(type.label)" v-model="type.label"></b-form-input>
          </div>
          <div class="col-auto m-2 flex-grow-1">
            <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
            <b-form-input :id="'code'" :state="!helper.empty(type.code)" v-model="type.code"></b-form-input>
          </div>

          <div class="col-auto m-2 flex-grow-1">
            <label :for="'class'" class=" form-label">{{ trans('Class_name') }}</label>

            <b-form-input :id="'class'" v-model="type.class"></b-form-input>
            <!--                                <b-form-input :id="'category'" :state="branch.label.length > 0"-->
            <!--                                              v-model="branch.category"></b-form-input>-->
          </div>

          <div class="d-flex justify-content-between mt-2 p-0">
            <div>
              Tags
            </div>
            <div>
              <div @click="addTag" class="btn btn-sm btn-icon btn-primary">
                <i class="fad fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap border-dashed p-1 mt-2">
            <div :key="'tag-' + index" class="col-auto m-2" v-for="tag, index in type.tags">

              <b-input-group>
                <input class="form-control" type="text" v-model="tag.label">
                <b-input-group-append>
                  <div @click="removeTag(tag)" class="btn btn-sm btn-icon btn-danger h-100"
                       style="border-top-left-radius: 0;border-bottom-left-radius: 0">
                    <i class="fad fa-trash"></i>
                  </div>
                </b-input-group-append>
              </b-input-group>

            </div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
        <b-button @click="createQuestionType" size="sm" variant="primary">
          <i class="fad fa-save"></i>
          {{ trans('Save') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Step from "@/entity/Step";
import Question from "@/entity/Question";
import QuestionType from "@/entity/QuestionType";
import QuestionChoice from "@/entity/QuestionChoice";
import {api} from "@/services/Api";
import Survey from "@/entity/Survey";
import Branch from "@/entity/Branch";
import Section from "@/entity/Section";
import Popup from "@/entity/Popup";
import InputNumber from "@/components/InputNumber.vue";
import Tag from "@/entity/Tag";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import CustomSwitch from "@/components/CustomSwitch.vue";
import IconSelector from "@/components/IconSelector.vue";
import QuestionCondition from "@/entity/QuestionCondition";
import QuestionConditionGroup from "@/entity/QuestionConditionGroup";
import {helper} from "@/services/Helper";
import FileUpload from "@/components/FileUpload.vue";
import Duration from '@/entity/Duration';
import Suggestion from '@/entity/Suggestion';
import {DATES} from '@/Utils/constant';
import PackCategory from "@/entity/PackCategory";


@Component({
  components: {FileUpload, IconSelector, CustomSwitch, VueMultiselect, InputNumber}
})
export default class QuestionForm extends Vue {
  [x: string]: any;

  @Prop() question!: Question
  @Prop() section!: Section
  @Prop() step!: Step
  @Prop() survey!: Survey
  @Prop() uKey!: string
  @Prop() questionTypes!: QuestionType[]
  dates = DATES
  branch!: Branch
  options: QuestionChoice[] = []
  visibilities = ['PUBLIC', 'MANAGER', 'ADMIN','PARTNER']
  @Prop() durations!: Duration[]
  prefixType = 'text'
  type = new QuestionType()
  isForm = false
  surveys: any[] = []
  original: any = null
  @Prop() packCategories!: PackCategory[]

  async updateChoiceRanks(evt: any) {
    // console.log(evt)
    const data = evt.clone.id.split('-')
    // console.log(data)
    const questionId = data[0]
    const choiceId = data[1]
    if (!questionId) {
      new Popup('Erreur', "Il vous faut enregistrer la question avant de pouvoir réorganiser l'ordre des choix")
      return
    }
    if (!choiceId) {
      new Popup('Erreur', "Il vous faut enregistrer ce choix avant de pouvoir réorganiser l'ordre des choix")
      return
    }
    const oldIndex = evt.oldIndex
    const newIndex = evt.newIndex
    const popup = new Popup('', this.trans('Reorganisation en cours ...'), 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1;
    this.question.choices.forEach((s: QuestionChoice) => {
      if (s) {
        ranks.push({id: s.id, ranking: i, label: s.label})
        i++;
      }
    })
    const res = await api.post(api.form, 'question/choice/update/ranks', {ranks})
    popup.hide()
  }

  async mounted() {
    this.original = new Question(JSON.parse(JSON.stringify(this.question)))
    // this.branch = this.survey.branch as Branch
    // this.loadTypes()
    // this.loadQuestionList()
    // await this.loadForms()
    this.$root.$on('bv::modal::hide', (bvEvent: any, modalId: any) => {
      const index = this.section.questions.findIndex((q: Question) => {
        return q === this.question
      })
      if (index !== -1) {
        if (helper.empty(this.question.id)) {
          this.section.questions.splice(index, 1)
        }
      }
    })
  }


  updateLabel(choice: QuestionChoice) {
    const duration = this.durations.find((d: Duration) => {
      return d.code === choice.code
    })
    choice.label = duration!.label
  }

  toggleMin() {
    if (this.question.min !== null) {
      this.question.min = null as any
    } else {
      this.question.min = 0
    }
  }

  getQuestion(uuid: string) {
    const questions: Question[] = this.survey.getQuestions()
    for (let question of questions) {
      if (question.uuid === uuid) {
        return question
      }
    }
  }

  addConditionGroup() {
    const occ = new QuestionConditionGroup()
    // occ.question = this.question
    this.question.conditionGroups.push(occ)
  }

  async removeGroup(group: QuestionConditionGroup) {
    let res = true
    if (group.id) {
      const pop = new Popup('En cours', 'Suppression du groupe de condition', 'danger', 'fad fa-trash', true)
      res = await api.get(api.form, 'question/delete/condition/group/' + group.id)
      pop.hide()

    }
    if (res) {
      const index = this.question.conditionGroups.findIndex((g: QuestionConditionGroup) => {
        return g === group
      })
      if (index !== -1) {
        this.question.conditionGroups.splice(index, 1)
      }
    }
  }

  addCondition(group: QuestionConditionGroup) {
    const occ = new QuestionCondition()
    group.conditions.push(occ)
  }

  async removeCondition(condition: QuestionCondition, group: QuestionConditionGroup) {
    let res = true
    if (condition.id) {
      const pop = new Popup('En cours', 'Suppression du groupe de condition', 'danger', 'fad fa-trash', true)
      res = await api.get(api.form, 'question/delete/condition/' + condition.id)
      pop.hide()
    }
    if (res) {
      const index = group.conditions.findIndex((c: QuestionCondition) => {
        return c === condition
      })
      if (index !== -1) {
        group.conditions.splice(index, 1)
      }
    }
  }

  toggleMax() {
    if (this.question.max !== null) {
      this.question.max = null as any
    } else {
      this.question.max = 0
    }
  }

  parsedDefaultValue: any[] = []

  loadQuestionList() {
    if (this.question.type && this.question.type.code && this.question.type.code.match('QUESTION_LIST') && this.question.defaultValue) {
      const uuids = JSON.parse(this.question.defaultValue)
      const all = this.allQuestions
      this.parsedDefaultValue = []
      all.forEach((data: any) => {
        data.questions.forEach((q: any) => {
          if (uuids.includes(q.uuid)) {
            this.parsedDefaultValue.push(q)
          }
        })
      })
    }
  }

  setQuestionList(evt: any[]) {
    const uuids: any = []
    evt.forEach((data: any) => {
      uuids.push(data.uuid)
    })
    this.question.defaultValue = JSON.stringify(uuids)
    return uuids
  }

  get allQuestions() {
    const qs: any = this.survey.getQuestions()
    const array: any[] = []
    qs.forEach((q: Question) => {
      let index = array.findIndex((t: any) => t.surveySlug === q.surveySlug)
      if (index === -1) {
        array.push({'surveySlug': q.surveySlug, label: this.survey.label, questions: []})
        index = array.length - 1
      }
      array[index].questions.push({
        uuid: q.uuid,
        label: q.label,
        survey: q.surveySlug,
        type: q.type.label,
        ranking: q.ranking
      })
    })
    console.log(array)
    return array;
  }

  // get rawAllQuestion(){
  //     return this.allQuestions[0].question
  // }

  log() {
    console.log(this.question)
  }

  async typeChanged() {

    if (this.question.type.code === 'BOOLEAN') {
      this.question.defaultValue = 'false'
    }
    // if (this.question.type && this.question.type.code === 'PACK_CATEGORY') {
    //   this.loadPackCategories()
    // }
    if (this.question.type.code === 'CHECKBOX') {
      if (this.question.type.class === 'Insurer') {
        const res = await api.get(api.core, 'insurer/list')
        // console.log(res)
        if (res && res.data && res.data.insurers) {
          this.question.choices = [];
          res.data.insurers.forEach((i: any) => {
            const occ = new QuestionChoice();
            occ.code = i.slug;
            occ.label = i.label;
            occ.icon = i.photo
            this.question.choices.push(occ)
          })
        }
      }
    }
    this.loadQuestionList()
    // this.loadSurveys()
  }


  addQuestionTag() {
    let hasEmpty = false
    this.question.tags.forEach((t: any) => {
      if (this.helper.empty(t.label)) {
        hasEmpty = true
      }
    })
    if (!hasEmpty) {
      const d: any = ''
      this.question.tags.push(new Tag({label: ''}));
    }
  }

  removeQuestionTag(item: Tag) {
    if (item.id) {
      // const res = await api.delete(api.form, 'question/type/delete/tag/' + item.id)
    }
    const index = this.question.tags.findIndex((t: Tag) => t === item)
    if (index !== -1) {
      this.question.tags.splice(index, 1)
    }
  }


  addTag() {
    let hasEmpty = false
    this.type.tags.forEach((t: any) => {
      if (this.helper.empty(t.label)) {
        hasEmpty = true
      }
    })
    if (!hasEmpty) {
      const d: any = ''
      this.type.tags.push(new Tag({label: ''}));
    }
  }
  async removeTag(item: Tag) {
    if (item.id) {
      // const res = await api.delete(api.form, 'question/type/delete/tag/' + item.id)
    }
    const index = this.type.tags.findIndex((t: Tag) => t === item)
    if (index !== -1) {
      this.type.tags.splice(index, 1)
    }
  }

  addSuggestion() {

    const d: any = ''
    this.question.suggestions.push(new Suggestion());
  }

  removeSuggestion(suggestion: Suggestion) {
    const index = this.question.suggestions.findIndex((t: Suggestion) => t === suggestion)
    if (index !== -1) {
      this.question.suggestions.splice(index, 1)
    }
  }


  setType(type: QuestionType) {
    this.type = {...type}
  }

  async createQuestionType() {
    this.$store.commit('loading')
    const res = await api.post(api.form, 'question/type/edit', {type: this.type})
    if (res && res.data && res.data.status === 'success') {
      const refs = this.$refs as any
      refs['questionTypeForm-' + this.uKey].hide();
      this.type = new QuestionType()
    }
    await this.loadTypes()
    this.$store.commit('stopLoading')
  }

  async save() {
    // console.log(this.question.tags)
    // return
    this.$store.commit('loading')
    const question: any = JSON.parse(JSON.stringify(this.question))
    question.type = {id: question.type.id, code: question.type.code} as any
    question.section = {uuid: this.section.uuid}
    delete question.survey
    delete question.response
    delete question.responses
    delete question.surveySlug
    delete question.genId
    const choices: any = [];
    this.question.choices.forEach((c: QuestionChoice) => {
      choices.push({id: c.id, label: c.label, code: c.code, icon: c.icon, description: c.description})
    })
    question.choices = choices;
    // question.choices.forEach((c: any) => console.log(c))
    // return
    const res = await api.post(api.form, 'question/edit', {question})
    if (res && res.data && res.data.status === 'success') {
      this.$store.commit('stopLoading')
      this.original = this.question
      this.hideQuestionForm()
      this.reloadSurvey()
    }

  }

  hideQuestionForm() {
    const refs = this.$refs as any
    refs['questionForm-' + this.uKey].hide();
  }

  async loadTypes() {
    this.$emit('loadTypes')
  }

  async loadForms() {
    this.$store.commit('loading')
    const res = await api.get(api.form, 'survey/list/' + this.branch.slug)
    if (res && res.data && res.data.status === 'success') {
      this.surveys = []
      res.data.surveys.forEach((survey: Survey) => {
        this.surveys.push(new Survey(survey))
      })
    }
    this.$store.commit('stopLoading')
  }

  reloadSurvey() {
    this.$emit('reloadSurvey')
  }

  @Watch('question')

  async loadSurveys() {
    if (helper.empty(this.surveys)) {
      if (this.question.type && this.question.type.code && this.question.type.code.match(/OBJECT_GROUP|OBJECT|SURVEY_SELECTOR|RADIO/)) {
        const res = await api.get(api.form, 'survey/branch/surveys/' + this.survey.branch)
        // console.log(res)
        if (res && res.data && res.data.surveys) {
          this.surveys = []
          res.data.surveys.forEach((s: any) => {
            this.surveys.push(new Survey(s))
          })
        }
      }
      this.loadQuestionList()
    }
  }

  addOption() {
    const choice = new QuestionChoice()
    let ok = true
    this.question.choices.forEach((c) => {
      if (this.helper.empty(c.label)) {
        ok = false
      }
    })
    if (ok) {
      this.question.choices.push(choice)
    } else {
      new Popup('Erreur', 'Veuillez renseigner les choix vide')
    }
  }

  removeChoice(choice: QuestionChoice) {
    const index = this.question.choices.findIndex((saved: QuestionChoice) => {
      if (choice.id) {
        return choice.id === saved.id && saved.label === choice.label
      }
      return saved.label === choice.label
    })
    if (index !== -1) {
      this.question.choices.splice(index, 1)
    }
  }
}
</script>
<style scoped></style>
