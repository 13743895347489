<template>
  <div class="d-flex align-items-center justify-content-between mw-300px">
    <div>
      <div v-if="partner">
        <div class="cursor-pointer fw-bolder ">
          {{ partner.name }}
        </div>
        <div class="text-gray-400 cursor-pointer fw-bolder">
          {{ partner.code }}
        </div>
        <!--        <div class="text-gray-400 cursor-pointer fw-bolder" v-if="!empty(partner.contact)">-->
        <!--          {{ phoneFormat(partner.contact) }}-->
        <!--        </div>-->
        <!--        <div class="text-gray-400 cursor-pointer fw-bolder" v-else>-->
        <!--          {{ partner.email }}-->
        <!--        </div>-->
      </div>
      <span v-else class="fw-bolder">
                {{ value }}
            </span>
    </div>
    <div class="cursor-pointer ms-5" @click="load">
      <i class="fad fa-sync"></i>
    </div>
  </div>
</template>

<script lang="ts">
import Partner from '@/entity/Partner';
import User from '@/entity/User';
import {api} from '@/services/Api';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {}
})
export default class PartnerView extends Vue {

  @Prop({default: null}) readonly value!: string

  partner: Partner | null = null

  beforeMount() {

  }

  async mounted() {
    const manager = await api.getPartner(this.value)
    if (manager) {
      this.partner = new Partner(manager)
    }
  }

  async loadPartner() {

  }

  async load() {
    let manager = await api.getPartner(this.value)
    if (manager) {
      this.partner = new Partner(manager)
    } else {
      await this.$store.getters.partners(true);
      manager = await api.getPartner(this.value)
      if (manager) {
        this.partner = new Partner(manager)
      }
    }
  }
}
</script>
