<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
          </div>

        </div>
      </div>
    </template>

    <template #content>
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-column">
            <div class="my-5">
              <label class="my-2" for="">{{ trans('Nom') }}</label>
              <div>
                <input type="text" class="form-control" v-model="template.name">
              </div>
            </div>
            <div class="mb-5">
              <label class="my-2" for="">{{ trans('Description') }}</label>
              <div>
              <textarea class="form-control" v-model="template.description">
                  </textarea>
              </div>
            </div>


            <div>


              <div class="my-5 d-flex justify-content-between">
                <div>
                  {{ trans('Destinataires') }}
                </div>
                <div>
                  <div class="d-flex align-items-center">
                    <div>
                      <input type="text" class="form-control" placeholder="0102030405" v-model="recipient"
                             @keyup.enter="addRecipient">
                    </div>
                    <div class=" btn btn-icon btn-primary btn-sm ms-1" @click="addRecipient">
                      <i class="fad fa-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex p-5 bg-light-dark mb-5">
                  <div v-for="(recipient, index) in template.defaultRecipients" :key="'recip_' + index"
                       class="bg-primary py-2  rounded text-inverse-primary fw-bolder d-flex align-items-center m-1">
                    <div class="ms-2">
                      {{ recipient }}
                    </div>
                    <div class="px-1 rounded  mx-2 d-flex align-items-center cursor-pointer"
                         @click="removeRecipient(index)">
                      <i class="fad fa-times text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>


              <div class="my-5">
                <label class="my-2" for="">{{ trans("Nom en tant qu'expediteur ") }}</label>
                <div>
                  <input type="text" class="form-control" v-model="template.sender">
                </div>
              </div>
              <div class="form-floating">
              <textarea v-model="template.content" ref="suggestionArea" class="form-control"
                        @keyup="analyzeSms"
                        placeholder="Ecrivez votre message" id="floatingTextarea2" style="min-height: 100px">
              </textarea>
                <div>
                  <div>
                    Longueur : {{length}}
                  </div>
                  <div>
                    Messages : {{messages}}
                  </div>
                  <div>
                    Par Message : {{perMessage}}
                  </div>
                  <div>
                    Restant : {{left}}
                  </div>
                </div>
              </div>


            </div>


            <button @click="save" style="align-self: self-end; width: auto;" class=" mt-5 btn btn-primary">
              Envoyer
            </button>


          </div>
        </div>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import SmsTemplate from "@/entity/SmsTemplate";
import Popup from "@/entity/Popup";
import CodeEditor from 'simple-code-editor';
import {api} from "@/services/Api";

@Component({
  components: {CodeEditor, Base}
})
export default class SmsTemplateForm extends Vue {

  template = new SmsTemplate()

  recipient = ''

  GSM_7_CHARSET = new Set(
      "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1BÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà"
  );

// Caractères spéciaux GSM-7 qui prennent 2 caractères
  GSM_7_DOUBLE_CHAR = new Set("^{}\\[~]|€");

  isGsm7(text: string) {
    for (let char of text) {
      if (!this.GSM_7_CHARSET.has(char) && !this.GSM_7_DOUBLE_CHAR.has(char)) {
        return false;
      }
    }
    return true;
  }

  length = 0
  messages = 0
  perMessage = 0
  left = 0

  analyzeSms() {
    const text = this.template.content
    this.length = 0;
    let encoding = this.isGsm7(text) ? "GSM-7" : "UCS-2";
    this.perMessage = encoding === "GSM-7" ? 160 : 70;
    const perSegment = encoding === "GSM-7" ? 153 : 67;

    for (let char of text) {
      this.length += this.GSM_7_DOUBLE_CHAR.has(char) ? 2 : 1;
    }

    this.messages = this.length === 0 ? 0 : this.length <= this.perMessage ? 1 : Math.ceil(this.length / perSegment);
    let remaining = this.messages === 0 ? this.perMessage : (this.messages === 1 ? this.perMessage - this.length : perSegment - (this.length % perSegment));
    this.left = remaining === perSegment ? 0 : remaining
  }

  isNumberValid(email: string) {
    // Regular expression for a basic email validation
    const numberRegex = /^\d{10}$/;

    return numberRegex.test(email);
  }

  addRecipient() {
    if (!this.isNumberValid(this.recipient)) {
      new Popup('Erreur', 'Numero invalide', 'danger');
      return
    }
    if (!this.template.defaultRecipients.includes(this.recipient)) {
      this.template.defaultRecipients.push(this.recipient)
      this.recipient = ''
    }
  }

  removeRecipient(index: number) {
    if (index >= 0 && index < this.template.defaultRecipients.length) {
      this.template.defaultRecipients.splice(index, 1)
    }
  }

  async save() {
    this.$store.commit('loading')
    const res = await api.post(api.com, 'sms/template/edit', {
      template: this.template
    })
    this.$store.commit('stopLoading')
  }

  mounted() {
    // console.log('object');
    this.load()
  }

  async load() {

    this.$store.commit('loading')
    if (this.$route.params.uuid) {
      const res = await api.get(api.com, 'sms/template/get/' + this.$route.params.uuid)
      if (res && res.data) {
        this.template = new SmsTemplate(res.data.template)
        this.analyzeSms()
      }
    }

    this.$store.commit('stopLoading')
  }

}

</script>

<style scoped>
textarea {
}
</style>
