<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <date-range-picker ref="picker" :opens="'left'" :locale-data="localData" :singleDatePicker="false"
                               :autoApply="false" :ranges="ranges" @update="load" :timePicker="false"
                               :showWeekNumbers="true"
                               :showDropdowns="true" v-model="range">
              <template v-slot:input="picker" style="min-width: 350px;">
                <template>
                  {{ readableDate(picker.startDate, 'dmY') }} ❖ {{ readableDate(picker.endDate, 'dmY') }}
                </template>
              </template>
            </date-range-picker>
            <div class="d-flex align-items-center">
              <div>
                <button class="btn btn-icon btn-sm btn-info ms-1" @click="requestPrintable"
                        v-if="search.start && search.end">
                  <i class="fad fa-print"></i>
                </button>
              </div>
              <div>
                <button class="btn btn-sm btn-primary ms-1" v-b-modal="'paymentGenerator'">
                  <i class="fad fa-plus"></i>
                  Générer un lien de paiement
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template #content>

      <div class="bg-light-primary p-5 rounded border-dashed border-primary mb-5 text-primary fs-2 fw-bolder"
           v-if="generatedLink">
        Votre lien de paiement généré : <a :href="generatedLink"> {{ generatedLink }} </a>
      </div>
      <!-- <div class="d-flex justify-content-end">
          <div class="btn btn-primary my-5" v-if="selectedUuids.length > 0" @click="printReceipts">
            <i class="fad fa-print"></i>
            Imprimer les reçus ({{ selectedUuids.length }})
          </div>
        </div> -->
      <div class="card">
        <div class="card-body ">
          <table class="table table-striped gy-3 gs-2 ">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <!-- <th>
                <div class="form-check form-check-custom  form-check-sm" @change="toggleAll">
                  <input class="form-check-input" type="checkbox">
                </div>
              </th> -->
              <th>{{ trans('N°') }}</th>
              <th>{{ trans('ID') }}</th>
              <th>{{ trans('Date') }}</th>
              <th>{{ trans('Methode') }}</th>
              <th>{{ trans('Label') }}</th>
              <th>{{ trans('Client') }}</th>
              <th>{{ trans('Initiateur') }}</th>
              <th>{{ trans('Source') }}</th>
              <th>{{ trans('Montant') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(payment, index) in payments">
              <td>
                <div class="d-flex align-items-center">
                  <RowSelector v-model="selectedUuids" :data="payment.uuid"/>
                  <div class="ms-2 fw-bolder">
                    {{ index + 1 }}
                  </div>
                </div>
              </td>
              <td>
                {{ payment.id }}
              </td>
              <td>
                <template v-if="payment.payedAt">
                  {{ readable(payment.payedAt, 'dMY Hi') }}
                </template>
                <template v-else-if="payment.createdAt">
                  {{ readable(payment.createdAt, 'dMY Hi') }}
                </template>
              </td>
              <td>
                <template v-if="payment.method">
                  {{ payment.method.label }}
                </template>
              </td>
              <td>
                <div v-if="payment.contractUuid" class="text-center">
                  Paiement de l'avenant N°{{ payment.avenantNumber }} du contrat
                  <router-link :to="{ name: 'contract-show', params: { contractUuid: payment.contractUuid } }"
                               class="fw-bolder">
                    {{ payment.contractNumber }}
                  </router-link>
                </div>
                <div v-else class="d-flex justify-content-center">
                  <div>
                    {{ payment.label }}
                  </div>
                  <div @click="linkPayment(payment)" class="btn btn-primary btn-sm">
                    <i class="fad fa-link"></i>
                    Lier à un Contrat
                  </div>


                  <b-modal :id="'modalLink_' + payment.id" size="lg" title="Lier le paiment a un contrat">
                    <div>
                      <h1>
                        Trouver le contrat
                      </h1>

                      <div class="p-1 d-flex flex-column align-items-center">
                        <input type="text"
                               placeholder="Renseignez le numero du police du contrat ou le numero du contrat"
                               v-model="fieldSearch" class="form-control my-3">
                        <button class="btn btn-primary" @click="searchContract"><i class="fad fa-search"></i> Rechercher
                        </button>
                      </div>

                      <div class="my-5">

                        <template v-for="(contract, index) in contracts">
                          <div :key="index">

                            <div v-b-toggle="'collapse_'+index" variant="primary"
                                 class="bg-secondary p-5 mb-3 d-flex justify-content-between rounded"><span
                                class="text-primary fw-bolder">Contract N {{ contract.number }} </span>
                              <span>{{ contract.avenants.length }} avenants</span></div>
                            <b-collapse :id="'collapse_'+index" class="mt-2">
                              <b-card>


                                <table class="table table-stripped gy-3 gs-3">
                                  <thead>
                                  <tr
                                      class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200"
                                  >
                                    <th>Assureur</th>
                                    <!-- <th>Création</th> -->
                                    <!-- <th>Emission</th> -->
                                    <th>Période</th>
                                    <!-- <th>Montant</th> -->
                                    <th>Reste à payer</th>
                                    <th>Statut</th>
                                    <th>Actions</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <template v-for="avenant in contract.avenants">
                                    <tr :key="'avenant_list_' + avenant.uuid">
                                      <td>
                                        <div>
                                            <span class="text-primary fw-bolder"
                                            >AV{{ avenant.numero }}</span
                                            >
                                          <span class="px-1">▪</span>
                                          <span class="fw-bolder">{{
                                              avenant.typeAvenant
                                                  ? avenant.typeAvenant.label
                                                  : avenant.type
                                            }}</span>
                                        </div>
                                        <div v-if="avenant.insurer" class="fw-bolder">
                                          {{ avenant.insurer.label }}
                                        </div>
                                        <div class="text-muted fw-bolder">
                                          {{ avenant.police }}
                                        </div>
                                      </td>
                                      <!-- <td>
                                        <div class="">
                                          <UserView v-model="avenant.createBy" />
                                          <div class="text-primary fw-bolder">
                                            {{ readableDate(avenant.createdAt, "dmy Hi") }}
                                          </div>
                                        </div>
                                      </td> -->
                                      <!-- <td>
                                        <div>
                                          <UserView v-model="avenant.emitBy" />
                                          <div class="text-primary fw-bolder">
                                            {{ readableDate(avenant.dateEmission, "dmy Hi") }}
                                          </div>
                                        </div>
                                      </td> -->
                                      <td>
                                        <div class="fw-bolder d-flex align-items-center">
                                          <div
                                              :title="readableDate(avenant.dateEffet, 'dmY')"
                                              v-b-tooltip.hover
                                              v-if="avenant.dateEffet"
                                          >
                                            {{ readableDate(avenant.dateEffet, "dmy") }}
                                          </div>
                                          <div class="mx-1 d-flex align-items-center">↔</div>
                                          <div
                                              :title="readableDate(avenant.dateEcheance, 'dmY')"
                                              v-b-tooltip.hover
                                              v-if="avenant.dateEcheance"
                                          >
                                            {{ readableDate(avenant.dateEcheance, "dmy") }}
                                          </div>
                                        </div>
                                        <div class="fw-bolder text-gray-400">
                                          {{ avenant.duree }}
                                        </div>
                                      </td>
                                      <!-- <td class="fw-bolder">
                                        {{ currency(avenant.primeTTC) }}
                                      </td> -->
                                      <td class="fw-bolder text-danger">
                                        {{ currency(avenant.balance, true) }}
                                      </td>
                                      <td>
                                          <span
                                              class="badge"
                                              :class="{
                                              'badge-warning':
                                                avenant.status ===
                                                CONSTANTS.AVENANT_STATUS.PROJET,
                                              'badge-primary':
                                                avenant.status ===
                                                CONSTANTS.AVENANT_STATUS.VALIDE,
                                              'badge-success':
                                                avenant.status ===
                                                CONSTANTS.AVENANT_STATUS.EN_COURS,
                                            }"
                                          >
                                            {{ avenant.status }}
                                          </span>
                                      </td>
                                      <td class="text-end">
                                        <!-- <a :href="coreEndpoint+ 'avenant/generate/devis/'+avenant.uuid"
                                               class="me-2 btn btn-icon btn-xs btn-warning"
                                               target="_blank">
                                                <i class="fad fa-file-download"></i>
                                            </a> -->

                                        <button @click="linkPaymentToAvenant(avenant , payment)"
                                                class="btn btn-icon btn-primary"><i class="fad fa-link"></i></button>
                                      </td>
                                    </tr>
                                  </template>
                                  </tbody>
                                </table>

                              </b-card>
                            </b-collapse>

                          </div>
                        </template>


                      </div>

                    </div>


                  </b-modal>
                </div>
              </td>
              <td>
                <router-link :to="{ name: 'customer-show', params: { uuid: payment.customerUuid } }" class="fw-bolder"
                             v-if="payment.customerUuid">
                  {{ payment.customerFullname }}
                </router-link>
                <div v-if="payment.contact" class="fw-bolder">
                  {{ phoneFormat(payment.contact) }}
                </div>
              </td>
              <td>
                <user-view v-model="payment.initiator"/>
              </td>
              <td>
                <partner-view v-model="payment.partner" v-if="payment.partner"></partner-view>
              </td>
              <td class="fw-bolder fs-2 text-end">
                {{ currency(payment.amount) }}
              </td>
              <td>
                <div class="d-flex">
                  <router-link target="_blank" :to="{ name: 'receipt-show', params: { uuid: payment.uuid } }"
                               class="btn btn-sm btn-icon m-1 btn-info">
                    <i class="fad fa-download"></i>
                  </router-link>
                </div>

              </td>
            </tr>
            <tr v-if="payments.length === 0 && count > 0">
              <td colspan="10">
                <div class="d-flex flex-column justify-content-center align-items-center p-10">
                  <div class="fw-bolder fs-2x mb-5">
                    {{ count }} paiements recensés
                  </div>
                  <div>

                    <div class="btn btn-primary" @click="load" v-if="count > 0">
                      Charger
                    </div>
                    <div class="btn btn-info ms-2" @click="requestPrintable" v-if="count > 0">
                      Imprimer
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="loading">
              <td colspan="10">
                <Loading/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <b-modal :title="trans('Générer un lien de paiement')" size="lg" centered id="paymentGenerator" ref="userModal">
          <form>
            <div>
              <div>
                <div>
                  Opérateur
                </div>
                <div>
                  <select class="form-control form-select form-control-solid form-control-lg"
                          v-model="generated.method">
                    <template v-for="method in methods">
                      <option :value="method.slug" v-if="!method.requireManagerValidation">{{ method.label }}</option>
                    </template>
                  </select>
                </div>
              </div>
              <div>
                <div>
                  Montant
                </div>
                <div>
                  <input type="number" class="form-control-lg form-control form-control-solid"
                         v-model="generated.montant">
                </div>
              </div>
              <div>
                <div>
                  Numéro de téléphone
                </div>
                <div>
                  <input type="text" class="form-control-lg form-control form-control-solid" v-model="generated.phone">
                </div>
              </div>
            </div>

          </form>
          <template #modal-footer="{ ok, cancel, hide }">
            <b-button @click="cancel()" size="sm" variant="secondary">
              <i class="fad fa-times"></i>
              {{ trans('Cancel') }}
            </b-button>
            <b-button @click="generatePayment(); hide()" size="sm" variant="primary">
              <i class="fad fa-save"></i>
              {{ trans('Save') }}
            </b-button>
          </template>
        </b-modal>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import User from "@/entity/User";
import FileUpload from "@/components/FileUpload.vue";
import RowSelector from "@/components/RowSelector.vue";
import {api} from "@/services/Api";
import PaymentTransaction from "@/entity/PaymentTransaction";
import CONSTANTS from "@/Utils/constant";
import {helper} from "@/services/Helper";
import UserView from "@/components/UserView.vue";
import Loading from "@/components/Loading.vue";
import PartnerView from "@/components/PartnerView.vue";
import DateRangePicker from 'vue2-daterange-picker';
import Popup from "@/entity/Popup";
import PaymentMethod from "@/entity/PaymentMethod";
import Swal from "sweetalert2";
import Contract from '@/entity/Contract';
import Avenant from '@/entity/Avenant';

@Component({
  components: {PartnerView, UserView, FileUpload, Base, Loading, RowSelector, DateRangePicker}
})
export default class PaymentList extends Vue {

  search = {
    start: CONSTANTS.dateConstants.first_day_current_month(),
    end: CONSTANTS.dateConstants.last_day_current_month()
  }
  range = {
    startDate: CONSTANTS.dateConstants.first_day_current_month(),
    endDate: CONSTANTS.dateConstants.last_day_current_month()
  };
  localData = {
    direction: 'ltr',
    format: 'mm/dd/yyyy',
    separator: ' - ',
    applyLabel: 'Valider',
    cancelLabel: 'Annuler',
    weekLabel: ' ',
    customRangeLabel: 'Custom Range',
    daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    firstDay: 0
  }
  ranges: any = false;
  selectedUuids: string[] = []
  payments: PaymentTransaction[] = []
  loading = false
  methods: PaymentMethod[] = []
  generated = {
    method: null,
    montant: null as any,
    phone: ''
  }

  generatedLink = null


  async generatePayment() {
    if (this.generated && this.generated.method && this.generated.montant > 0 && !helper.empty(this.generated.phone)) {
      this.$store.commit('loading')
      const res = await api.get(api.core, 'api/generate/payment/link/' + this.generated.method + '/' + this.generated.montant + '/' + this.generated.phone)
      this.$store.commit('stopLoading')
      if (res && res.data && res.data.url) {
        const response = await Swal.fire({
          title: 'Lien de paiement',
          html: 'Votre lien de paiement est le suivant : <br><div class="fw-bolder btn btn-primary text-white my-5"><a target="_blank" class="text-white" href="' + res.data.url + '">Payer ' + this.generated.montant + ' sur ' + this.generated.method + ' </a></div>' +
              '<div class="fw-bolder my-5 fs-2">' + res.data.url + '</div>',
          timerProgressBar: true,
          showCancelButton: true,
          confirmButtonText: 'Ok',
          didOpen: () => {
            // Swal.showLoading()
            // const b = Swal.getHtmlContainer().querySelector('b')
            // timerInterval = setInterval(() => {
            //     b.textContent = Swal.getTimerLeft()
            // }, 100)
          },
          willClose: () => {
            // clearInterval(timerInterval)
          }
        })
        // if (response.isConfirmed) {
        //
        // }
        // this.generatedLink = res.data.url
      }
    } else {
      const response = await Swal.fire({
        icon: 'error',
        title: 'Erreur',
        html: 'Veuillez renseigner toutes les informations',
        timer: 10000,
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        didOpen: () => {
          // Swal.showLoading()
          // const b = Swal.getHtmlContainer().querySelector('b')
          // timerInterval = setInterval(() => {
          //     b.textContent = Swal.getTimerLeft()
          // }, 100)
        },
        willClose: () => {
          // clearInterval(timerInterval)
        }
      })
    }
  }

  async linkPaymentToAvenant(avenant: Avenant, paymentTransaction: PaymentTransaction) {

    this.$store.commit('loading')
    const res = await api.get(api.core, 'payment/link/' + paymentTransaction.id + '/' + avenant.id)

    this.$store.commit('stopLoading')


  }

  loadRanges() {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);


    this.ranges = {
      "Aujourdhui": [today, today],
      'Hier': [yesterday, yesterday],
      'Ce mois': [CONSTANTS.dateConstants.first_day_current_month(), CONSTANTS.dateConstants.last_day_current_month()],
      'Le mois dernier': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      'Dernier Trimestre': [CONSTANTS.dateConstants.first_day(helper.substractMonth(today, 2)), CONSTANTS.dateConstants.last_day_current_month()],
      'Dernier Semestre': [CONSTANTS.dateConstants.first_day(helper.substractMonth(today, 5)), CONSTANTS.dateConstants.last_day_current_month()],
      "Cette année": [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
      "L'année passée": [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)],
      "2 Dernières années": [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear(), 11, 31)],
    }
    this.$forceUpdate()
  }

  printRequest: any = null
  lastId = 0

  async requestPrintable() {
    this.printRequest = helper.generateId()
    this.lastId = 0
    this.preparePrintable()
  }

  async preparePrintable() {
    this.$store.commit('loading')
    const res = await api.post(api.core, 'payment/receipts', {
      range: {
        start: this.range.startDate,
        end: this.range.endDate
      },
      printRequest: this.printRequest,
      lastId: this.lastId,
    })
    if (res && res.data) {
      if (res.data.finished === true) {
        const res = await api.post(api.core, 'payment/download/printable', {
          range: {
            start: this.range.startDate,
            end: this.range.endDate
          },
          printRequest: this.printRequest
        }, {
          responseType: "blob",
        })
        if (res) {
          const blob = new Blob([res.data], {type: res.headers['content-type'] as any});
          let url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          let a = document.createElement("a");
          var title = helper.readable(this.range.startDate) + '_au_' + helper.readable(this.range.endDate)
          a.setAttribute("download", 'Recu_du_' + helper.slugify(title));
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        this.$store.commit('stopLoading')
      } else {
        this.lastId = res.data.lastId
        await this.preparePrintable()
      }
    } else {
      this.$store.commit('stopLoading')
    }
  }

  async printReceipts() {
    if (helper.empty(this.printRequest)) {
      this.printRequest = helper.generateId()
    }
    this.$store.commit('loading')
    const res = await api.post(api.core, 'payment/receipts', {
      range: {
        start: this.range.startDate,
        end: this.range.endDate
      },
      printRequest: this.printRequest
    }, {
      responseType: "blob",
    })
    const blob = new Blob([res.data], {type: res.headers['content-type'] as any});
    let url = window.URL || window.webkitURL;
    let link = url.createObjectURL(blob);
    let a = document.createElement("a");
    var title = helper.readable(this.range.startDate) + '_au_' + helper.readable(this.range.endDate)
    a.setAttribute("download", 'Recu_du_' + helper.slugify(title));
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.$store.commit('stopLoading')

  }

  toggleAll() {
    if (this.selectedUuids.length == 0) {
      this.selectedUuids = []
      this.payments.forEach((p) => {
        this.selectedUuids.push(p.uuid!)
      })
    } else {
      this.selectedUuids = []
    }
    this.$forceUpdate()
  }

  async loadPaymentMethods() {
    const popup = new Popup('Chargement ...', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'payment/method/list')
    popup.hide()
    if (res && res.data) {
      this.methods = []
      res.data.methods.forEach((method: PaymentMethod) => {
        this.methods.push(new PaymentMethod(method))
      })
    }
  }

  async print() {

  }

  mounted() {
    this.getCount()
    this.loadRanges()
    this.loadPaymentMethods()
    this.load()
  }

  async process(payment: PaymentTransaction) {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'payment/process/' + payment.uuid)
    this.$store.commit('stopLoading')
  }


  count = 0

  async getCount() {
    this.loading = true
    const start = helper.dateToHtml(this.range.startDate)
    const end = helper.dateToHtml(this.range.endDate)
    const res = await api.post(api.core, 'payment/list/' + start + '/' + end, {lastId: this.lastId, count: true})
    this.loading = false
    if (res && res.data && res.data.payments) {
      this.count = res.data.payments
    }
  }

  async load() {
    this.payments = []
    this.loading = true
    this.lastId = 0;
    this.count = 0
    await this.partialLoad()
  }

  async partialLoad() {
    const start = helper.dateToHtml(this.range.startDate)
    const end = helper.dateToHtml(this.range.endDate)
    const res = await api.post(api.core, 'payment/list/' + start + '/' + end, {lastId: this.lastId})
    if (res && res.data) {
      res.data.payments.forEach((p: any) => {
        if (p.id < this.lastId || this.lastId === 0) {
          this.lastId = p.id
        }
        this.payments.push(new PaymentTransaction(p))
      })
      if (res.data.payments.length == 0) {
        this.loading = false
      } else {
        // this.lastId = res.data.lastId
        await this.partialLoad()
      }
    }

  }

  create() {

  }

  edit() {

  }

  save() {

  }

  linkPayment(payment: PaymentTransaction) {

    this.$bvModal.show('modalLink_' + payment.id)


  }

  fieldSearch = null

  searching = false
  contracts: Contract[] = []

  total = null
  matchCount = null

  searched: any = {
    branch: null,
    customer: null,
    insurer: null,
    partner: null,
    manager: null,
    uuid: null,
    police: null,
    status: null,
    effetMin: null,
    effetMax: null,
    expireMin: null,
    expireMax: null,
    createMin: null,
    createMax: null,
    itemType: null,
    itemProperty: null,
    propertyValue: null,
    page: 1,
    perPage: 50
  }

  async searchContract() {
    console.log('object');
    if (this.searching) {
      return
    }
    this.searching = true
    const res = await api.post(api.core, 'contract/search', {
      searched: {
        ...this.searched,
        police: this.fieldSearch,
        numberContract: this.fieldSearch
      }
    })
    if (res && res.data && res.data.contracts) {
      this.contracts = [];
      res.data.contracts.forEach((c: Contract) => {
        const occ = new Contract(c)
        this.contracts.push(occ);
      })
      this.total = res.data.total
      this.matchCount = res.data.matchCount
    }
    this.searching = false
  }


}
</script>
<style scoped></style>
