<template>
<Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div @click="resetReduction" class="btn-sm btn btn-primary ms-5"
                 v-b-modal.reductionModal>
              <i class="fad fa-plus"></i>
              {{ trans('Créer une réduction') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>

        <div class="card">
        <div class="card-body ">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Status') }}</th>
              <th>{{ trans('Label') }}</th>
              <th>{{ trans('Code') }}</th>
              <th>{{ trans('Calcul') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tr v-if="isLoading">
              <td colspan="4">
                <Loading></Loading>
              </td>
            </tr>
            <draggable :animation="200" :list="reductionModels" @end="updateRanks"
                       filter=".action-button" ghost-class="bg-light-primary" group="pdm" tag="tbody" v-else>
              <tr v-for="reduc in reductionModels">
                <td>
                  <div class="form-check form-switch form-check-custom form-check-solid ms-5">
                    <input class="form-check-input h-20px w-30px" type="checkbox" @change="toggle(reduc)" :checked="reduc.isEnable"/>
                  </div>
                </td>
                <td>{{ reduc.label}}</td>
                <td>{{ reduc.code}}</td>
                <td>
                  <router-link target="_blank" :to="{ name : 'engine-method-editor',
                                    params:{methodId: reduc.method.id,methodSlug: reduc.method.code,engineVersion:engineVersion }}"
                               class="text-primary text-decoration-underline fw-bolder" v-if="reduc.method">
                    {{ reduc.method.label }}
                  </router-link>
                </td>
                <td>
                  <div @click="setReduction(reduc)" class="btn btn-xs btn-icon btn-primary"
                       v-b-modal.reductionModal>
                    <i class="fad fa-edit"></i>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        </div>

        <b-modal :title="trans('Model de Réduction')" centered id="reductionModal" ref="reductionModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'name'" :state="!helper.empty(reduction.label)"
                            v-model="reduction.label"></b-form-input>
            </div>
            <div class="col-auto my-2 flex-grow-1">
              <label :for="'type'" class="required form-label">{{ trans('Calcul') }}</label>
              <div class="d-flex">
                <b-input-group>
                  <b-form-select :state="!helper.empty(reduction.method)"
                                 class="form-control  form-select"
                                 v-model="reduction.method">
                    <b-form-select-option :value="null">{{ trans('Select_a_type') }}
                    </b-form-select-option>
                    <b-form-select-option :key="'method_'+key" :value="method"
                                          v-for="method,key in methods">
                      {{ trans(method.label) }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-input-group-append>
                    <div class="btn btn-sm btn-icon btn-primary h-100"
                         style="border-top-left-radius: 0;border-bottom-left-radius: 0"
                         v-b-modal="'methodModal'">
                      <i class="fad fa-plus"></i>
                    </div>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>

          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editReductionModel" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :title="trans('Calcul')" centered id="methodModal" ref="methodModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label class="required form-label">{{ trans('Nom') }}</label>
              <div>
                <b-form-input :state="!helper.empty(method.label)" class="form-control" type="text"
                              v-model="method.label">
                </b-form-input>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label class="form-label">{{ trans('Description') }}</label>
              <div>
                <textarea class="form-control" v-model="method.description"></textarea>
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editMethod()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

</template>
</Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";
import Loading from "@/components/Loading.vue";
import EngineMethod from "@/entity/EngineMethod";
import ReductionModel from '@/entity/ReductionModel';


@Component({
  components: {Loading, Base}
})
export default class ReductionList extends Vue {

    reduction = new ReductionModel()
    reductionModels: ReductionModel[] = []
    isLoading = false
    engineVersion = null
    methods: EngineMethod[] = []
    method = new EngineMethod()

resetReduction() {
    this.reduction = new ReductionModel()
}
setReduction(reduction: ReductionModel) {
  if (reduction.method) {
    for (let method of this.methods) {
      if (reduction.method.id === method.id) {
        reduction.method = method
      }
    }
  }
    this.reduction = reduction
  }
async mounted() {
        await this.loadReductionModels()
        this.loadMethods()
    }
async loadReductionModels() {
    this.isLoading = true
    const popup = new Popup('Chargement ...', '', 'secondary', 'fad fa-cloud-download-alt', true)
    const res = await api.get(api.core, 'reduction/model/list/' + this.$route.params.branchSlug)
    if (res && res.data && res.data.reductionModels) {
      if (res.data.version) {
        this.engineVersion = res.data.version
      }
      this.reductionModels = []
      res.data.reductionModels.forEach((reduc: ReductionModel) => {
        const occ = new ReductionModel(reduc)
        this.reductionModels.push(occ)
      })
    }
    popup.hide()
    this.isLoading = false
  }

async editReductionModel() {
    const popup = new Popup('Chargement ...', '', 'secondary', 'fad fa-cloud-download-alt', true)
    const reduc: any = this.reduction
    const res = await api.post(api.core, 'reduction/model/edit/' + this.$route.params.branchSlug, {reductionModel: reduc});
    (this.$refs.reductionModal as any).hide()
    popup.hide()
    this.loadReductionModels()
  }
  async loadMethods() {
    const method = {id: this.method.id, label: this.method.label, description: this.method.description}
    let url = 'engine/method/list/branch/and/global/methods/' + this.$route.params.branchSlug;

    const res = await api.post(api.core, url, {method})

    if (res && res.data && res.data.methods) {
        // console.log('res.data.methods', res)
      this.methods = []
      res.data.methods.forEach((m: any) => {
        const occ = new EngineMethod(m)
        this.methods.push(occ)
      })
      res.data.globals.forEach((m: any) => {
        const occ = new EngineMethod(m)
        occ.label = 'GLOBAL ' + occ.label
        this.methods.push(occ)
        const reduc = this.reductionModels.find((reduc: ReductionModel) => {
          return reduc.method && reduc.method.id === m.id
        })
         if (reduc) {
            reduc.method = occ
         }
      })
    }
  }
  async editMethod() {
    this.$store.commit('loading')
    const method = {id: this.method.id, label: this.method.label, description: this.method.description}
    let url = 'engine/method/edit';

    const res = await api.post(api.core, url, {method})
    if (this.$refs.methodModal) {
      (this.$refs.methodModal as any).hide()
    }
    this.$store.commit('stopLoading')
    this.loadReductionModels()
    this.loadMethods()
  }

  async updateRanks() {
    const popup = new Popup('', 'Reorganisation en cours ...', 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1
    this.reductionModels.forEach((r: ReductionModel) => {
      ranks.push({id: r.id, ranking: i})
      i++
    })
    const res = await api.post(api.core, 'reduction/model/update/ranks', {ranks})
    this.loadReductionModels()
    popup.hide()
  }
  async toggle(r: ReductionModel) {
    this.$store.commit('loading')
    await api.get(api.core, 'reduction/model/toggle/' + r.id)
    this.$store.commit('stopLoading')
    this.loadReductionModels()
  }
}

</script>
