<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                  <i class="fad fa-home"></i>
                  {{ trans('Survey_list') }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>


        <div class="d-flex align-items-center py-1">
          <div class="btn btn-primary btn-sm">
            Créer un client
          </div>

          <div class="btn btn-primary btn-sm ms-5" @click="printCustomers" v-if="hasRole('ROLE_SUPER_ADMIN')">
            Imprimer
          </div>
        </div>

      </div>
    </div>
  </template>
  <template v-slot:content>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-auto flex-grow-1">
            <label class="fw-bolder mb-2" for="">
              Rechercher
            </label>
            <div>
              <input class="form-control" placeholder="Rechercher" type="text" v-model="searched.value">
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-10">
          <div @click="resetSearch" class="btn btn-danger me-5 d-flex align-items-center">
            <i class="fad fa-trash"></i>
            Réinitialiser
          </div>
          <button :disabled="searching" @click="search" class="btn btn-primary d-flex align-items-center">
            <i class="fad fa-search"></i>
            Rechercher
          </button>
        </div>
      </div>
    </div>

    <b-tabs pills class="mt-10">
      <b-tab @click=" search('customer')" title="Clients">

        <div class="card mt-5">
          <div class="card-body">
            <table class="table table-striped gy-3 gs-3">
              <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>{{ trans('Client') }}</th>
                  <th>
                    {{ trans('Contact') }}
                  </th>
                  <th class="text-center">{{ trans('Gestionnaire') }}</th>
                  <th>{{ trans('Apporteur') }}</th>
                  <th>{{ trans('Actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="searching">
                  <td colspan="5">
                    <Loading></Loading>
                  </td>
                </tr>
                <template v-else>
                  <tr v-for="customer in customers">
                    <td>
                      <div>
                        {{ customer.id }} ▪️ {{ customer.identifier }}
                      </div>
                      <div>
                        {{ customer.email }}
                      </div>
                    </td>
                    <td class="text-end">
                      {{ phoneFormat(customer.fixe) }}
                      <br>
                      {{ phoneFormat(customer.mobile) }}
                    </td>
                    <td>

                    </td>
                    <td>

                    </td>
                    <td>
                      <div class="d-flex">
                        <router-link :to="{ name: 'customer-show', params: { uuid: customer.uuid } }"
                          class="btn btn-xs btn-primary btn-icon">
                          <i class="fad fa-eye"></i>
                        </router-link>
                      </div>

                    </td>
                  </tr>
                </template>

              </tbody>
            </table>
          </div>
        </div>

        <div class="my-20">
          <Pagination :count="total" :itemLength="customers.length" :matchCount="matchCount" :perPage="searched.perPage"
            :showEmpty="true" @change="search" v-model="searched.page"></Pagination>
        </div>

      </b-tab>
      <b-tab @click=" search('user')" title="Utilisateurs WiCARE">

        <div class="card mt-5">
          <div class="card-body">
            <table class="table table-striped gy-7 gs-7">
              <thead>
                <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>{{ trans('Utilisateur') }}</th>
                  <!-- <th>{{ trans('Tenants') }}</th> -->
                  <!-- <th>{{ trans('Status') }}</th> -->
                  <!-- <th>{{ trans('Accès') }}</th> -->
                  <th>{{ trans('Actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="user in users">
                  <tr :key="user.id">
                    <td>
                      <div class="d-flex">
                        <div>
                          <img :src="uploadRoot + user.photo" alt=""
                            class="bg-secondary w-40px h-40px rounded object-cover" v-if="user.photo">
                          <i class="fad fa-user fs-3x" v-else></i>
                        </div>
                        <div class="ms-3">

                          <div class=" fw-bolder">{{ user.name + ' ' + user.prename }}</div>
                          <div class="text-gray-400">{{ user.phone }}</div>

                        </div>
                      </div>

                    </td>
                    <!-- <td>
                                    <template v-for="tenant in user.tenants">
                                        <div class="badge badge-light-primary">
                                            {{ tenant.label }}
                                        </div>
                                    </template>
          </td>
          <td>
            <i class="fad fa-ban text-danger fs-3x" v-if="user.blocked"></i>
            <i class="fad fa-thumbs-up text-success fs-3x" v-else></i>
          </td>
          <td></td> -->
                    <td>
                      <!-- <div class="btn btn-icon btn-xs btn-warning" @click="confirmReset(user)"
                                        v-if="hasRole('ROLE_ADMIN')">
                                        <i class="fad fa-lock-alt"></i>
                                    </div> -->
                      <div @click="editUser(user)" class="btn btn-xs btn-icon btn-primary ms-2" v-b-modal="user.id">
                        <i class="fad fa-edit"></i>
                      </div>


                      <b-modal :title="trans('Editer un utilisateur WiCARE')" size="lg" centered :id="user.id"
                        ref="userModal">
                        <form>
                          <div class="d-flex" v-if="user">
                            <div class="col-auto m-2 flex-grow-1 float-start">
                              <FileUpload :allowMultiple="false" :allowRemove="false"
                                :allowedType="'image/jpeg,image/png,image/gif,image/jfif'" :fitMode="'cover'"
                                :icon="'fad fa-edit'"
                                :previewClass="'d-flex border-dashed w-150px object-contain cursor-pointer h-150px align-items-center justify-content-center m-auto'"
                                :text="'Selectionnez une photo'" :type="'full'" accept=".jpg,.jpeg,.png,.gif,.jfif"
                                v-model="user.photo"></FileUpload>
                            </div>
                            <div class="d-flex flex-wrap">

                              <div class="col-auto m-2 flex-grow-1">
                                <label :for="'label'" class="required form-label">{{ trans('Noms') }}</label>
                                <b-form-input :id="'label'" :state="!helper.empty(user.name)"
                                  v-model="user.name"></b-form-input>
                              </div>
                              <div class="col-auto m-2 flex-grow-1">
                                <label :for="'code'" class="required form-label">{{ trans('Prenoms') }}</label>
                                <b-form-input :id="'code'" :state="!helper.empty(user.prename)"
                                  v-model="user.prename"></b-form-input>
                              </div>
                              <div class="col-auto m-2 flex-grow-1">
                                <label :for="'label'" class="required form-label">{{ trans('Email') }}</label>
                                <b-form-input :id="'label'" :state="!helper.empty(user.email)"
                                  :disabled="!helper.empty(user.id)" v-model="user.email"></b-form-input>
                              </div>
                              <div class="col-auto m-2 flex-grow-1">
                                <label :for="'label'" class="required form-label">{{ trans('Téléphone') }}</label>
                                <b-form-input :id="'label'" :state="!helper.empty(user.phone)"
                                  :disabled="!helper.empty(user.id)" v-model="user.phone"></b-form-input>
                              </div>
                            </div>
                          </div>
                       

                          

<b-tabs active-nav-item-class="" active-tab-class="" nav-wrapper-class="bg-white px-10 mb-10 rounded-bottom nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5
                                          fw-bolder flex-nowrap flex-grow-1">
    <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" v-for="tenant in tenants"
        :key="tenant.id">
        <template #title>
            <div class="d-flex align-items-center ">
                <!-- <i class="fad fa-users fs-2 me-2"></i> -->
                {{ tenant.label }}
            </div>
        </template>
        <template #default>
            <div class="card">
                <div class="card-body">

                    <!-- <template v-if="userHasRole(user, 'ROLE_' + tenant.code.toUpperCase())"> -->

                        <template v-for="roleItem in roles">
                            <!-- <span>inin</span> -->
                            <RoleLine :role="roleItem" @toggle="toggleRole" :tenant="tenant"
                                :key="roleItem.id" :user="user">
                            </RoleLine>
                        </template>

                        <!-- <template v-for="role in specialRoles">
                            <RoleLine :role="role" @toggle="toggleRole" :tenant="tenant" :key="role.id"
                                :user="user">
                            </RoleLine>
                        </template>

                        <div class="d-flex justify-content-center m-20">
                            <div class="btn btn-danger" @click="disallowTenant(tenant)">
                                Retirer l'accès au tenant {{ tenant.label }}
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="d-flex justify-content-center p-20 bg-light-primary rounded m-20">
                            <div class="btn btn-primary" @click="allowTenant(tenant)">
                                Autorisé l'accès au tenant {{ tenant.label }}
                            </div>
                        </div>
                    </template> -->

                </div>
            </div>

        </template>
    </b-tab>
</b-tabs>
                        </form>
                        <template #modal-footer="{ ok, cancel, hide }">
                          <b-button @click="cancel()" size="sm" variant="secondary">
                            <i class="fad fa-times"></i>
                            {{ trans('Cancel') }}
                          </b-button>
                          <b-button @click="saveUser(); hide()" size="sm" variant="primary">
                            <i class="fad fa-save"></i>
                            {{ trans('Save') }}
                          </b-button>
                        </template>
                      </b-modal>


                      <!-- <router-link :to="{ name: 'user-edit', params: { id: user.id } }"
                                        class="ms-2 btn btn-info btn-icon btn-xs">
                                        <i class="fad fa-shield"></i>
                                    </router-link> -->
                      <!-- <div @click="unblockUser(user)" class="btn btn-xs btn-icon btn-success ms-2"
                                        v-if="user.blocked">
                                        <i class="fad fa-shield-check"></i>
                                    </div>
                                    <div @click="blockUser(user)" class="btn btn-xs btn-icon btn-danger ms-2" v-else>
                                        <i class="fad fa-ban"></i>
                                    </div> -->
                    </td>
                  </tr>
                </template>

              </tbody>
            </table>
          </div>
        </div>


      </b-tab>
    </b-tabs>


  </template>
  </Base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import Pagination from "@/components/Pagination.vue";
import Customer from '@/entity/Customer';
import UserView from "@/components/UserView.vue";
import Loading from "@/components/Loading.vue";
import { AxiosHeaders } from "axios";
import User from '@/entity/User';
import FileUpload from '@/components/FileUpload.vue';
import Role from '@/entity/Role';
import Tenant from '@/entity/Tenant'; 
import CustomerSelfcare from '@/entity/CustomerSelfcare';
@Component({
  components: { Base, Pagination, UserView, Loading , FileUpload }
})
export default class CustomerList extends Vue {

  searching = false
  uploadRoot = api.uploadRoot

  searched = {
    page: 1,
    value: '',
    perPage: 50
  }
  matchCount = 0;
  total = 0
  customers: Customer[] = []

  users: CustomerSelfcare[] = []
  user = new CustomerSelfcare()

    tenants: Tenant[] = []
    hierarchy: Role = new Role()
    roles: Role[] = []

  async mounted() {
    this.search("customer")

    const res = await Promise.all([
            this.loadRoles(),
            this.loadTenants()
        ])
  }

  resetSearch() {
    this.searched = {
      page: 1,
      value: '',
      perPage: 50
    }
  } 


  async saveUser() {
        const res = await api.post(api.auth, 'customer/edit', { user: this.user })
        this.user = new CustomerSelfcare()
        this.search('customer')
    }


  async printCustomers() {
    const res = await api.get(api.core, 'customer/print', { responseType: 'blob' })
    if (res && res.data) {
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      let url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      let a = document.createElement('a');
      a.setAttribute("download", res.headers['name']);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    }
  }

  typeCustomer = 'customer'

  async search(type?: string) {
    this.searching = true
    if (type === 'customer') {
      const res = await api.post(api.core, 'customer/list', { 'search': this.searched })
      if (res && res.data && res.data.customers) {
        this.customers = []
        res.data.customers.forEach((c: any) => {
          const occ = new Customer(c)
          this.customers.push(occ)
        })
      }
      this.searching = false
    } else {
      const res = await api.post(api.auth, 'customer/list', { 'search': this.searched })
      if (res && res.data && res.data.customers) {
        this.users = []
        res.data.customers.forEach((u: any) => {
          const occ = new CustomerSelfcare(u)
          this.users.push(occ)
        })
      }

      console.log(this.users, 'users')
    }
  }



  async loadRoles() {
        const res = await api.get(api.auth, 'role/list')
        if (res && res.data && res.data.role) {
            this.roles = []
            const occ = new Role(res.data.role)
            this.roles.push(occ)

        }

        // console.log(this.roles);
    }

    async loadTenants() {
        const res = await api.get(api.auth, 'tenant/list')
        if (res && res.data && res.data.tenants) {
            this.tenants = []
            res.data.tenants.forEach((t: any) => {
                const occ = new Tenant(t)
                this.tenants.push(occ)
            })
        }
    }


    async allowTenant(tenant: Tenant) {
        this.$store.commit('loading')
        const res = await api.get(api.auth, 'user/' + this.user.id + '/allow/tenant/' + tenant.code)
        if (res && res.data && res.data.user) {
            this.user = new CustomerSelfcare(res.data.user)
        }
        this.$store.commit('stopLoading')
    }

    async disallowTenant(tenant: Tenant) {
        this.$store.commit('loading')
        const res = await api.get(api.auth, 'user/' + this.user.id + '/disallow/tenant/' + tenant.code)
        if (res && res.data && res.data.user) {
            this.user = new CustomerSelfcare(res.data.user)
        }
        this.$store.commit('stopLoading')
    }


    toggleRole(data: any) {
        const role: any = data.role
        const tenant: Tenant = data.tenant
        // console.log(data)
        let code = role.code.startsWith('ROLE_') ? role.code : 'ROLE_' + role.code
        if (tenant) {
            code += '_' + tenant.code.toUpperCase()
        }
        const action = this.user.roles.includes(code) ? 'remove' : 'append'
        const list = role.getlist()

        if (role instanceof Role) {
            list.forEach((r: string) => {
                if (tenant) {
                    r += '_' + tenant.code.toUpperCase()
                }
                // console.log(r)
                const index = this.user.roles.indexOf(r)
                if (index !== -1) {
                    this.user.roles.splice(index, 1)
                }
            })
        }

        if (action === 'remove') {
            // list.forEach((r: string) => {
            const index = this.user.roles.indexOf(code)
            if (index !== -1) {
                this.user.roles.splice(index, 1)
            }
            // })
        } else {
            this.user.roles.push(code)
            list.forEach((r: string) => {
                if (tenant) {
                    r += '_' + tenant.code.toUpperCase()
                }
                const index = this.user.roles.indexOf(r)
                if (index === -1) {
                    this.user.roles.push(r)
                }
            })
        }
    }


    editUser(user: CustomerSelfcare) {
        this.user = user
    }
}
</script>
<style scoped></style>
