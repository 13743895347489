import fr from "../assets/lang/fr.json";
import en from "../assets/lang/en.json";
import {api} from "@/services/Api";
import store from "@/store";
import {helper} from "@/services/Helper";
import {auth} from "@/services/Auth";


class Translator {
    dico: any = {};
    public unknows: string[] = []
    public to: any = null
    public loaded = false
    public delay = 2000
    public source = 'admin'
    public subFolder = 'wia/frontend/admin/src/assets/lang/'

    constructor() {
        this.dico = {fr, en}
        // this.init();
    }

    async init() {
        const res = await api.get(api.core, 'shared/trans/admin/' + store.state.lang)
        if(res && res.data && res.data.dico){
            // console.log(res.data.dico)
            this.dico[store.state.lang] = JSON.parse(res.data.dico)
        }
    }

    translate(code: string) {
        if (!code || helper.empty(code)) {
            return '';
        }

        const result = code.replace(/_/g, ' ');
        if(this.dico.hasOwnProperty(store.state.lang)){
            const dico = this.dico[store.state.lang]
            if (!Object.prototype.hasOwnProperty.call(dico, code)) {
                Object.assign(dico, {[code]: result});
                clearTimeout(this.to)
                this.to = null
                // this.unknows.push(code)
                // if (this.loaded) {
                //     this.to = setTimeout(() => {
                //         this.saveUnknow()
                //     }, this.delay)
                // }
                return result;
            } else {
                return dico[code];
            }
        }else{
            console.log('Dictionnaire inconnu "'+store.state.lang+'"')
        }
    }

    async saveUnknow() {
        if(!auth.hasRole('ROLE_MANAGER')){
            return
        }
        clearTimeout(this.to)
        this.to = null
        // console.log(this)
        if (this.unknows.length > 0) {
            // const res = await api.post(api.core, 'shared/save/unknow/translations', {
            //     codes: this.unknows,
            //     'source': this.source,
            //     'subFolder': this.subFolder,
            //     // 'lang' : store.state.lang
            // });
            // this.unknows = []
        }
    }
}

export const trans = new Translator();
window.addEventListener('load', () => {
    // console.log('load')
    clearTimeout(trans.to)
    trans.to = null
    trans.loaded = true
    // trans.to = setTimeout(() => {
    //     trans.saveUnknow()
    // }, trans.delay)
})
