<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <Base>
    <template v-slot:content>
      <div v-if="contractNotFound" class="w-100 p-10 d-flex justify-content-center align-items-center">
        <i class="fad fa-empty-set fs-5x"></i>

        <div>
          Contrat Introuvable
        </div>
      </div>
      <template v-else>
        <div class="card mb-5 mb-xl-10">
          <div class="fw-bolder d-flex align-items-center ms-5 bg-success py-5 fs-2 text-center justify-content-center"
               v-if="contract && contract.nextContractUuid">
            <router-link class="text-white d-flex align-items-center" :to="{
          name: 'contract-show',
          params: { contractUuid: contract.nextContractUuid },
        }">
              <i class="fad fa-thumbs-up text-inherit fs-2"></i>
              <div class="mx-5">Ce contract a été renouvellé</div>
              <i class="fad fa-caret-right fs-2 text-inherit"></i>
            </router-link>
          </div>
          <div class="card-body pt-9 pb-0">
            <div class="d-flex flex-wrap flex-sm-nowrap mb-3" v-if="contract">
              <div class="me-7 mb-4">
                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  <img :src="uploadRoot + contract.subscriber.photo" alt="image" class="object-cover"
                       v-if="contract.subscriber && contract.subscriber.photo"/>
                  <img :src="uploadRoot + contract.insurer.photo" alt="image" class="object-contain shadow-xs rounded"
                       v-else-if="contract.insurer && contract.insurer.photo"/>
                  <div
                      class="w-100px h-100px h-lg-175px w-lg-160px d-flex align-items-center justify-content-center shadow-xs rounded"
                      v-else>
                    <i class="fad fa-user fs-5x"></i>
                    <!--                                    <img  alt="image" class="object-cover" style="opacity: 0">-->
                  </div>
                  <div :class="{
                'bg-success':
                  contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,
                'bg-warning':
                  contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,
                'bg-danger':
                  contract.status === CONSTANTS.CONTRACT_STATUS.TERMINATED,
                'bg-primary':
                  contract.status === CONSTANTS.CONTRACT_STATUS.SUSPENDED,
              }"
                       class="position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px">
                  </div>
                </div>
              </div>

              <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                      <div class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                        <i :class="contract.branch.icon + ' me-2 fs-2'" :style="{ color: contract.branch.color }"></i>
                        <img :src="uploadRoot + contract.insurer.photo" :alt="contract.insurer.label"
                             class="object-contain h-40px" v-if="contract.insurer && contract.insurer.photo"/>
                        <template v-else>
                          {{ contract.insurer.label }}
                        </template>
                        ▪
                        {{ contract.branch.label }} ▪ {{ contract.type }} N°
                        {{ contract.number }}
                      </div>
                    </div>
                    <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                      Police : N° {{ contract.police }}
                    </div>
                    <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                      <router-link :to="{
                    name: 'customer-show',
                    params: { uuid: contract.subscriber.uuid },
                  }" href="">
                        <i class="fad fa-user fs-2 me-5"></i>
                        {{ contract.subscriber.identifier }}
                        <span class="mx-2"> ❖ </span>
                        <span>
                      {{ phoneFormat(contract.subscriber.mobile) }}
                    </span>
                      </router-link>
                    </div>
                    <div class="my-4">
                      <div class="fw-bolder d-flex align-items-center">
                        <div :title="readableDate(contract.startAt, 'dmY')" v-b-tooltip.hover v-if="contract.startAt">
                          {{ readableDate(contract.startAt, "dmY") }}
                        </div>
                        <div class="mx-1 d-flex align-items-center">↔</div>
                        <div :title="readableDate(contract.expireAt, 'dmY')" v-b-tooltip.hover v-if="contract.expireAt">
                          {{ readableDate(contract.expireAt, "dmY") }}
                        </div>
                        <div class="ms-2">( {{ contract.duration }} )</div>
                      </div>
                    </div>

                    <div class="my-4">
                      <div class="fw-bolder d-flex align-items-center">
                        <div :title="readableDate(contract.expireAt, 'dmY')" v-b-tooltip.hover v-if="contract.expireAt">
                          Expire
                          {{ timelapse(contract.expireAt, null, true, "ymd") }}
                        </div>
                        <!--                      <a target="_blank" :href="contract.renewLink" class="ms-5">-->
                        <!--                        {{ contract.renewLink }}-->
                        <!--                      </a>-->
                        <router-link target="_blank" :to="{
                      name: 'renew-contract',
                      params: { contractUuid: contract.uuid },
                    }" class="ms-5 btn-sm btn btn-primary" v-if="empty(contract.nextContractUuid)">
                          <i class="fad fa-sync"></i>
                          Personnaliser le renouvellement
                        </router-link>
                      </div>
                    </div>

                    <!--                                    <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">-->
                    <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center" href="#">-->
                    <!--                                            <i class="fad fa-user me-2"></i>-->
                    <!--                                            Developer</a>-->
                    <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center" href="#">-->
                    <!--                                           <i class="fad fa-map-marker me-2"></i>-->
                    <!--                                            SF, Bay Area-->
                    <!--                                        </a>-->
                    <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 d-flex align-items-center" href="#">-->
                    <!--                                            <i class="fad fa-at me-2"></i>-->
                    <!--                                            max@kt.com-->
                    <!--                                        </a>-->
                    <!--                                    </div>-->
                  </div>
                  <div class="d-flex my-4 align-items-center">
                    <div class="mx-5 fw-bolder" v-if="hasRole('ROLE_MANAGER')">
                      Partenaire
                      <div>
                        <b-dropdown class="flex-grow-1 w-100" no-caret no-flip right tag="div"
                                    toggle-class="w-100 p-0 flex-grow-1" variant="link" @click="(event) => {
                        event.stopPropagation();
                      }
                        ">
                          <template v-slot:button-content>
                            <div
                                class="form-control flex-grow-1 text-start justify-content-between d-flex align-items-center w-100">
                              <template v-if="
                            empty(contract.partnerCode) &&
                            empty(contract.partner)
                          ">
                                Selectionnez un partenaire
                              </template>
                              <template v-else>
                                <div class="w-100 overflow-auto d-flex">
                                  <template v-if="!empty(contract.partnerCode)">
                                    <PartnerView :value="contract.partnerCode"/>
                                  </template>
                                  <template v-else-if="!empty(contract.partner)">
                                    <PartnerView :value="contract.partner.code"/>
                                  </template>
                                  <template v-else>
                                    {{ contract.partnerCode }}
                                  </template>
                                  <!--                                <div v-for="code in search.partners"-->
                                  <!--                                     class="badge badge-primary mx-1 d-flex align-items-center">-->
                                  <!--                                  {{ getPartnerNameByCode(code) }}-->
                                  <!--                                  <i class="fad fa-times ps-2 cursor-pointer text-white"-->
                                  <!--                                     @click="togglePartner(code)"></i>-->
                                  <!--                                </div>-->
                                </div>
                              </template>
                              <i class="fad fa-angle-down ms-5 fs-2"></i>
                            </div>
                          </template>
                          <b-dropdown-text class="min-w-md-350px p-0" tag="div">
                            <template>
                              <div class="overflow-auto border-rounded" style="max-height: 250px">
                                <div class="p-1">
                                  <input type="text" placeholder="Rechercher" v-model="partnerSearched"
                                         class="form-control"/>
                                </div>
                                <template v-for="partner of partnerUsers">
                                  <template v-if="
                                empty(partnerSearched) ||
                                (!empty(partnerSearched) &&
                                  !empty(partner.name) &&
                                  partner.name
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      partnerSearched.toLowerCase()
                                    )) ||
                                partner.code
                                  .trim()
                                  .toLowerCase()
                                  .includes(partnerSearched.toLowerCase())
                              ">
                                    <div class="bg-hover-light-primary px-3 py-2 border-top"
                                         @click="switchPartner(partner.code)">
                                      <div class="fw-bolder">
                                        {{ partner.name }}
                                      </div>
                                      <div class="fw-bolder text-muted fs-8">
                                        {{ partner.code }}
                                      </div>
                                    </div>
                                  </template>
                                </template>
                              </div>
                            </template>
                          </b-dropdown-text>
                        </b-dropdown>
                      </div>
                    </div>
                    <div class="mx-5 fw-bolder">
                      Gestionnaire
                      <br/>
                      <div>
                        <VueMultiselect :options="managers" :selectedLabel="'Ok'"
                                        :tagPlaceholder="trans('Gestionnaire')"
                                        placeholder="Gestionnaire" :close-on-select="true" select-label="+"
                                        :value="contract.manager"
                                        @input="updateManager" tag-placeholder="+" track-by="uuid">
                          <template slot="singleLabel" slot-scope="props">
                            <user-view v-model="props.option" :oneLine="true"></user-view>
                          </template>
                          <template slot="option" slot-scope="props">
                            <div>
                              <div class="fs-7">
                                {{ props.option.name }} {{ props.option.prename }}
                              </div>
                              <div class="fs-8 mt-1">
                                {{ props.option.email }}
                              </div>
                            </div>
                          </template>
                        </VueMultiselect>
                        <!--                      <user-view v-model="contract.manager"></user-view>-->
                      </div>
                    </div>
                    <div class="mx-5 fw-bolder">
                      Status
                      <div>
                        <div :class="{
                      'bg-success':
                        contract.status ===
                        CONSTANTS.CONTRACT_STATUS.ONGOING,
                      'bg-warning':
                        contract.status ===
                        CONSTANTS.CONTRACT_STATUS.QUOTATION,
                      'bg-danger':
                        contract.status ===
                        CONSTANTS.CONTRACT_STATUS.TERMINATED,
                      'bg-primary':
                        contract.status ===
                        CONSTANTS.CONTRACT_STATUS.SUSPENDED,
                    }" class="btn btn-sm btn-primary me-3">
                          {{ contract.status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap flex-stack">
                  <div class="d-flex flex-column flex-grow-1 pe-8">
                    <div class="d-flex flex-wrap align-items-center">
                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                        <i class="fad fa-coins"></i>
                      </span>
                          <div class="fs-2 fw-bolder counted">
                            {{ currency(contract.primeTTC) }}
                          </div>
                        </div>
                        <div class="fw-bold fs-6 text-gray-400">Prime TTC</div>
                      </div>

                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                        <i class="fad fa-coins"></i>
                      </span>
                          <div class="fs-2 fw-bolder counted">
                            {{ currency(contract.subscriber.account.balance) }}
                          </div>
                        </div>
                        <div class="fw-bold fs-6 text-gray-400">Solde Client</div>
                      </div>

                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                        <i class="fad fa-coins"></i>
                      </span>
                          <div class="fs-2 fw-bolder counted">
                            {{ currency(contract.subscriber.contractValue) }}
                          </div>
                        </div>
                        <div class="fw-bold fs-6 text-gray-400">
                          Valeur Client
                        </div>
                      </div>
                    </div>

                    <div></div>
                    <!--end::Stats-->
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Stats-->
              </div>
              <!--end::Info-->
            </div>
            <Loading v-else></Loading>
            <b-tabs active-nav-item-class="" active-tab-class=""
                    nav-wrapper-class="nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap    flex-grow-1"
                    v-model="tabIndex">
              <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadAvenants">
                <template #title>
                  <div class="d-flex align-items-center">
                    <i class="fad fa-layer-group fs-2 me-2"></i>
                    Avenants
                  </div>
                </template>
              </b-tab>
              <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadItems">
                <template #title>
                  <div class="d-flex align-items-center">
                    <i class="fad fa-object-group fs-2 me-2"></i>
                    Actifs
                  </div>
                </template>
              </b-tab>
              <b-tab @click="loadInvoices" :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
                <template #title>
                  <div class="d-flex align-items-center">
                    <i class="fad fa-file-contract fs-2 me-2"></i>
                    Factures
                  </div>
                </template>
              </b-tab>
              <b-tab @click="loadPayments" :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
                <template #title>
                  <div class="d-flex align-items-center">
                    <i class="fad fa-coins fs-2 me-2"></i>
                    Paiements
                  </div>
                </template>
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <div class="card" key="avenants">
          <div class="card-body">
            <Loading v-if="isLoading"></Loading>
            <template v-else-if="contract">
              <div v-if="tabIndex === 0">
                <table class="table table-stripped gy-3 gs-3">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>Assureur</th>
                    <th>Création</th>
                    <th>Emission</th>
                    <th>Période</th>
                    <th>Montant</th>
                    <th>Reste à payer</th>
                    <th>Statut</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="avenant in contract.avenants">
                    <tr :key="'avenant_list_' + avenant.uuid">
                      <td>
                        <div>
                          <span class="text-primary fw-bolder">AV{{ avenant.numero }}</span>
                          <span class="px-1">▪</span>
                          <span class="fw-bolder">{{
                              avenant.typeAvenant
                                  ? avenant.typeAvenant.label
                                  : avenant.type
                            }}</span>
                        </div>
                        <div v-if="avenant.insurer" class="fw-bolder">
                          {{ avenant.insurer.label }}
                        </div>
                        <div class="text-muted fw-bolder">
                          {{ avenant.police }}
                        </div>
                      </td>
                      <td>
                        <div class="">
                          <UserView v-model="avenant.createBy"/>
                          <div class="text-primary fw-bolder">
                            {{ readableDate(avenant.createdAt, "dmy Hi") }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <UserView v-model="avenant.emitBy"/>
                          <div class="text-primary fw-bolder">
                            {{ readableDate(avenant.dateEmission, "dmy Hi") }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="fw-bolder d-flex align-items-center">
                          <div :title="readableDate(avenant.dateEffet, 'dmY')" v-b-tooltip.hover
                               v-if="avenant.dateEffet">
                            {{ readableDate(avenant.dateEffet, "dmy") }}
                          </div>
                          <div class="mx-1 d-flex align-items-center">↔</div>
                          <div :title="readableDate(avenant.dateEcheance, 'dmY')" v-b-tooltip.hover
                               v-if="avenant.dateEcheance">
                            {{ readableDate(avenant.dateEcheance, "dmy") }}
                          </div>
                        </div>
                        <div class="fw-bolder text-gray-400">
                          {{ avenant.duree }}
                        </div>
                      </td>
                      <td class="fw-bolder">
                        {{ currency(avenant.primeTTC) }}
                      </td>
                      <td class="fw-bolder text-danger">
                        {{ currency(avenant.invoicesBalance, true) }}
                      </td>
                      <td>
                      <span class="badge" :class="{
                        'badge-warning':
                          avenant.status ===
                          CONSTANTS.AVENANT_STATUS.PROJET,
                        'badge-primary':
                          avenant.status ===
                          CONSTANTS.AVENANT_STATUS.VALIDE,
                        'badge-success':
                          avenant.status ===
                          CONSTANTS.AVENANT_STATUS.EN_COURS,
                      }">
                        {{ avenant.status }}
                      </span>
                      </td>
                      <td class="text-end">

                        <router-link v-if="
                        avenant.status ===
                        CONSTANTS.AVENANT_STATUS.PROJET &&
                        hasRole('ROLE_MANAGER') &&
                        avenant.typeAvenant
                      " :to="{
                        name: 'avenant-edit',
                        params: {
                          uuidContrat: contract.uuid,
                          uuidAvenant: avenant.uuid,
                          idTypeAvenant:
                            avenant.typeAvenant && avenant.typeAvenant.id
                              ? avenant.typeAvenant.id
                              : null,
                        },
                      }">
                          <div class="btn btn-info me-2 btn-xs btn-icon">
                            <i class="fad fa-edit"></i>
                          </div>
                        </router-link>

                        <template v-if="!isAvenantExpired(avenant)">
                          <div @click="emitAvenant(avenant)" class="me-2 btn btn-icon btn-xs btn-warning" v-if="
                        avenant.status === CONSTANTS.AVENANT_STATUS.PROJET && hasRole('ROLE_MANAGER') ">
                            <i class="fad fa-check-double"></i>
                          </div>
                          <div @click="setAvenant(avenant)" class="btn btn-primary me-2 btn-xs btn-icon"
                               v-b-modal="'avenantViewer'">
                            <i class="fad fa-file-signature"></i>
                          </div>
                          <!--                          <b-dropdown no-caret no-flip right size="sm" tag="div"-->
                          <!--                                      toggle-class="topbar-item text-decoration-none p-0"-->
                          <!--                                      v-if="hasRole('ROLE_MANAGER') || hasRole('ROLE_ADMIN') || hasRole('ROLE_PARTNER')" variant="link">-->
                          <!--                            <template v-slot:button-content>-->
                          <!--                              <button v-if="checkModeAvenant(avenant)" class="btn me-2 btn-secondary btn-xs btn-icon">-->
                          <!--                                <i class="fad fa-money-bill-simple-wave"></i>-->
                          <!--                              </button>-->
                          <!--                            </template>-->
                          <!--                            <b-dropdown-text class="min-w-md-350px p-5" tag="div">-->

                          <!--                              <h3 class="fw-bolder my-4"> {{ trans("Changer de mode de paiement") }}</h3>-->


                          <!--                              <b-tabs pills>-->
                          <!--                                <b-tab :title="'Paiement' + ' ' + (index + 1)"-->
                          <!--                                       v-for="(transaction, index) in avenant.transactions"-->
                          <!--                                       v-if="transaction.type == 'Espèce'" :key="index">-->

                          <!--                                  <div class="p-2">-->

                          <!--                                    <div>-->

                          <!--                                      <h2 class=" fs-2 text-center text-primary my-3">-->
                          <!--                                        {{ currency(transaction.amount) }}-->
                          <!--                                      </h2>-->
                          <!--                                    </div>-->
                          <!--                                    <div class="d-flex justify-content-between my-5">-->
                          <!--                                      <template v-for="(method, index) in paymentMethods">-->
                          <!--                                        <div :key="index" v-on:click="methodSelected = method" :class="{-->
                          <!--                                      selected:-->
                          <!--                                        methodSelected &&-->
                          <!--                                        method.id === methodSelected.id,-->
                          <!--                                    }"-->
                          <!--                                             class="m-1 cursor-pointer payment-method m-1 d-flex align-items-center flex-column"-->
                          <!--                                             v-if="!method.requireManagerValidation">-->
                          <!--                                          <img :src="uploadRoot + method.photo" alt=""-->
                          <!--                                               class="w-40px h-40px rounded-circle"/>-->
                          <!--                                        </div>-->
                          <!--                                      </template>-->
                          <!--                                    </div>-->

                          <!--                                    <div v-if="methodSelected" class="d-flex flex-column w-100 align-items-center">-->

                          <!--                                      <div class="my-5">-->
                          <!--                                        <div class="position-relative">-->
                          <!--                                          <input type="text" class="form-control ms-1 text-end"-->
                          <!--                                                 v-model="linkPhoneNumber"/>-->
                          <!--                                          <div class="position-absolute" style="-->
                          <!--                                          left: 10px;-->
                          <!--                                          top: 8px;-->
                          <!--                                          pointer-events: none;-->
                          <!--                                        ">-->
                          <!--                                            <i class="fad fa-phone-alt"></i>-->
                          <!--                                          </div>-->
                          <!--                                        </div>-->
                          <!--                                      </div>-->


                          <!--                                      <button class="btn btn-primary"-->
                          <!--                                              @click="repay(transaction, methodSelected)">Repayer-->
                          <!--                                      </button>-->
                          <!--                                    </div>-->
                          <!--                                  </div>-->
                          <!--                                </b-tab>-->
                          <!--                              </b-tabs>-->
                          <!--                            </b-dropdown-text>-->
                          <!--                          </b-dropdown>-->
                        </template>
                        <b-dropdown no-caret no-flip right size="sm" tag="div"
                                    toggle-class="topbar-item text-decoration-none p-0" v-if="
                          avenant.invoicesBalance !== 0 && hasRole('ROLE_MANAGER') || avenant.invoicesBalance < 0"
                                    variant="link">
                          <template v-slot:button-content>
                            <div @click="amount = abs(avenant.invoicesBalance)"
                                 class="btn btn-success me-2 btn-xs btn-icon">
                              <i class="fad fa-money-bill-wave"></i>
                            </div>
                          </template>
                          <b-dropdown-text class="min-w-md-350px p-5" tag="div">
                            <h4>AV{{ avenant.numero }} ▪ {{ avenant.type }}</h4>
                            <div>
                              <label class="fw-bolder mb-2" for="">{{
                                  trans("Recharger le compte client et payer")
                                }}</label>
                              <div>
                                <b-input-group>
                                  <b-form-input class="text-end" type="number" v-model="amount"></b-form-input>
                                  <b-input-group-append>
                                    <div @click="payAvenant(avenant)"
                                         class="btn btn-sm border-radius-left-0 d-flex align-items-center btn-primary h-100">
                                      <i class="fad fa-money-bill"></i>
                                      Payer
                                    </div>
                                  </b-input-group-append>
                                </b-input-group>
                              </div>
                              <div class="mt-5">
                                Si le compte client est suffisant le
                                rechargement ne sera pas effectué
                              </div>

                              <div class="fw-bolder my-2 text-center">
                                Envoyez un lien de paiement au
                              </div>
                              <div class="d-flex justify-content-center align-items-center">
                                <input type="text" class="form-control text-center ms-1 w-150px text-end"
                                       v-model="linkPhoneNumber"
                                       placeholder="Numéro de téléphone"/>
                              </div>
                              <div class="d-flex justify-content-center align-items-center">
                                <template v-for="(method, index) in paymentMethods">
                                  <div :key="index" class="m-5 cursor-pointer d-flex align-items-center"
                                       v-if="!method.requireManagerValidation"
                                       @click="sendPaymentLink(method, avenant)">
                                    <img :src="uploadRoot + method.photo" alt="" class="w-40px h-40px rounded"/>

                                  </div>
                                </template>
                                <div
                                    class="m-5 cursor-pointer d-flex align-items-center justify-content-center bg-primary w-40px h-40px rounded"
                                    @click="sendPaymentLink(null, avenant)">
                                  <i class="fad fa-list fs-2 text-inverse-primary"></i>
                                </div>
                              </div>
                            </div>
                          </b-dropdown-text>
                        </b-dropdown>

                        <template v-if="avenant.status === CONSTANTS.AVENANT_STATUS.PROJET">
                          <b-dropdown no-caret no-flip right size="sm" tag="div"
                                      toggle-class="topbar-item text-decoration-none p-0" variant="link">
                            <template v-slot:button-content>
                              <div @click="amount = abs(avenant.balance)" class="btn btn-info me-2 btn-xs btn-icon">
                                <i class="fad fa-forward"></i>
                              </div>
                            </template>
                            <b-dropdown-text class="min-w-md-350px p-5" tag="div">
                              <h4>Avancer la date d'effet</h4>
                              <div>
                                <label class="fw-bolder mb-2" for="">
                                  {{ trans("Nouvelle date d'effet") }}
                                </label>
                                <div class="d-flex align-items-center">
                                  <input class="form-control" type="date" v-model="dateEffet"/>
                                  <div @click="forwardAvenant(avenant)"
                                       class="btn btn-sm border-radius-left-0 btn-primary">
                                    Avancer
                                  </div>
                                </div>
                              </div>
                            </b-dropdown-text>
                          </b-dropdown>
                        </template>
                        <div class="btn btn-danger me-2 btn-xs btn-icon" @click="removeAvenantProject(avenant)"
                             v-if="avenant.status === CONSTANTS.AVENANT_STATUS.PROJET ||  hasRole('ROLE_ADMIN') ">
                          <i class="fad fa-ban"></i>
                        </div>
                        <router-link :to="{name: 'avenant-show',params: { uuid: avenant.uuid }}"
                                     class="btn btn-dark btn-xs btn-icon">
                          <i class="fad fa-eye"></i>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
              <div v-if="tabIndex === 1">
                <div class="py-5">
                  <div class="d-flex justify-content-end align-items-center">
                    <b-dropdown class="" no-flip right tag="div" variant="primary">
                      <template v-slot:button-content>
                        Sélectionnez une action
                      </template>
                      <b-dropdown-item tag="div" @click="subscribeRenewalCampaign">
                        <div class="px-5 py-2 cursor-pointer d-flex align-items-center">
                          <i class="fad fa-sms fs-2 me-2"></i>
                          Lancer la campagne de renouvellement
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item tag="div">
                        <router-link :to="{
                  name: 'contract-renewal',
                  params: { uuid: contract.uuid },
                }" class="text-dark">
                          <div class="px-5 py-2 cursor-pointer d-flex align-items-center">
                            <i class="fad fa-sync fs-2 me-2"></i>
                            Renouveller le contrat
                          </div>
                        </router-link>
                      </b-dropdown-item>

                      <!-- <b-dropdown-item tag="div">
                            <router-link :to="{ name: 'avenant-edit', params: { uuidContrat: contract.uuid } }" class="text-dark">
                              <div class="px-5 py-2 cursor-pointer d-flex align-items-center">
                                <i class="fad fa-edit fs-2 me-2"></i>
                                {{ trans("Créer un avenanttt") }}
                              </div>
                            </router-link>
                          </b-dropdown-item> -->

                      <template v-for="item in typesAvenant">
                        <b-dropdown-item tag="div" :key="item.id">
                          <router-link :to="{
                    name: 'avenant-edit',
                    params: {
                      uuidContrat: contract.uuid,
                      idTypeAvenant: item.id,
                    },
                  }" class="text-dark">
                            <div class="px-5 py-2 cursor-pointer d-flex align-items-center">
                              <i class="fad fa-edit fs-2 me-2"></i>
                              {{ item.label }}
                            </div>
                          </router-link>
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </div>
                </div>

                <Loading v-if="isLoading"></Loading>
                <table class="table table-striped gy-3 gs-3">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>Actif</th>
                    <th>Assuré</th>
                    <th>Pack</th>
                    <th>Ajoute le</th>
                    <th>Prime</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="item in contractItems">
                    <tr style="height: 1px" v-if="
                item.model &&
                item.model.className !== 'Customer' &&
                !item.model.isRoot &&
                (!item.parentItem ||
                  (item.parentItem && item.parentItem.expand))
              ">
                      <td class="p-0">
                        <label :class="{ 'fw-bolder': itemHasChildren(item) }"
                               class="cursor-pointer w-100 h-100 d-flex align-items-center">
                          <input @change="toggleItemExpand(item, $event)" class="hidden" type="checkbox"
                                 v-model="item.expand"/>
                          <template v-for="index in item.level">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </template>
                          <template v-if="itemHasChildren(item)">
                            <i class="fad fa-angle-right me-2" v-if="!item.expand"></i>
                            <i class="fad fa-angle-down me-2" v-else></i>
                          </template>
                          {{ item.model.name }}
                          <template v-if="!helper.empty(item.defaultAlias)">
                            {{ item.defaultAlias }}
                          </template>
                        </label>
                      </td>
                      <td>
                  <span class="fw-bolder text-uppercase">
                    <!--                                                <template v-if="item.beneficiary">-->
                    <!--                                                    {{item.beneficiary.properties}}-->
                    <!--                                                </template>-->
                    {{ item.beneficiaryIdentifier }}
                  </span>
                      </td>
                      <td>
                        {{ getItemPack(item) }}
                      </td>
                      <td>
                        {{ readableDate(item.addedAt, "dmY H:i") }}
                      </td>
                      <td class="text-end fw-bolder whitespace-no-wrap">
                        <template v-if="!helper.empty(item.premium)">
                          {{ currency(item.premium) }}
                        </template>
                        <span class="badge badge-secondary" v-else>{{
                            currency(item.calculatedPremium)
                          }}</span>
                      </td>
                      <td class="text-end">
                        <div class="d-flex justify-content-end">
                          <b-dropdown no-caret no-flip right tag="div"
                                      toggle-class="topbar-item text-decoration-none p-0 me-2" variant="link"
                                      v-if="item.model.printables.length > 0">
                            <template v-slot:button-content>
                              <div class="btn btn-xs btn-light-primary btn-icon rounded">
                                <i class="fad fa-print"></i>
                              </div>
                            </template>
                            <template #default="{ hide }">
                              <template v-for="(printable, index) in item.model
                          .printables">
                                <div :class="{ 'border-top': index > 0 }"
                                     class="d-flex justify-content-between px-3 py-2 align-items-center">
                                  <div class="whitespace-no-wrap flex-grow-1">
                                    {{ printable.label }}
                                  </div>
                                  <router-link :to="{
                              name: 'printable-view',
                              params: {
                                itemUuid: item.uuid,
                                printableUuid: printable.uuid,
                              },
                            }" class="d-flex justify-content-end ms-5" target="_blank">
                                    <div class="btn btn-xs btn-primary btn-icon mx-1">
                                      <i class="fad fa-eye"></i>
                                    </div>
                                  </router-link>
                                </div>
                              </template>
                            </template>
                          </b-dropdown>
                          <router-link :to="{
                      name: 'item-viewer',
                      params: {
                        uuid: item.uuid,
                        type: item.model.code,
                      },
                    }" class="btn btn-xs btn-icon btn-light-dark">
                            <i class="fad fa-eye"></i>
                          </router-link>
                        </div>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
              <div v-if="tabIndex === 2">
                <div class="card mb-5">
                  <div class="card-body border border-dashed border-gray-300 rounded min-w-700px">
                    <Loading v-if="isLoading"></Loading>
                    <h3>Factures</h3>

                    <table class="table table-row-dashed table-row-gray-300 align-middle table-striped g-4">
                      <thead>
                      <tr class="text-uppercase">
                        <td>
                          <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox">
                          </div>
                        </td>
                        <th>Numéro</th>
                        <th>Creér le</th>
                        <th>A payer le</th>
                        <th>Montant</th>
                        <th>Reste à payer</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="invoice in invoices">
                        <td>
                          <div class="form-check form-check-sm form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox">
                          </div>
                        </td>
                        <td>
                          {{ invoice.number }}
                        </td>
                        <td>
                          {{ datify(invoice.createdAt, 'dmY') }}
                        </td>
                        <td>
                          {{ datify(invoice.dueDate, 'dmY') }}
                        </td>
                        <td class="fw-bolder fs-2">
                          {{ currency(invoice.premium) }}
                        </td>
                        <td class="fw-bolder fs-2" :class="{'text-danger' : Math.abs(invoice.balance)>0}">
                          {{ currency(invoice.balance, true) }}
                        </td>
                        <td>
                        <span :class="{

                      'badge-secondary': invoice.status === 'PENDING',
                      'badge-light-success': invoice.status === 'PAYED',
                      'badge-light-danger': invoice.status === 'CANCELLED',

                    }" class="badge text-uppercase badge-secondary">
                      {{ invoice.status }}
                    </span>
                        </td>
                        <td>
                          <div class="btn btn-icon btn-xs btn-primary">
                            <i class="fad fa-eye"></i>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <!--                  <div class="d-flex align-items-center p-5">-->
                    <!--                    <div class="d-flex flex-aligns-center pe-10 pe-lg-20">-->

                    <!--                      <div class="d-flex align-items-center me-2">-->
                    <!--                        <i class="fad fa-file-pdf fs-3x text-primary"></i>-->
                    <!--                      </div>-->
                    <!--                      <div class="ms-1 fw-bold">-->
                    <!--                        <a class="fs-6 text-hover-primary fw-bolder" href="#">Devis</a>-->
                    <!--                        <br/>-->
                    <!--                        <span class="fs-6x fw-bolder text-gray-400">80 kb</span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="d-flex flex-aligns-center">-->
                    <!--                      <div class="d-flex align-items-center me-2">-->
                    <!--                        <i class="fad fa-file-pdf fs-3x text-primary"></i>-->
                    <!--                      </div>-->
                    <!--                      <div class="ms-1 fw-bold">-->
                    <!--                        <a class="fs-6 text-hover-primary fw-bolder" href="#">Contrat</a>-->
                    <!--                        <br/>-->
                    <!--                        <span class="fs-6x fw-bolder text-gray-400">80 kb</span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                  </div>-->

                    <div class="separator my-10"></div>
                  </div>
                </div>
              </div>
              <div v-if="tabIndex === 3">
                <Loading v-if="isLoading"></Loading>
                <div class="d-flex justify-content-end mb-10">
                  <b-dropdown no-caret no-flip right size="sm" tag="div"
                              toggle-class="topbar-item text-decoration-none p-0" variant="link">
                    <template v-slot:button-content>
                      <div class="btn btn-success btn-sm">
                        Remplacer des paiements en espèce
                      </div>
                    </template>
                    <b-dropdown-text class="min-w-md-150px p-5 text-center" tag="div">
                      <div v-if="empty(transactionIds)" class="text-center">
                        <i class="fad fa-empty-set fs-5x"></i>
                        <div class="mt-5 fw-bolder">
                          Sélectionnez des paiements à remplacer
                        </div>
                      </div>
                      <div v-else>
                        <div class="fw-bolder">
                          Remplacer {{ transactionIds.length }} Paiements
                        </div>
                        <div class="d-flex justify-content-center">
                          <div class="fs-2 fw-bolder badge badge-light-primary mt-5">
                            {{ currency(getReplacedAmount()) }}
                          </div>
                        </div>
                        <label class="fw-bolder my-5" for="">
                          {{ trans("Sélectionnez une methode") }}
                        </label>
                        <div class="d-flex">
                          <template v-for="(method, index) in paymentMethods">
                            <div :key="index" class="m-1 cursor-pointer badge" @click="setReplacementMethod(method)"
                                 v-if="!method.requireManagerValidation"
                                 :class="{'badge-light-primary' : selectedMethod && method.id === selectedMethod.id }">
                              <div>
                                <img :src="uploadRoot + method.photo" alt="" class="w-50px h-50px rounded"/>
                              </div>
                              <div class="text-center">
                                {{ method.label }}
                              </div>
                            </div>
                          </template>
                        </div>

                        <div v-if="selectedMethod && selectedMethod.requirePhoneNumber">
                          Entrez le numéro de téléphone :
                          <input type="text" class="form-control" v-model="replacementNumber">
                        </div>

                        <div class="d-flex mt-5 justify-content-center">
                          <div class="btn"
                               :class="{'btn-primary': selectedMethod, 'btn-secondary' : selectedMethod === null}"
                               @click="replaceTransactions">
                            Remplacer
                          </div>
                        </div>
                      </div>
                    </b-dropdown-text>
                  </b-dropdown>

                </div>
                <table class="table table-stripped gy-3 gs-3">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>
                      <label class="checkbox checkbox-lg checkbox-inline">
                        <input type="checkbox">
                        <span></span>
                      </label>
                    </th>
                    <th>#</th>
                    <th>Methode</th>
                    <td>Opération</td>
                    <th>Initiateur</th>
                    <th>Montant</th>
                    <th>Status</th>
                    <th>Reçu</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="transaction in transactions">
                    <td>
                      <label class="checkbox checkbox-lg checkbox-inline">
                        <input type="checkbox" @change="toggleTransaction(transaction)"
                               :checked="transactionIds.includes(transaction.id)">
                        <span></span>
                      </label>
                    </td>
                    <td>
                      <div class="fw-bolder">
                        {{ currency(transaction.id, false, false) }}
                      </div>
                    </td>
                    <td>
                      <div>
                        <img :src="uploadRoot+transaction.method.photo" class="w-30px h-30px rounded" alt="">
                        <span class="fw-bolder">
                    {{ transaction.method.label }}
                      </span>
                      </div>

                      <div class="fs-6 text-gray-400 fw-bolder">
                        {{ readableDate(transaction.createdAt) }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="ms-3 fw-bolder badge badge-light-danger" v-if="transaction.credit">
                          {{ transaction.credit.description }}
                        </span>
                        <div>
                          <i class="fad fa-arrow-right fs-2 mx-5"></i>
                        </div>
                        <div class="ms-3">
                          <div class="fw-bolder badge badge-light-success" v-if="transaction.debit">
                            {{ transaction.debit.description }}
                          </div>
                          <div>
                            <div v-for="payment in transaction.replaced" class="badge badge-secondary m-1 cursor-pointer">
                              N° {{ currency(payment.id,false,false) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span class="fw-bolder">
                        <user-view v-model="transaction.initiator"/>
                      </span>
                    </td>
                    <td>
                      <span class="fw-bolder">
                        {{ currency(transaction.amount) }}
                      </span>
                    </td>
                    <td>
                      <span class="badge"
                            :class="{
                        'badge-success' : transaction.status === 'SUCCEED',
                        'badge-secondary' : transaction.status === 'PENDING',
                        'badge-danger' : transaction.status === 'CANCELLED',
                      }">
                      {{ transaction.status }}
                        </span>
                    </td>
                    <td>

                      <div class="btn btn-icon btn-danger" @click="simulateWave(transaction)"
                           v-if="hasRole('ROLE_ADMIN') && transaction.method && transaction.method.slug === 'wave' && transaction.status === 'PENDING'">
                        <i class="fad fa-money-bill-1-wave"></i>
                      </div>
                      <div class="btn btn-icon btn-danger" @click="simulateHub2(transaction)"
                           v-if="hasRole('ROLE_ADMIN') && transaction.method &&  transaction.status === 'PENDING' &&
                           (transaction.method.slug === 'momo' || transaction.method && transaction.method.slug === 'flooz')">
                        <i class="fad fa-money-bill-1-wave"></i>
                      </div>
                      <router-link target="_blank"
                                   :to="{name: 'receipt-show',  params: { uuid: transaction.receipt.uuid }}"
                                   class="btn btn-sm btn-icon btn-primary" v-if="transaction.receipt">
                        <i class="fad fa-download"></i>
                      </router-link>
                    </td>
                  </tr>
                  <tr class="border-top">
                    <td colspan="5"></td>
                    <td>
                      <div class="fw-bolder">
                        {{ currency(transactionPayedAmount) }}
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="tabIndex === 4">4</div>
            </template>
          </div>
        </div>
        <b-modal :ref="'avenantViewer'" :title="trans('Avenant')" centered class="modal-fullscreen" id="avenantViewer"
                 scrollable size="xl">
          <div v-if="avenant">
            <div class="d-flex align-items-center">
              <h2 class="flex-grow-1">
                AV{{ avenant.numero }} ▪ {{ avenant.type }}
              </h2>
              <span :class="{
          'badge-warning':
            avenant.status === CONSTANTS.AVENANT_STATUS.PROJET,
          'badge-success':
            avenant.status === CONSTANTS.AVENANT_STATUS.VALIDE,
        }" class="badge fs-2">
          {{ avenant.status }}
        </span>
            </div>
            <div class="mt-5">
              <div class="fw-bolder d-flex align-items-center">
                <div :title="readableDate(avenant.dateEffet, 'dmY')" v-b-tooltip.hover v-if="avenant.dateEffet">
                  {{ readableDate(avenant.dateEffet, "dmy") }}
                </div>
                <div class="mx-1 d-flex align-items-center">↔</div>
                <div :title="readableDate(avenant.dateEcheance, 'dmY')" v-b-tooltip.hover v-if="avenant.dateEcheance">
                  {{ readableDate(avenant.dateEcheance, "dmy") }}
                </div>
              </div>
            </div>

            <div class="d-flex fw-bolder mt-5 align-items-center fs-6 flex-wrap justify-content-start">
              <div class="me-5 mb-5" v-for="pd in avenant.premiumDetails">
                {{ pd.model.label }} :
                <span class="badge badge-primary fs-6">{{
                    currency(pd.premium)
                  }}</span>
              </div>
            </div>
            <div class="d-flex fw-bolder align-items-center fs-6 mb-5">
              <div class="">
                Prime TTC :
                <span class="badge badge-secondary fs-6">{{
                    currency(avenant.primeTTC)
                  }}</span>
              </div>
              <div class="border-start ms-4 ps-4">
                Reste à Payer :
                <span class="badge badge-danger fs-6">
            {{ currency(avenant.balance, true) }}</span>
              </div>
            </div>
            <!--                    <div class="border-top border-bottom pt-5">-->
            <!--                        <h4>Details</h4>-->
            <!--                    </div>-->
            <Loading v-if="isLoadingAvenant"></Loading>
            <table class="table table-striped" v-else>
              <thead>
              <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                <th>Actif</th>
                <th>Pack</th>
                <!--                            <th>Garanties</th>-->
                <th>Bénéficiaire</th>
                <th class="text-end">Prime</th>
                <th class="text-end">Actions</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(item, index) in itemList">
                <tr style="height: 1px" :key="index" v-if="
              item.model.className !== 'Customer' &&
              !item.model.isRoot &&
              (!item.parentItem ||
                (item.parentItem && item.parentItem.expand))
            ">
                  <td class="p-0">
                    <label :class="{ 'fw-bolder': itemHasChildren(item) }"
                           class="cursor-pointer w-100 h-100 d-flex align-items-center">
                      <input @change="toggleItemExpand(item, $event)" class="hidden" type="checkbox"
                             v-model="item.expand"/>
                      <template v-for="index in item.level">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </template>
                      <template v-if="itemHasChildren(item)">
                        <i class="fad fa-angle-right me-2" v-if="!item.expand"></i>
                        <i class="fad fa-angle-down me-2" v-else></i>
                      </template>
                      <template class="" v-if="avenant.ins.includes(item.uuid)">
                        Incorporation
                      </template>
                      <!--                                        <template v-if="item.model.name !== item.name">-->
                      {{ item.model.name }}
                      <!--                                        </template>-->
                      <!--                                        {{item.name}}-->
                      <template v-if="!helper.empty(item.defaultAlias)">
                        {{ item.defaultAlias }}
                      </template>

                      <span class="bg-light-success ms-4" v-if="avenant.ins.includes(item.uuid)">
                    <i class="fad fa-plus-circle text-success"></i>
                  </span>
                    </label>
                  </td>
                  <td>
                    {{ getItemPack(item) }}
                  </td>
                  <!--                                <td>-->
                  <!--                                    &lt;!&ndash;                                    <div class="d-flex flex-wrap">&ndash;&gt;-->
                  <!--                                    &lt;!&ndash;                                        <div class="btn btn-xs btn-icon m-1 btn-success" v-for="guarantee in item.guarantees" :title="guarantee.label" v-b-tooltip.hover>&ndash;&gt;-->
                  <!--                                    &lt;!&ndash;                                            <img :src="uploadRoot+guarantee.icon" alt="" class="mw-15px mh-15px">&ndash;&gt;-->
                  <!--                                    &lt;!&ndash;&lt;!&ndash;                                            {{guarantee.label}}&ndash;&gt;&ndash;&gt;-->
                  <!--                                    &lt;!&ndash;                                        </div>&ndash;&gt;-->
                  <!--                                    &lt;!&ndash;                                    </div>&ndash;&gt;-->
                  <!--                                </td>-->
                  <td>
                    &nbsp;
                    <span class="fw-bolder text-uppercase">
                  {{ item.beneficiaryIdentifier }}
                </span>
                  </td>
                  <td class="text-end fw-bolder whitespace-no-wrap">
                    <template v-if="!helper.empty(item.premium)">
                      {{ currency(item.premium) }}
                    </template>
                    <span class="badge badge-secondary" v-else>{{
                        currency(item.calculatedPremium)
                      }}</span>
                  </td>
                  <td class="text-end">
                    <div class="d-flex justify-content-end">
                      <div class="btn btn-xs btn-icon btn-primary me-2">
                        <i class="fad fa-edit"></i>
                      </div>

                      <div class="btn btn-xs btn-icon btn-danger me-2">
                        <i class="fad fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>

            <div class="d-flex align-items-center p-5">
              <div class="d-flex flex-aligns-center pe-10 pe-lg-20">
                <div class="d-flex align-items-center me-2">
                  <i class="fad fa-file-pdf fs-3x text-primary"></i>
                </div>
                <div class="ms-1 fw-bold">
                  <a :href="coreEndpoint +
              'avenant/devis/' +
              avenant.uuid +
              '?zone=' +
              $store.state.currentTenant.code
              " class="fs-6 text-hover-primary fw-bolder" href="#" target="_blank">Devis</a>
                  <br/>
                  <a :href="coreEndpoint +
              'avenant/devis/' +
              avenant.uuid +
              '?zone=' +
              $store.state.currentTenant.code
              " class="btn btn-primary btn-sm" target="_blank">Visualiser</a>
                </div>
              </div>
            </div>
          </div>
        </b-modal>

      </template>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import Contract from "@/entity/Contract";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import FileUpload from "@/components/FileUpload.vue";
import Avenant from "@/entity/Avenant";
import CONSTANTS from "@/Utils/constant";
import Item from "@/entity/Item";
import UserView from "@/components/UserView.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import Property from "@/entity/Property";
import IncorporationDetail from "@/views/Contract/IncorporationDetail.vue";
import Printable from "@/entity/Printable";
import Swal from "sweetalert2";
import User from "@/entity/User";
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import Popup from "@/entity/Popup";
import {helper} from "@/services/Helper";
import AccountOperation from "@/entity/AccountOperation";
import PartnerView from "@/components/PartnerView.vue";
import {trans} from "../../services/Translator";
import TypeAvenant from "@/entity/TypeAvenant";
import PaymentTransaction from "@/entity/PaymentTransaction";
import Invoice from "@/entity/Invoice";

@Component({
  components: {
    PartnerView,
    IncorporationDetail,
    FileUpload,
    Loading,
    Base,
    UserView,
    VueMultiselect,
  },
})
export default class ContractShow extends Vue {
  contract: Contract | any = null;
  contractNotFound = false
  amount = 0;
  coreEndpoint = api.core;
  uploadRoot = api.uploadRoot;
  tabIndex = 0;
  isLoading = false;
  avenant: any = null;
  managers: User[] = [];
  partners: Partner[] = [];
  partnerUsers: Partner[] = [];
  userType: any = "manager";
  paymentMethods: PaymentMethod[] = [];
  dateEffet: any = null
  paymentMethod = null;
  linkPhoneNumber = "";
  partnerSearched = "";

  get transactionPayedAmount() {
    let total = 0
    this.transactions.forEach((t: PaymentTransaction) => {
      if (t.status === 'SUCCEED' && t.replaced.length === 0) {
        total += t.amount
      }
    })
    return total
  }

  async simulateWave(transaction: PaymentTransaction) {
    const res = await api.get(api.core, 'payment/simulate/wave/response/' + transaction.uuid)

    this.loadPayments()
  }

  async simulateHub2(transaction: PaymentTransaction) {
    const res = await api.get(api.core, 'payment/simulate/hub2/response/' + transaction.uuid)
    this.loadPayments()
  }

  getItemPack(item: Item) {
    let insurerLabel = "";
    let packLabel = "";
    if (item.pack && item.pack.insurer) {
      return item.pack.insurer.label + " ▪ " + item.pack.label;
    }
    for (let property of item.properties) {
      if (property.model.type.code === "PACK") {
        if (property.value) {
          const value = JSON.parse(property.value);
          if (value.pack && value.pack.label) {
            packLabel = value.pack.label;
            if (value.pack.insurer && value.pack.insurer.label) {
              insurerLabel = value.pack.insurer.label;
            }
          }
          if (
              helper.empty(insurerLabel) &&
              value.insurer &&
              value.insurer.label
          ) {
            insurerLabel = value.insurer.label;
          }
        }
      }
    }
    return insurerLabel + " " + packLabel;
  }

  async removeAvenantProject(avenant: Avenant) {
    const response = await Swal.fire({
      title: "Supprimer",
      html: "Êtes-vous sûr de vouloir supprimer ce projet  d'avenant ?",
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: "Supprimer",
    });
    if (response.isConfirmed) {
      const res = await api.get(
          api.core,
          "avenant/remove/project/" + avenant.uuid
      );
      if (res && res.data) {
        this.loadAvenants();
      }
    }
  }

  async mounted() {
    const now = new Date()
    this.dateEffet = helper.reversedDateToHtml(now)
    this.userType = localStorage.getItem("userType");
    this.loadPaymentMethods();
    await this.loadContract();
    this.managers = await api.loadManagers();
    if (this.userType === "manager") {
      this.partners = await this.$store.getters.partners();
      this.partnerUsers = await this.$store.getters.partnerUsers();
    }

    if (this.contract && this.contract.branch.id) {
      this.loadTypesAvenant(this.contract.branch.id);
    }
  }


  transactionIds: any[] = []
  selectedMethod: any = null
  replacementNumber = ''

  setReplacementMethod(method: PaymentMethod) {
    this.selectedMethod = method
  }

  getReplacedAmount() {
    let amount = 0
    this.transactions.forEach((t: PaymentTransaction) => {
      if (this.transactionIds.includes(t.id)) {
        amount += t.amount
      }
    })

    return amount
  }

  toggleTransaction(transaction: PaymentTransaction) {
    const index = this.transactionIds.findIndex((t) => {
      return t.id === transaction.id
    })

    const method = this.paymentMethods.find((m: PaymentMethod) => {
      return m.slug === transaction.method.slug
    })
    if (method && transaction.status === 'SUCCEED') {
      transaction.method = method
      if (index > -1) {
        this.transactionIds.splice(index, 1)
      } else if (transaction.method.requireManagerValidation === true) {
        this.transactionIds.push(transaction.id)
      }
    } else {
      new Popup('Erreur', "Cette transaction n'a pas encore été payée")
    }
  }

  async replaceTransactions() {
    if (!helper.empty(this.transactionIds) && this.selectedMethod) {
      let endpoint = 'payment/replace/' + this.selectedMethod.slug
      if (!helper.empty(this.replacementNumber)) {
        endpoint += '/' + this.replacementNumber
      }
      const res = await api.post(api.core, endpoint, this.transactionIds)
      if (res && res.data && res.data.transaction) {
        const t = new PaymentTransaction(res.data.transaction)
        this.transactions.unshift(t)

        const response = await Swal.fire({
          title: 'Lien de paiement',
          html: 'Votre lien de paiement est le suivant : <br>' +
              '<div class="fw-bolder btn btn-primary text-white my-5"><a target="_blank" class="text-white" href="' + res.data.url + '">' +
              'Payer ' + t.amount + ' sur ' + this.selectedMethod.label + ' </a></div>' +
              '<div class="fw-bolder my-5 fs-2">' + res.data.url + '</div>',
          showCancelButton: true,
          confirmButtonText: 'Ok',
        })

      }
    }
  }

  async switchPartner(code: string) {
    const response = await Swal.fire({
      title: "Réattribuer ce contrat",
      html: "Êtes-vous sûr de vouloir attribuer ce contrat à ce partenaire ? <br/><br/> La commission lié à ce contrat lui sera attribué en votre nom",
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: "Confirmer",
    });
    if (response.isConfirmed) {
      this.$store.commit("loading");
      const res = await api.get(
          api.core,
          "contract/transfer/partner/" + this.contract.uuid + "/" + code
      );
      this.$store.commit("stopLoading");
    }
  }

  async subscribeRenewalCampaign() {
    if (this.contract) {
      this.$store.commit("loading");
      const res = await api.get(
          api.core,
          "contract/subscribe/renewal/campaign/" + this.contract.uuid
      );
      this.$store.commit("stopLoading");
    }
  }

  getPartnerNameByCode(code: string) {
    if (code === "all") {
      return "Tous les partenaires";
    }
    const partner = this.partners.find((partner: Partner) => {
      return partner.code === code;
    });
    if (partner) {
      if (!helper.empty(partner.name)) {
        return partner.name;
      }
      return partner.code;
    }
    return code;
  }

  getLink(pm: PaymentMethod | null, avenant: Avenant) {
    let route = api.comparator + "avenant/payment/" + avenant.uuid;
    if (pm) {
      route += "/" + pm.slug;
    }
    return route;
  }

  methodSelected = null;

  async repay(transaction: PaymentTransaction, pm: PaymentMethod) {

    if (helper.empty(this.linkPhoneNumber)) {
      new Popup("Erreur", "Le contact est vide");
      return;
    }


    this.$store.commit('loading')

    const res = await api.get(api.core, 'payment/repay' + '/' + transaction.id + '/' + pm.slug + '/' + this.linkPhoneNumber)
    this.$store.commit('stopLoading')

    if (res && res.data && res.data.url) {
      const response = await Swal.fire({
        title: 'Lien de paiement',
        html: 'Votre lien de paiement est le suivant : <br><div class="fw-bolder btn btn-primary text-white my-5"><a target="_blank" class="text-white" href="' + res.data.url + '">Payer ' + transaction.amount + ' sur ' + pm.slug + ' </a></div>' +
            '<div class="fw-bolder my-5 fs-2">' + res.data.url + '</div>',
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        didOpen: () => {
          // Swal.showLoading()
          // const b = Swal.getHtmlContainer().querySelector('b')
          // timerInterval = setInterval(() => {
          //     b.textContent = Swal.getTimerLeft()
          // }, 100)
        },
        willClose: () => {
          // clearInterval(timerInterval)
        }
      })
      // if (response.isConfirmed) {
      //
      // }
      // this.generatedLink = res.data.url
    }
    // window.location.href = link;
  }


  async sendPaymentLink(pm: PaymentMethod | null, avenant: Avenant) {

    if (helper.empty(this.linkPhoneNumber)) {
      new Popup("Erreur", "Le contact est vide");
      return;
    }
    this.$store.commit("loading");
    const link = this.getLink(pm, avenant);
    if (pm) {
      const ref = "pm-button-" + pm.uuid;
      const refs = this.$refs as any;
      if (refs.hasOwnProperty(ref)) {
        refs[ref][0].hide();
      }
    }
    const res = await api.post(api.core, "avenant/send/payment/short", {
      uuid: avenant.uuid,
      link: link,
      contact: this.linkPhoneNumber,
      amount: this.amount,
      method: pm ? pm.slug : null
    });
    this.$store.commit("stopLoading");
    if (res && res.data) {
      // if (res.data.url) {
      //   open(res.data.url, '_blank')
      // }

      const response = await Swal.fire({
        title: 'Lien de paiement',
        html: 'Votre lien de paiement est le suivant : <br>' +
            '<div class="fw-bolder btn btn-primary text-white my-5">' +
            '<a target="_blank" class="text-white" href="' + res.data.url + '">' +
            'Payer ' + this.amount + (pm ? (' sur ' + pm.slug) : '') +
            '</a>' +
            '</div>' +
            '<div class="fw-bolder my-2 fs-2">' + res.data.url + '</div>',
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        didOpen: () => {
          // Swal.showLoading()
          // const b = Swal.getHtmlContainer().querySelector('b')
          // timerInterval = setInterval(() => {
          //     b.textContent = Swal.getTimerLeft()
          // }, 100)
        },
        willClose: () => {
          // clearInterval(timerInterval)
        }
      })

      // const pop = new Popup(
      //     "SMS Envoyé",
      //     "Un lien de paiement à été envoyé au " + this.linkPhoneNumber,
      //     "primary",
      //     "fad fa-sms"
      // );
    }
  }

  async sendRePaymentLink(pm: PaymentMethod, transaction: PaymentTransaction) {


    console.log('object');
    if (helper.empty(this.linkPhoneNumber)) {
      new Popup("Erreur", "Le contact est vide");
      return;
    }
    this.$store.commit("loading");
  }

  async loadPaymentMethods() {
    this.isLoading = true;
    const popup = new Popup(
        "Chargement ...",
        "",
        "warning",
        "fad fa-cogs",
        true
    );
    const res = await api.get(api.core, "payment/method/list");
    this.isLoading = false;
    popup.hide();
    if (res && res.data) {
      this.paymentMethods = [];
      res.data.methods.forEach((method: PaymentMethod) => {
        this.paymentMethods.push(new PaymentMethod(method));
      });
    }
  }

  isAvenantExpired(avenant: Avenant) {
    const now = new Date()
    if (avenant.status === CONSTANTS.AVENANT_STATUS.PROJET && avenant.source !== 'Formulaire Interne') {
      let effet = new Date(avenant.dateEffet)
      effet.setHours(0, 0, 0, 0);
      now.setHours(0, 0, 0, 0);
      // console.log(now, effet)
      // console.log(now.getTime() + ' >' + effet.getTime())
      // console.log(now.getTime() > effet.getTime())
      return now.getTime() > effet.getTime()
    }
    return false
  }

  operations: AccountOperation[] = [];
  transactions: PaymentTransaction[] = []

  async loadPayments() {
    this.isLoading = true;
    const popup = new Popup(
        "Chargement ...",
        "",
        "warning",
        "fad fa-cogs",
        true
    );
    const res = await api.get(
        api.core,
        "contract/payment/list/" + this.$route.params.contractUuid
    );
    this.isLoading = false;
    popup.hide();
    if (res && res.data && res.data.transactions) {
      this.transactions = [];
      res.data.transactions.forEach((op: any) => {
        const occ = new PaymentTransaction(op)
        this.transactions.push(occ);
      });
    }
  }

  invoices: Invoice[] = []

  async loadInvoices() {
    this.isLoading = true;
    const popup = new Popup(
        "Chargement ...",
        "",
        "warning",
        "fad fa-cogs",
        true
    );
    const res = await api.get(api.core, "contract/invoice/list/" + this.$route.params.contractUuid
    );
    this.isLoading = false;
    popup.hide();
    if (res && res.data && res.data.invoices) {
      this.invoices = [];
      res.data.invoices.forEach((i: any) => {
        this.invoices.push(new Invoice(i));
      });
    }
  }

  get willExpireSoon() {
    if (this.contract instanceof Contract) {
      const now = new Date();
      const diff = this.contract.expireAt.getTime() - now.getTime();
      let dayLeft = Math.ceil(diff / (1000 * 3600 * 24));
      if (dayLeft < 30) {
        return true;
      }
      return false;
    }
    return false;
  }

  async loadReceipt(uuid: string) {
    this.isLoading = true;
    const res = await api.get(api.core, "payment/receipt/" + uuid);
    this.isLoading = false;
  }

  async emitAvenant(avenant: Avenant) {
    const response = await Swal.fire({
      title: "Emission de contrat",
      html: "Êtes-vous sûr de vouloir emettre ce avenant sans encaissement ?",
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: "Emettre l'avenant",
    });
    if (response.isConfirmed) {
    }
    this.$store.commit("loading");
    const res = await api.get(api.core, "avenant/emit/" + avenant.uuid);
    this.$store.commit("stopLoading");
    this.loadContract();

  }

  customPartnerLabel(option: any) {
    // console.log(option)
    if (typeof option === "string") {
      return option;
    }
    return option.code;
  }

  updatePartner(e: any) {
    console.log(e);
  }

  async updateManager(e: any) {
    if (e && e.uuid) {
      const u = new User(e);
      const response = await Swal.fire({
        title: "Attribuer à " + u.identifier,
        html: "Êtes-vous sûr de vouloir changer le gestionnaire de ce contrat ?",
        timer: 10000,
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: "Changer",
        didOpen: () => {
        },
        willClose: () => {
        },
      });
      if (response.isConfirmed) {
        this.$store.commit("loading");
        const res = await api.get(
            api.core,
            "contract/update/manager/" + this.contract.uuid + "/" + e.uuid
        );
        this.$store.commit("stopLoading");
        if (res && res.data && res.data.contract) {
          this.contract = new Contract(res.data.contract);
        }
      }
    }
  }

  itemHasChildren(item: Item) {
    // let result = false
    return item.properties.some((p: Property) => p.children.length > 0);
    // item.properties.forEach((p: Property) => {
    //     if (p.children.length > 0) {
    //         result = true
    //     }
    // })
    // return result
  }

  toggleItemExpand(item: Item, evt: Event) {
    // console.log(evt)
    // console.log(evt instanceof Event)
    let value: any = evt;
    if (evt instanceof Event) {
      value = (evt.target as any).checked as any;
    } else {
      item.expand = value;
      // console.log('hide ' + item.name)
    }
    item.properties.forEach((p: Property) => {
      p.children.forEach((c: Item) => {
        const index = this.itemList.findIndex((i: Item) => {
          return i.uuid === item.uuid;
        });
        if (index !== -1) {
          this.itemList[index].expand = item.expand;
          this.toggleItemExpand(c, value);
        }
      });
    });
  }

  previewPrintable(item: Item, printable: Printable) {
  }

  itemList: Item[] = [];
  isLoadingAvenant = false;

  async getAvenantData(avenant: Avenant) {
    this.isLoadingAvenant = true;
    const res = await api.get(api.core, "avenant/get/items/" + avenant.uuid);
    // console.log(res)
    this.isLoadingAvenant = false;
  }

  get contractItems() {
    if (this.contract && this.contract.root) {
      // console.log(this.getItemByLines(this.contract.root))
      return this.getItemByLines(this.contract.root);
    } else {
      return [];
    }
  }

  getItemByLines(item: Item, result?: Item[]) {
    if (!result) {
      result = [];
    }
    if (!result.includes(item)) {
      result.push(item);
    }
    item.properties.forEach((p: Property) => {
      p.children.forEach((c: Item) => {
        c.parentItem = item;
        c.level = item.level + 1;
        this.getItemByLines(c, result);
      });
    });
    return result;
  }

  async setAvenant(avenant: Avenant) {
    this.avenant = avenant;
    if (!this.avenant.root) {
      this.isLoadingAvenant = true;
      const res = await api.get(
          api.core,
          "avenant/get/root/" + this.avenant.uuid
      );
      if (res && res.data && res.data.root) {
        this.avenant.root = new Item(res.data.root);
      }
      this.isLoadingAvenant = false;
    }
    this.itemList = this.getItemByLines(this.avenant.root);
  }

  avenantLink: any = null

  async payAvenant(avenant: Avenant) {
    // console.log(this.amount+' '+avenant.balance)
    // return
    this.amount = Math.abs(this.amount)
    if (this.amount == 0) {
      new Popup('Erreur', 'Entrez un motant supérieur à 0')
      return
    }
    if (Math.abs(avenant.invoicesBalance) < this.amount) {
      const response = await Swal.fire({
        title: "Montant supérieur",
        html: "Le solde est supérieur au montant dû voulez vous verser la différence " + helper.currencyFormat(this.amount - Math.abs(avenant.balance)) + " sur le compte client ?",
        timer: 10000,
        timerProgressBar: true,
      });
      if (!response.isConfirmed) {
        return;
      }
    }

    this.$store.commit("loading");
    const res = await api.get(
        api.core,
        "checkout/pay/avenant/" + avenant.uuid + "/" + this.amount
    );
    if (res && res.data && res.data.status === "success") {
      this.loadContract();
    }
    this.$store.commit("stopLoading");
  }

  async loadAvenants() {
    this.isLoading = true;
    const res = await api.get(
        api.core,
        "contract/get/avenants/" + this.$route.params.contractUuid
    );
    if (res && res.data && res.data.avenants) {
      this.contract.avenants = [];
      res.data.avenants.reverse();
      res.data.avenants.forEach((a: any) => {
        const occ = new Avenant(a);
        this.contract.avenants.push(occ);
      });
      res.data.previous.forEach((a: any) => {
        const occ = new Avenant(a);
        this.contract.avenants.push(occ);
      });
    }
    this.isLoading = false;
  }

  async loadItems() {
    const res = await api.get(
        api.core,
        "contract/get/root/item/" + this.$route.params.contractUuid
    );
    if (res && res.data && res.data.root) {
      this.contract.root = new Item(res.data.root);
    }
  }

  async loadContract() {
    this.isLoading = true;
    const res = await api.get(
        api.core,
        "contract/get/data/" + this.$route.params.contractUuid
    );
    this.isLoading = false;

    if (res && res.data && res.data.contract) {
      // console.log(res.data.contract.avenants[0].root.properties[0].children[0].properties[19].children[0].properties)
      this.contract = new Contract(res.data.contract);
      // this.contract.avenants.reverse();

      if (this.contract.status === CONSTANTS.CONTRACT_STATUS["QUOTATION"]) {
        this.tabIndex = 0;
        await this.loadAvenants();
      }
      if (this.contract.status === CONSTANTS.CONTRACT_STATUS["ONGOING"]) {
        this.tabIndex = 1;
        await this.loadItems();
      }
      this.linkPhoneNumber = this.contract.subscriber.mobile;
    } else {
      this.contractNotFound = true
    }
    // console.log(this.contract)
    // console.log(this.contract.avenants[0].root.properties[0].children[0].properties[1].children[0].properties[6].children[0])
    // console.log(this.contract.avenants[0].root.properties[0].children[0].properties[19].children[0].properties[0].model.link)
  }

  typesAvenant: TypeAvenant[] = [];

  async loadTypesAvenant(idBranch: number) {
    this.isLoading = true;
    const res = await api.get(api.core, "type/avenant/list/" + idBranch);
    if (res && res.data && res.data.typesAvenant) {
      // (this.$refs.dataTypeModal as any).hide()
      this.typesAvenant = [];
      res.data.typesAvenant.forEach((type: TypeAvenant) => {
        this.typesAvenant.push(new TypeAvenant(type));
      });
    }

    this.isLoading = false;
  }

  async loadEngine() {
    const module_path = api.core + "engine/Auto/Engine.js";
    // const module_path = api.core + 'engine/EngineModule.js';

    // const Atlantique =

    // const Atlantique = await require(module_path)
    // console.log(Atlantique)

    // const plugin = document.createElement("script");
    // plugin.setAttribute(
    //     "type",
    //     'text/javascript'
    // );
    // plugin.setAttribute(
    //     "src",
    //     module_path
    // );
    // plugin.async = true;
    // document.head.appendChild(plugin);
    // plugin.addEventListener('load', () => {
    //     const r = new Atlantique()
    //     console.log(r)
    //
    // })

    // const module = await import(module_path).then(({default: engine}) => {
    //     console.log(engine)
    // })
    // console.log(module)
    // module.default();
  }

  checkModeAvenant(avenant: Avenant) {
    // console.log(avenant.transactions);
    return avenant.transactions.some(
        (item) => item.status === "Réussie" && item.type === "Espèce"
    );
  }

  async forwardAvenant(avenant: Avenant) {
    const res = await api.post(api.core, 'avenant/forward/' + avenant.uuid, {dateEffet: this.dateEffet})
    if (res && res.data && res.data.status === 'success') {
      this.dateEffet = null
      this.loadAvenants()
    }
  }
}
</script>
<style scoped>
td {
  vertical-align: middle;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.2s;
}

.slide-left-leave-from {
  transition: all 0.2s;
}

.slide-left-enter {
  opacity: 0;
  transform: translateY(50%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}

.modal-xl .modal-content {
  height: 100%;
}


.payment-method {
  filter: grayscale(100);
  transition: all .3s ease;
}

.payment-method:hover,
.payment-method.selected {
  filter: grayscale(0)
}
</style>
